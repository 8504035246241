import React from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../Util/GenericTextInput';

const styles = theme => ({
    paper: {
        maxWidth: 500,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class FormComponent extends React.Component {

    submitHandler = () => {
        this.props.action(
            this.input_job_no.value
        )
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3} className={classes.paper}>
                    <GenericTextInput
                        label="Job No."
                        name="input_job_no"
                        inputRef={node => (this.input_job_no = node)}
                        inputProps={{ maxLength: 20 }}
                    />

                    <br />

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.props.loading ? true : false}
                        className={classes.button}
                        onClick={() => this.submitHandler()}
                    >
                        Search
                    </Button>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);