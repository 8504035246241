import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class GenericIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}>
                {this.props.label}
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {!this.props.stage.length ?
                    <Box pt={4}
                        className={classes.box}>

                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} />
                    </Box>

                    :

                    /* If it is a LS Staff */
                    localStorage.getItem('perm') === "2" ?
                        <Box pt={4}
                            className={classes.box}>

                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                        :

                    /*If the job record is not active */
                    this.props.stage[0]['active'] === 'N' ?
                        <Box pt={4}
                            className={classes.box}>

                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                        :

                        /* If application or resubmission is approved */
                        (this.props.stage[0]['curr_stage'] === 'APP' ||
                            this.props.stage[0]['curr_stage'] === 'RES') &&
                            this.props.stage[0]['status'] === 'Approved' ?

                            <Box pt={4}
                                className={classes.box}>

                                <GenericIdleButton
                                    className={classes.button}
                                    onClick={this.props.action2}
                                    label="Go to Payment" />

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />
                            </Box>

                            :

                            /* If Deposition is approved */
                            this.props.stage[0]['curr_stage'] === 'DEP' &&
                                this.props.stage[0]['status'] === 'Approved' ?

                                <Box pt={4}
                                    className={classes.box}>
                                    <GenericIdleButton
                                        className={classes.button}
                                        onClick={this.props.action7}
                                        label="Job Cancellation" />

                                    <BackIdleButton
                                        className={classes.button}
                                        onClick={this.props.action} />

                                </Box>

                                :

                                /* If RSP or Redeposition is approved */
                                (this.props.stage[0]['curr_stage'] === 'ESP' ||
                                    this.props.stage[0]['curr_stage'] === 'RED') &&
                                    this.props.stage[0]['status'] === 'Approved' ?

                                    <Box pt={4}
                                        className={classes.box}>
                                        <GenericIdleButton
                                            className={classes.button}
                                            onClick={this.props.action3}
                                            label="Amendment of Fees" />

                                        <GenericIdleButton
                                            className={classes.button}
                                            onClick={this.props.action4}
                                            label="Go to Claims" />

                                        <GenericIdleButton
                                            className={classes.button}
                                            onClick={this.props.action7}
                                            label="Job Cancellation" />

                                        <BackIdleButton
                                            className={classes.button}
                                            onClick={this.props.action} />

                                    </Box>

                                    :

                                    /* If resubmission is rejected */
                                    this.props.stage[0]['curr_stage'] === 'RES' &&
                                        this.props.stage[0]['status'] === 'Rejected' ?

                                        <Box pt={4}
                                            className={classes.box}>
                                            <GenericIdleButton
                                                className={classes.button}
                                                onClick={this.props.action5}
                                                label="Modify Application" />

                                            <BackIdleButton
                                                className={classes.button}
                                                onClick={this.props.action} />
                                        </Box>

                                        :

                                        /* If deposition or redeposition is rejected */
                                        (this.props.stage[0]['curr_stage'] === 'DEP' ||
                                            this.props.stage[0]['curr_stage'] === 'RED') &&
                                            this.props.stage[0]['status'] === 'Rejected' ?

                                            <Box pt={4}
                                                className={classes.box}>
                                                <GenericIdleButton
                                                    className={classes.button}
                                                    onClick={this.props.action6}
                                                    label="Modify Payment Application" />

                                                <BackIdleButton
                                                    className={classes.button}
                                                    onClick={this.props.action} />
                                            </Box>

                                            :

                                            /* If claims is either approved or rejected and still in first time claim */
                                            this.props.stage[0]['curr_stage'] === 'CLM' &&
                                                (this.props.stage[0]['status'] === 'Approved' ||
                                                    this.props.stage[0]['status'] === 'Rejected') &&
                                                this.props.claim_indicator[0]['indicator'] === 'First' ?

                                                <Box pt={4}
                                                    className={classes.box}>
                                                    <GenericIdleButton
                                                        className={classes.button}
                                                        onClick={this.props.action4}
                                                        label="Go to Claims" />

                                                    <GenericIdleButton
                                                        className={classes.button}
                                                        onClick={this.props.action7}
                                                        label="Job Cancellation" />

                                                    <BackIdleButton
                                                        className={classes.button}
                                                        onClick={this.props.action} />
                                                </Box>

                                                :

                                                /* If claims is either approved or rejected */
                                                this.props.stage[0]['curr_stage'] === 'CLM' &&
                                                    (this.props.stage[0]['status'] === 'Approved' ||
                                                        this.props.stage[0]['status'] === 'Rejected') &&
                                                    this.props.claim_indicator[0]['indicator'] === 'Multiple' ?

                                                    <Box pt={4}
                                                        className={classes.box}>
                                                        <GenericIdleButton
                                                            className={classes.button}
                                                            onClick={this.props.action4}
                                                            label="Go to Claims" />

                                                        <BackIdleButton
                                                            className={classes.button}
                                                            onClick={this.props.action} />
                                                    </Box>

                                                    :

                                                    /* If cancellation/refund is rejected */
                                                    this.props.stage[0]['curr_stage'] === 'RFD' &&
                                                        this.props.stage[0]['status'] === 'Rejected' ?

                                                        <Box pt={4}
                                                            className={classes.box}>
                                                            <GenericIdleButton
                                                                className={classes.button}
                                                                onClick={this.props.action7}
                                                                label="Job Cancellation" />

                                                            <BackIdleButton
                                                                className={classes.button}
                                                                onClick={this.props.action} />
                                                        </Box>

                                                        :

                                                <Box pt={4}
                                                    className={classes.box}>
                                                    <BackIdleButton
                                                        className={classes.button}
                                                        onClick={this.props.action} />
                                                </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);