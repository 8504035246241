import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { withStyles } from '@material-ui/core/styles';

import GenericNumberTextInput from '../../Util/GenericNumberTextInput';
import GenericLoadTextField from '../../Util/GenericLoadTextField';
import GenericIdleTextField from '../../Util/GenericIdleTextField';

import Section2PopoverComponent from './Section2PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section2Component extends React.Component {

    onChangeHandler = (event, i) => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.onChange(event.target.value, i)
        }
    }

    render() {
        const { classes } = this.props

        const arrayUI = (
            <div style={{ flexGrow: 1 }}>
                {this.props.array.map((e1, i) => (
                    <Grid container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className={classes.padding}
                        key={i}
                        style={{ border: '1px solid' }}>
                        <Grid item xs={12} sm={1}>
                            <p>{i + 1}</p>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <GenericNumberTextInput
                                label="Area of Lot"
                                name="section2_input"
                                inputProps={{ maxLength: 9 }}
                                value={this.props.value[i]}
                                onChange={event => this.onChangeHandler(event, i)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {this.props.arrayLoading[i] ?
                                <GenericLoadTextField
                                    label="Amount"
                                    name="section2_array_amount"
                                    value={this.props.arrayAmount[i]}
                                />
                                :
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section2_array_amount"
                                    value={this.props.arrayAmount[i]}
                                />}
                        </Grid>
                        <Grid item xs={12} sm={1}>

                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <IconButton
                                variant="contained"
                                color="secondary"
                                onClick={() => this.props.action2(i)}
                            >
                                <RemoveIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 2: Charge for each lot
                    </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Please fill in the area for each lot.
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section2PopoverComponent />
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.table}>Area of Lot (In Hectare)</TableCell>
                                <TableCell className={classes.table}></TableCell>
                                <TableCell className={classes.table} align="right">Action</TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>

                <AccordionDetails>
                    {arrayUI}
                </AccordionDetails>

                <AccordionDetails>
                    <IconButton variant="contained" color="primary"
                        className={classes.roundedButton}
                        onClick={this.props.action1}
                    >
                        <AddIcon fontSize="large" />
                    </IconButton>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={3}>
                            {this.props.loading ?
                                <GenericLoadTextField
                                    label="Amount"
                                    name="section2_amount"
                                    value={this.props.valueTotal}
                                />
                                :
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section2_amount"
                                    value={this.props.valueTotal}
                                />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);