import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from "axios";
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class CPDLogbook extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })

        axios.get(Config() + '/ls_cpd/logbook/get',
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    data: response.data[1],
                    backdrop: false
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    generatePDF = (log_year) => {
        axios.put(Config() + '/ls_cpd/logbook/generate',
        {
            'log_year': log_year
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.openFile(response.data[1])
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    openFile = (value) => {
        window.open(value, "_blank")
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <br />
                <TableComponent 
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                data={this.state.data}
                action={this.generatePDF} />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(CPDLogbook));