import React from 'react';
import TextField from '@material-ui/core/TextField';

class ControlledTextInput extends React.Component {
    render() {
        return (
            <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            error={this.props.error}
            label={this.props.label}
            name={this.props.name}
            type="tel"
            value={this.props.value}
            inputProps={this.props.inputProps}
            InputLabelProps={this.props.InputLabelProps}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            />
        )
    }
}

export default ControlledTextInput;