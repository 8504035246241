import React from 'react';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class LicensedSurveyorRegister extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            ls_name_array: [],

            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        axios.get(Config() + '/licensedsurveyor/application/initialize',
            {
                withCredentials: true
            })
            .then(response => {
                this.setState({
                    ls_name_array: response.data
                })
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {

                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    addData = (
        ls_name,
        username,
        password
    ) => {
        console.log(ls_name, username, password)
        axios.post(Config() + '/licensedsurveyor/application/add',
            {
                'user_name': username,
                'password': password,
                'ls_no': ls_name
            },
            {
                withCredentials: true
            })
            .then(response => {
                console.log(response)
                if (response.data['result'] === "Success") {
                    this.props.history.push({ pathname: '/ui/StaffHome' })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    queryData = (ls_no) => {
        axios.put(Config() + '/licensedsurveyor/application/query',
            {
                'ls_no': ls_no
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === true) {
                    this.ComponentUI.current.selecterrorHandler(true)
                }
                else {
                    this.ComponentUI.current.selecterrorHandler(false)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <FormComponent
                    data={this.state.ls_name_array}
                    action={this.addData}
                    action2={this.queryData}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(LicensedSurveyorRegister));