import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

import debounce from 'lodash.debounce';
import axios from 'axios';

import { Config } from '../../../../../../Config';

import AlertComponent from './AlertComponent';
import Section1Component from './Section1Component';
import Section2Component from './Section2Component';
import SectionTotalComponent from './SectionTotalComponent';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="primary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Next
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary"
                fullWidth type="submit"
                disabled
                className={this.props.className}
            >
                Loading... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="secondary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Back
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="secondary"
                fullWidth type="submit"
                disabled
                className={this.props.className}
            >
                Loading... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_input: '',
            section1_amount: (0).toFixed(2),
            section1_loading: false,

            section2_input_1: '',
            section2_input_2: '',
            section2_amount: (0).toFixed(2),
            section2_loading: false,

            total_amount: (0).toFixed(2),

            error_msg: ''
        }
        this.section1_input_onChangeDebounced = debounce(this.section1_input_onChangeDebounced, 1500)
        this.section2_input_1_onChangeDebounced = debounce(this.section2_input_1_onChangeDebounced, 1500)
        this.section2_input_2_onChangeDebounced = debounce(this.section2_input_2_onChangeDebounced, 1500)
    }

    section1_input_onChangeHandler = (value) => {
        this.setState({
            section1_input: value,
            section1_loading: true
        })

        this.section1_input_onChangeDebounced(value)
    }

    section1_input_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 10,
                'section': 1,
                'value': value
            })
            .then(response => (
                this.setState({
                    section1_amount: response.data.toFixed(2),
                    section1_loading: false
                }, () => {
                    this.total_ChangeHandler()
                })
            ))
    }

    section2_input_1_onChangeHandler = (value) => {
        this.setState({
            section2_input_1: value,
            section2_loading: true
        })

        this.section2_input_1_onChangeDebounced(value)
    }

    section2_input_1_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 10,
                'section': 2,
                'value_1': value,
                'value_2': this.state.section2_input_2
            })
            .then(response => (
                this.setState({
                    section2_amount: response.data.toFixed(2),
                    section2_loading: false
                }, () => {
                    this.total_ChangeHandler()
                })
            ))
    }

    section2_input_2_onChangeHandler = (value) => {
        this.setState({
            section2_input_2: value,
            section2_loading: true
        })

        this.section2_input_2_onChangeDebounced(value)
    }

    section2_input_2_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 10,
                'section': 2,
                'value_1': this.state.section2_input_1,
                'value_2': value
            })
            .then(response => (
                this.setState({
                    section2_amount: response.data.toFixed(2),
                    section2_loading: false
                }, () => {
                    this.total_ChangeHandler()
                })
            ))
    }

    total_ChangeHandler = () => {
        var total = (Number(this.state.section1_amount) +
            Number(this.state.section2_amount))

        this.setState({
            total_amount: total.toFixed(2),
            loading: false
        })
    }

    nextButtonHandler = () => {
        this.setState({ loading: true })
        this.props.action(
            this.state.section1_input,
            this.state.section2_input_1,
            this.state.section2_input_2,
            this.state.total_amount
        )
    }

    getData = (
        section1_input,
        section2_input_1,
        section2_input_2,
        section1_amount,
        section2_amount) => {

        this.setState({
            section1_input: section1_input,
            section2_input_1: section2_input_1,
            section2_input_2: section2_input_2,
            section1_amount: section1_amount,
            section2_amount: section2_amount,
        }, () => {
            this.total_ChangeHandler()
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Section1Component
                        value={this.state.section1_input}
                        onChange={this.section1_input_onChangeHandler}
                        valueTotal={this.state.section1_amount}
                        loading={this.state.section1_loading}
                    />

                    <Section2Component
                        onChange_value1={this.section2_input_1_onChangeHandler}
                        onChange_value2={this.section2_input_2_onChangeHandler}
                        value1={this.state.section2_input_1}
                        value2={this.state.section2_input_2}
                        loading={this.state.section2_loading}
                        valueTotal={this.state.section2_amount}
                    />

                    <SectionTotalComponent
                        value={this.state.total_amount}
                        loading={this.state.loading}
                    />
                </Paper>

                {this.state.loading ?

                    <Box pt={4} className={classes.box}>
                        <NextLoadButton className={classes.button} />
                        <BackLoadButton className={classes.button} />
                    </Box>
                    :

                    <Box pt={4} className={classes.box}>
                        <NextIdleButton
                            className={classes.button}
                            onClick={this.nextButtonHandler} />
                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action2} />
                    </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);