import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BrightnessHigh from '@material-ui/icons/BrightnessHigh';
import Brightness4 from '@material-ui/icons/Brightness4';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { withStyles } from '@material-ui/core/styles';

import DrawerComponent from './DrawerComponent';

const drawerWidth = 240;

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerMobile: {
        //prevent the mobile drawer from overlapping the desktop drawer
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    }
})

class AppBarComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorE1: null,
            mobileOpen: false,
        }
    }

    handleClick = event => {
        this.setState({ anchorE1: event.currentTarget })
    };

    handleClose = () => {
        this.setState({ anchorE1: null })
    };

    render() {
        const { classes } = this.props

        return (
            <div>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h5" noWrap>
                            e-Connect LJS
                        </Typography>
                        <div className={classes.grow} />

                        {/* This action is from App HOC class to update theme */}
                        <IconButton color="inherit"
                            onClick={this.props.action}
                        >{localStorage.getItem('theme') === 'dark' ? <BrightnessHigh /> : <Brightness4 />}</IconButton>

                        <IconButton
                            onClick={this.handleClick}
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorE1}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(this.state.anchorE1)}
                            onClose={this.handleClose}
                        >
                            <MenuItem>My Profile</MenuItem>
                            <MenuItem onClick={this.props.logout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>

                <nav className={classes.drawer}>
                    <Hidden lgUp implementation="css">
                        <Drawer className={classes.drawerMobile}
                            variant="temporary"
                            open={this.state.mobileOpen}
                            onClose={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            ModalProps={{
                                keepMounted: true // Better open performance on mobile.
                            }}
                        >
                            <DrawerComponent />
                        </Drawer>
                    </Hidden>

                    <Hidden mdDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            <DrawerComponent />
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        )
    }
}

export default withStyles(styles)(AppBarComponent);