import React from 'react';

import TextField from '@material-ui/core/TextField';

class PasswordTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                type="password"
                label="New Password"
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

export default PasswordTextInput;