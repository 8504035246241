import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class FirstReviewButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                First Checker Review
            </Button>
        )
    }
}

class SecondReviewButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Second Checker Review
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {!this.props.active ?

                    <Box pt={4}
                        className={classes.box}>

                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} />
                    </Box>

                    :

                    this.props.stage_data[0]['curr_stage'] === 'PAPP'
                        &&
                        this.props.stage_data[0]['status'] === 'Pending'
                        &&
                        this.props.active === 'Yes'
                        ?

                        <Box pt={4}
                            className={classes.box}>

                            <FirstReviewButton
                                className={classes.button}
                                onClick={this.props.action1}
                            />

                            <SecondReviewButton
                                className={classes.button}
                                onClick={this.props.action2}
                            />

                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                        :

                        this.props.stage_data[0]['curr_stage'] === 'PPAY'
                            &&
                            this.props.stage_data[0]['status'] === 'Pending'
                            &&
                            this.props.active === 'Yes'
                            ?

                            <Box pt={4}
                                className={classes.box}>

                                <FirstReviewButton
                                    className={classes.button}
                                    onClick={this.props.action3}
                                />

                                <SecondReviewButton
                                    className={classes.button}
                                    onClick={this.props.action4}
                                />

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />
                            </Box>

                            :

                            <Box pt={4}
                                className={classes.box}>

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />
                            </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);