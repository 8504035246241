import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class Grid1Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.esp_no}>

                        <Grid item xs={12} sm={4}>
                            ESP No:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.esp_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ESP Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.esp_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ESP Remarks:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.remark}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        {/* <Grid item xs={12} sm={4}>
                            LJS E3 Date.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ljs3_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid> */}

                        <Grid item xs={12} sm={4}>
                            LJS E7 Date:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ljs7_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            Updated By:
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            Updated At:
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <b>{x.updated_at}</b>
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

class ESPComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        ESP No.
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            data={this.props.data}
                        />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ESPComponent);