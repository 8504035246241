import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import AlertComponent from './AlertComponent';
import Section1Component from './Section1Component';
import SectionTotalComponent from './SectionTotalComponent';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="secondary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Back
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            checkbox: false,
            input_1: [],
            input_2: [],
            input_3: [],
            input_4: [],
            input_5: [],

            total_amount: (0).toFixed(2),

            error_msg: ''
        }
    }

    total_ChangeHandler = () => {
        var total = this.state.input_5.reduce((prev, next) =>
            (Number(prev) + Number(next)), 0)

        this.setState({
            total_amount: total.toFixed(2),
            loading: false
        })
    }

    getData = (
        input_1,
        input_2,
        input_3,
        input_4,
        input_5) => {

        this.setState({
            input_1: input_1,
            input_2: input_2,
            input_3: input_3,
            input_4: input_4,
            input_5: input_5
        }, () => {
            this.total_ChangeHandler()
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Section1Component
                        input_1={this.state.input_1}
                        input_2={this.state.input_2}
                        input_3={this.state.input_3}
                        input_4={this.state.input_4}
                        input_5={this.state.input_5} />

                    <SectionTotalComponent
                        value={this.state.total_amount}
                        loading={this.state.loading}
                    />

                </Paper>

                <Box pt={4} className={classes.box}>
                    <BackIdleButton
                        className={classes.button}
                        onClick={this.props.action} />
                </Box>

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);