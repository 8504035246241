import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericIdleTextField from '../../Util/GenericIdleTextField';

import Section1PopoverComponent from './Section1PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section1Component extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1: Basic Charge
                        </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>

                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                        Please select if basic charge applies in the application (Do not select if you have submitted Table 1)
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section1PopoverComponent />
                        </Grid>
                    </Grid>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={4}>
                            1) &nbsp;&nbsp;Basic Charges Applicable:
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Checkbox name="section1_checkbox"
                            disabled
                            checked={this.props.value}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <Grid item xs={12} sm={3}>
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section1_amount"
                                    value={this.props.valueTotal}
                                />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);