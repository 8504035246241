import React from 'react'

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    table: {
        borderBottom: "none"
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section1PopoverComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            anchor: null
        }
    }

    toggleHandler = e => {
        this.setState({
            open: !this.state.open,
            anchor: e.currentTarget
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.toggleHandler}
                >
                    Reference
                </Button>

                <Popover
                    open={this.state.open}
                    onClose={this.toggleHandler}
                    anchorEl={this.state.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.padding}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.table}>Where Licensed Surveyors are requested by clients to speak or give evidence on their behalf on survey matters, in meetings or in court, or to attend meetings, consultations or discussions, the fees shall be RM 900.00 per session or RM 130.00 per hour or part thereof.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1PopoverComponent);