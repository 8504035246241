import React from 'react';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class TimelineComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3}
                className={classes.paper}>

                <Stepper orientation="vertical">
                    {this.props.data.map((x, index) => (
                        <Step completed expanded key={index}>
                            <StepLabel>{x.date_time}</StepLabel>
                            <StepContent>
                                <Typography variant="subtitle2">
                                    {x.text}
                                </Typography>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TimelineComponent);