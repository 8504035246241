import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Table1Component extends React.Component {
    render() {
        return (
            <TableContainer>
                <Table className={this.props.className}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Table Name
                            </TableCell>
                            <TableCell align="center">
                                Survey Fee & Charge
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {this.props.data.map(x => (
                            <TableRow key={x.id}>
                                <TableCell align="center">
                                    Table {x.table_id}
                                </TableCell>
                                <TableCell align="center">
                                    <b>{x.total_amount.toFixed(2)}</b>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        justify="flex-end"
                        spacing={3}
                        className={this.props.className}
                        key={x.total_amount}>

                        <Grid item xs={12} sm={3}>
                            SST / Prof. Fee (RM)
                        </Grid>
                        <Grid item xs={12} sm={1}
                        style={{ textAlign: 'right' }}>
                            <b>{x.sst_input.toFixed(2)}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            Total (RM):
                        </Grid>
                        <Grid item xs={12} sm={1}
                        style={{ textAlign: 'right' }}>
                            <b>{x.total_amount.toFixed(2)}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}
                        style={{ alignItems: 'center' }}>
                            <Button variant="contained"
                                color="primary"
                                className={this.props.classNameButton}
                                onClick={this.props.onClick}>
                                Go To Table Summary
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

class Section2Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 2 - Survey Fee Tables
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Table1Component
                            className={classes.padding}
                            data={this.props.data} />
                    </AccordionDetails>
                }

                <br />

                {!this.props.data2.length ?
                <AccordionDetails>
                    No data.
                </AccordionDetails>
                    
                    :
                    <AccordionDetails>
                        <Grid2Component
                            // className={classes.padding}
                            classNameButton={classes.button}
                            data={this.props.data2}
                            onClick={this.props.action} />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);