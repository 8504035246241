import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import GenericTextInput from '../Util/GenericTextInput';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class FirstCheckerDialogComponent extends React.Component {
    render() {
        return (
            <Dialog 
            onClose={this.props.onClose}
            open={this.props.open}>
                
                <DialogTitle>
                    First Checker Comment
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type in the comments for the selected CPD application as First Checker:
                    </DialogContentText>
                    <GenericTextInput
                    inputRef={this.props.inputRef}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={this.props.onClick}
                    className={this.props.className}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class SecondCheckerDialogComponent extends React.Component {
    render() {
        return (
            <Dialog 
            onClose={this.props.onClose}
            open={this.props.open}>
                
                <DialogTitle>
                    Second Checker Comment
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type in the comments for the selected CPD application as Second Checker:
                    </DialogContentText>
                    <GenericTextInput
                    inputRef={this.props.inputRef}
                    />
                    <RequiredTextInput
                    error={this.props.error}
                    helperText={this.props.helperText}
                    label="Points Allowed (Not required to input value if reject)"
                    inputProps={{ maxLength: 10 }}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    InputLabelProps={this.props.InputLabelProps}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={this.props.onClick}
                    className={this.props.className}>
                        Approve
                    </Button>

                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.props.onClick2}
                    className={this.props.className}>
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class DialogComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            error_points: false,

            input_points: ''
        }
    }

    onChangeHandler = (event) => {
        const re = /^\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ 
                input_points: event.target.value
            })
        }
    }

    submitHandler = () => {
        if (this.state.input_points === '') {
            this.setState({ error_points: true })
        }
        else {
            this.setState({ error_points: false })
            this.props.action2(this.input_comment.value, this.state.input_points)
        }
    }

    render() {
        const { classes } = this.props
        return (
            <div>
                <FirstCheckerDialogComponent
                onClose={this.props.onClose1}
                open={this.props.open1}
                inputRef={node => (this.input_comment = node)}
                onClick={() => this.props.action1(this.input_comment.value)}
                className={classes.button}
                />

                <SecondCheckerDialogComponent
                onClose={this.props.onClose2}
                open={this.props.open2}
                inputRef={node => (this.input_comment = node)}
                error={this.state.error_points}
                helperText={this.state.error_points ? "Please enter the allowed points" : null}
                onChange={this.onChangeHandler}
                value={this.state.input_points}
                InputLabelProps={{ shrink: this.state.shrink }}
                // onClick={() => this.props.action2(this.input_comment.value, this.input_points.value)}
                onClick={() => this.submitHandler()}
                onClick2={() => this.props.action3(this.input_comment.value)}
                className={classes.button}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);