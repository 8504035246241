import React from 'react';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import PageComponent from './Component/PageComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class SurveyorResetPassword extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            loginName: '',
            dialogTitle: '',
            dialogMsg: '',
            dialog: false,
            backdrop: false
        }
    } 

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        // console.log(" this.props = "+this.props.match.params.token)
        const token = this.props.match.params.token
        // console.log(" token = "+token)
        axios.post(Config() + '/authentication/surveyor/verify/token',
        {
            token: token
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Valid") {
                this.setState({
                    loginName: response.data['username'],
                    dialogMsg: '',
                    dialog: false,
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    this.ComponentUI.current.errorHandler("Invalid Token.")
                    this.setState({
                        dialogTitle: "Reset Password Link Expired!",
                        loginName: '',
                        dialogMsg: "Sorry, your reset password link expired.",
                        dialog: true,
                    })
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }
    
    resetPasswordHandler = (login_name, new_password, confirm_password) => {
        // // check if new_password and confirm_password is match
        if (new_password === confirm_password){
            axios.post(Config() + '/authentication/surveyor/reset/password',
            {
                login_name: login_name,
                new_password: new_password
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        dialogTitle: "Password Changed!",
                        loginName: '',
                        dialogMsg: response.data['msg'],
                        dialog: true,
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        this.ComponentUI.current.errorHandler("An unexpected error occured. Please contact your system administrator")
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
        }
        else{
            this.ComponentUI.current.errorHandler("New Password and Confirm Password Mismatch!")
        }
        
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    goHomePage = () => {
        this.props.history.push({
            pathname: '/'
        })
    }

    render() {
        return (
            <div>

                <DialogComponent
                    onClose={this.closeDialog}
                    dialogTitle={this.state.dialogTitle}
                    dialogMessage={this.state.dialogMsg}
                    open={this.state.dialog}
                    onClick={this.goHomePage} />

                <PageComponent 
                    action={this.resetPasswordHandler} 
                    ref={this.ComponentUI} 
                    username={this.state.loginName} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(SurveyorResetPassword));
