import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import RemoveIcon from '@material-ui/icons/Remove';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../Util/GenericTextInput';
import GenericNumberTextInput from '../../Util/GenericNumberTextInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section1Component extends React.Component {

    checkbox_onChangeHandler = event => {
        this.props.onChange_checkbox(event.target.checked)
    }

    input_1_onChangeHandler = (event, i) => {
        this.props.onChange_value1(event.target.value, i)
    }

    input_2_onChangeHandler = (event, i) => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.onChange_value2(event.target.value, i)
        }
    }

    input_3_onChangeHandler = (event, i) => {
        this.props.onChange_value3(event.target.value, i)
    }

    input_4_onChangeHandler = (event, i) => {
        this.props.onChange_value4(event.target.value, i)
    }
    
    input_5_onChangeHandler = (event, i) => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.onChange_value5(event.target.value, i)
        }
    }

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid container
                    direction="row"
                    spacing={3}
                    alignItems="center"
                    className={classes.padding}>

                    <Grid item xs={12} sm={10}>
                        Please select if basic charge is applicable.
                        </Grid>
                    <Grid item xs={12} sm={2}>
                        <Checkbox name="section1_checkbox"
                            checked={this.props.checkbox}
                            onChange={this.checkbox_onChangeHandler}
                        />
                    </Grid>
                </Grid>

                <Divider />

                <Typography
                    variant="subtitle1"
                    className={classes.padding}>
                    Please add and fill in the details accordingly.
                </Typography>

                {this.props.input_1.map((e1, i) => (
                    <Grid container
                        direction="row"
                        spacing={2}
                        className={classes.padding}
                        key={i}>

                        <Grid item xs={12} sm={1}>
                            <p>{i + 1}</p>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericTextInput
                                label="Particulars"
                                value={this.props.input_1[i]}
                                inputProps={{ maxLength: 50 }}
                                onChange={event => this.input_1_onChangeHandler(event, i)}
                                disabled={this.props.input_1[i] === "Basic Charge" &&
                                        this.props.checkbox === true ? true : false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Quantity"
                                value={this.props.input_2[i]}
                                inputProps={{ maxLength: 7 }}
                                onChange={event => this.input_2_onChangeHandler(event, i)}
                                disabled={this.props.input_1[i] === "Basic Charge" &&
                                        this.props.checkbox === true ? true : false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Unit"
                                value={this.props.input_3[i]}
                                inputProps={{ maxLength: 7 }}
                                onChange={event => this.input_3_onChangeHandler(event, i)}
                                disabled={this.props.input_1[i] === "Basic Charge" &&
                                        this.props.checkbox === true ? true : false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Rate"
                                value={this.props.input_4[i]}
                                inputProps={{ maxLength: 10 }}
                                onChange={event => this.input_4_onChangeHandler(event, i)}
                                disabled={this.props.input_1[i] === "Basic Charge" &&
                                        this.props.checkbox === true ? true : false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Amount"
                                value={this.props.input_5[i]}
                                inputProps={{ maxLength: 10 }}
                                onChange={event => this.input_5_onChangeHandler(event, i)}
                                disabled={this.props.input_1[i] === "Basic Charge" &&
                                        this.props.checkbox === true ? true : false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}
                            style={{ textAlign: 'right' }}>
                            <IconButton
                                variant="contained"
                                color="secondary"
                                onClick={() => this.props.action1(i)}
                                disabled={this.props.input_1[i] === "Basic Charge" &&
                                        this.props.checkbox === true ? true : false}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);