import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class HistoryDialogComponent extends React.Component {

    openHandler = (form_id) => {
        this.props.action(form_id)
    }

    render() {
        const { classes } = this.props
        return (
            <Dialog
                onClose={this.props.onClose}
                open={this.props.open}>

                <DialogTitle>
                    Transaction History for Job No. {this.props.job_no}
                </DialogTitle>
                <DialogContent>
                    {!this.props.data.length ?
                        null
                        :
                        <Table>
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.form_id}>
                                        <TableCell align="center">
                                            {x.form_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.curr_stage}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.status}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.active}
                                        </TableCell>
                                        <TableCell align="center">
                                            {x.updated_at}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => this.openHandler(x.form_id)}>
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HistoryDialogComponent);