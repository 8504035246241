import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
    padding: {
        padding: theme.spacing(2)
    }
})

class Section6Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 6 - Claims
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Survey Fee Details
                    </Typography>
                </AccordionDetails>

                <Divider />

                {!this.props.data.length ?

                    <AccordionDetails>
                        <Grid container
                            direction="row"
                            spacing={3}
                            className={classes.padding}
                        >
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="subtitle1">
                                    No data here...
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>

                    :

                    this.props.data.map(x => (
                        <AccordionDetails
                            key={x.deposit}>
                            <Grid container
                                direction="row"
                                spacing={3}
                                className={classes.padding}
                            >

                                {/* Line 1 */}
                                <Grid item xs={12} sm={2}>
                                    Deposit
                                </Grid>
                                <Grid container item xs={12} sm={2}
                                    justify="flex-end">
                                    <b>{x.deposit}</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Actual Survey Fee
                                </Grid>
                                <Grid container item xs={12} sm={2}
                                    justify="flex-end">
                                    <b>{x.actual_survey_fee}</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>

                                {/* Line 2 */}
                                <Grid item xs={12} sm={2}>
                                    Additional Deposit
                                </Grid>
                                <Grid container item xs={12} sm={2}
                                    justify="flex-end">
                                    <b>{x.add_deposit}</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    5% Admin Charge
                                </Grid>
                                <Grid container item xs={12} sm={2}
                                    justify="flex-end">
                                    <b>{x.admin_charge}</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>

                                {/* Line 3 */}
                                <Grid item xs={12} sm={2}>
                                    Refund
                                </Grid>
                                <Grid container item xs={12} sm={2}
                                    justify="flex-end">
                                    <b>{x.refund}</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Claim
                                </Grid>
                                <Grid container item xs={12} sm={2}
                                    justify="flex-end">
                                    <b>{x.claim_amount}</b>
                                </Grid>

                                {x.claim_percent === 100 ?

                                    <Grid item xs={12} sm={2}>
                                        <b>C</b> %
                                    </Grid>
                                    :

                                    <Grid item xs={12} sm={2}>
                                        <b>{x.claim_percent}</b> %
                                    </Grid>
                                }
                                <br />
                                <br />
                                <br />
                                <br />

                                {/* Line 4 */}
                                <Grid item xs={12} sm={2}>

                                </Grid>
                                <Grid item xs={12} sm={2}>


                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    BALANCE
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <b>{x.balance}</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>
                                <Grid item xs={12} sm={2}>

                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    ))}



                <Divider />

                <AccordionDetails>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.props.action}>
                        View Claim Details
                    </Button>
                </AccordionDetails>

            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section6Component);