import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        textAlign: 'center'
    }
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Grid item xs={12} className={classes.root}>
                <Typography variant="h3">
                    LJS Staff Login Account
                </Typography>
                <Typography variant="h6">
                    Create new account for LJS Staff
        </Typography>
            </Grid >
        )
    }
}

export default withStyles(styles)(HeaderComponent);