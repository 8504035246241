import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class ArticledGraduateForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false,
            dialog: false
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem('articledgraduate_id') !== 'new') {
            this.fetchData()
        }
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_articledgraduate/application/initialize',
        {
            'articledgraduate_id': sessionStorage.getItem('articledgraduate_id')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === 'Success') {
                console.log(response.data[1])
                this.ComponentUI.current.initializeHandler(response.data[1])
            }
            this.setState({ backdrop: false })
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    addData = (
        graduate_name, ic_no, home_address, office_address,
        qualification, prac_exp, remark,
        file_object_1, file_name_1,
        file_object_2, file_name_2, file_object_3,
        file_name_3
    ) => {
        //LS Staff route added on 12/10/2021
        if (localStorage.getItem('perm') === "2") {
            var url = Config() + '/ls_articledgraduate/application/ls_staff/add'
        }
        else {
            url = Config() + '/ls_articledgraduate/application/add'
        }

        axios.post(url,
            {
                'articledgraduate_id': sessionStorage.getItem('articledgraduate_id'),
                'graduate_name': graduate_name,
                'ic_no': ic_no,
                'home_address': home_address,
                'office_address': office_address,
                'qualification': qualification,
                'prac_exp': prac_exp,
                'remark': remark
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.addFile(response.data[1],
                        file_object_1, file_name_1,
                        file_object_2, file_name_2,
                        file_object_3, file_name_3)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    addFile = (
        articledgraduate_id,
        file_object_1, file_name_1,
        file_object_2, file_name_2,
        file_object_3, file_name_3
    ) => {
        const data = new FormData()

        for (let i = 0; i < file_object_1.length; i++) {
            data.append('file_object_1', file_object_1[i])
            data.append('file_name_1', file_name_1[i])
        }

        for (let i = 0; i < file_object_2.length; i++) {
            data.append('file_object_2', file_object_2[i])
            data.append('file_name_2', file_name_2[i])
        }

        for (let i = 0; i < file_object_3.length; i++) {
            data.append('file_object_3', file_object_3[i])
            data.append('file_name_3', file_name_3[i])
        }

        data.append('articledgraduate_id', articledgraduate_id)

        axios.post(Config() + '/ls_articledgraduate/application/add/file',
            data,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    goNext = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/ArticledGraduateList'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    onClose={this.closeDialog}
                    open={this.state.dialog}
                    onClick={this.goNext} />

                <HeaderComponent />
                <FormComponent
                    action={this.addData}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(ArticledGraduateForm));