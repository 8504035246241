import React from 'react';

import Grid from '@material-ui/core/Grid';

import GenericLoadTextField from '../../Util/GenericLoadTextField';
import GenericIdleTextField from '../../Util/GenericIdleTextField';

class SectionTotalComponent extends React.Component {
    render() {
        return (
            <Grid container
                direction="row"
                justify="flex-end"
                alignItems="center">
                <Grid item xs={12} sm={3}>
                    {this.props.loading ?
                        <GenericLoadTextField
                            label="Total"
                            name="total_amount"
                            value={this.props.value}
                        />
                        :
                        <GenericIdleTextField
                            label="Total"
                            name="total_amount"
                            value={this.props.value}
                        />}
                </Grid>
            </Grid>
        )
    }
}

export default SectionTotalComponent;