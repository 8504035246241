import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import RequiredTooltipTextInput from '../Util/RequiredTooltipTextInput';
import DateInputComponent from '../Util/DateInputComponent';
import SelectRequiredTextInput from '../Util/SelectRequiredTextInput';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    },
    input: {
        display: 'none',
    },
    fieldset: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        margin: 0,
        padding: 20
    },
    legend: {
        color: 'grey',
        textAlign: 'left',
        marginLeft: 0
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

// class DraftIdleButton extends React.Component {
//     render() {
//         return (
//             <Button
//                 variant="contained"
//                 color="primary"
//                 className={this.props.className}
//                 onClick={this.props.onClick}
//             >
//                 Save as Draft
//             </Button>
//         )
//     }
// }

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

// class DraftLoadButton extends React.Component {
//     render() {
//         return (
//             <Button
//                 variant="contained"
//                 color="primary"
//                 disabled
//                 className={this.props.className}
//             >
//                 ...
//                 <CircularProgress size={24} />
//             </Button>
//         )
//     }
// }

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            file_object: [],
            file_name: [],
            file_size: [],

            //input fields
            input_category: '',
            input_start_date: new Date(),
            input_end_date: new Date(),

            shrink: true,

            error_msg: ''
        }
    }

    //Added initialization function on 13/10/2021
    initializeHandler = (data) => {
        this.input_description.value = data[0]['description']
        this.input_organizer.value = data[0]['organizer']
        this.input_venue.value = data[0]['venue']
        this.input_points.value = data[0]['points_applied']

        this.setState({
            input_category: data[0]['category'],
            input_start_date: new Date(data[0]['start_date']),
            input_end_date: new Date(data[0]['end_date'])
        })
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onChangeHandler_start_date = date => {
        this.setState({ input_start_date: date })
    }

    onChangeHandler_end_date = date => {
        this.setState({ input_end_date: date })
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object: [...prevState.file_object, file],
                file_name: [...prevState.file_name, file.name],
                file_size: [...prevState.file_size, file.size]
            }))
        })
    }

    removeHandler = (i) => {
        let file_object = [...this.state.file_object]
        let file_name = [...this.state.file_name]
        let file_size = [...this.state.file_size]
        file_object.splice(i, 1)
        file_name.splice(i, 1)
        file_size.splice(i, 1)
        this.setState({ file_object, file_name, file_size })
    }

    submitHandler = () => {
        this.setState({ loading: true })

        this.setState({
            error_category: this.state.input_category ? false : true,
            error_description: this.input_description.value ? false : true,
            error_organizer: this.input_organizer.value ? false : true,
            error_venue: this.input_venue.value ? false : true,
            error_start_date: this.state.input_start_date ? false : true,
            error_end_date: this.state.input_end_date ? false : true,
            error_points: this.input_points.value ? false : true
        })

        if (this.state.input_category === '' ||
            this.input_description.value === '' ||
            this.input_organizer.value === '' ||
            this.input_venue.value === '' ||
            this.input_points.value === '' ||

            this.state.input_start_date === null ||
            this.state.input_end_date === null) {

            this.errorHandler("Please fill in the required input/s.")
        }

        // else if ((this.state.input_start_date).getFullYear() !== new Date().getFullYear()) {
        //     this.errorHandler("Please submit the CPD application for the current year.")
        // }

        else if ((this.state.input_start_date).getFullYear() < new Date().getFullYear()) {
            this.errorHandler("Please submit the CPD application that is not older than the current year.")
        }

        else {
            if (
                !this.state.file_name.length) {

                this.errorHandler("Please upload the relevant documents for the CPD Application.")
            }

            else {
                if (
                    Number(this.state.file_size.reduce((prev, next) =>
                        (Number(prev) + Number(next)), 0)) >= 50000000) {

                    this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
                }

                else {
                    var formatted_start_date = (this.state.input_start_date).getDate() + '/' + ((this.state.input_start_date).getMonth() + 1) + '/' + (this.state.input_start_date).getFullYear()
                    var formatted_end_date = (this.state.input_end_date).getDate() + '/' + ((this.state.input_end_date).getMonth() + 1) + '/' + (this.state.input_end_date).getFullYear()

                    this.props.action(
                        this.state.input_category,
                        this.input_description.value,
                        this.input_organizer.value,
                        this.input_venue.value,
                        formatted_start_date,
                        formatted_end_date,
                        this.input_points.value,
                        this.state.file_object,
                        this.state.file_name
                    )
                }
            }
        }
    }

    draftHandler = () => {
        if (!this.state.input_birth_date) {
            var formatted_start_date = ""
        }
        else if (!this.state.input_prac_date) {
            var formatted_end_date = ""
        }
        else {
            formatted_start_date = (this.state.input_start_date).getDate() + '/' + ((this.state.input_start_date).getMonth() + 1) + '/' + (this.state.input_start_date).getFullYear()
            formatted_end_date = (this.state.input_end_date).getDate() + '/' + ((this.state.input_end_date).getMonth() + 1) + '/' + (this.state.input_end_date).getFullYear()
        }

        this.props.action2(
            this.state.input_category,
            this.input_description.value,
            this.input_organizer.value,
            this.input_venue.value,
            formatted_start_date,
            formatted_end_date,
            this.input_points.value
        )
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Paper elevation={3} className={classes.paper}>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                <br />

                <Typography variant="h6">
                    Details
                </Typography>
                <br />
                <form noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SelectRequiredTextInput
                                error={this.state.error_category}
                                label="Category"
                                name="input_category"
                                value={this.state.input_category}
                                onChange={this.onChangeHandler}
                                data={this.props.data}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTooltipTextInput
                                error={this.state.error_description}
                                label="Description"
                                name="input_description"
                                inputProps={{ maxLength: 100 }}
                                title="Name of the event.
                            If service to profession, specify designation such as LJS Board Member, SAJUTA Exco Member, RISM Committee Member etc."
                                inputRef={node => (this.input_description = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTooltipTextInput
                                error={this.state.error_organizer}
                                label="Organizer/Association Name"
                                name="input_organizer"
                                inputProps={{ maxLength: 100 }}
                                title="Name of the organizer or if service to profession, specify Sabah Surveyors Board, SAJUTA or RISM"
                                inputRef={node => (this.input_organizer = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTooltipTextInput
                                error={this.state.error_venue}
                                label="Venue/Association Office"
                                name="input_venue"
                                inputProps={{ maxLength: 100 }}
                                title="e.g. Auckland Convention Centre, New Zealand; Sabah, Malaysia"
                                inputRef={node => (this.input_venue = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <fieldset
                                className={classes.fieldset}
                            >
                                <legend
                                    className={classes.legend}
                                >
                                    Date
                                </legend>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} md={6}>
                                        <DateInputComponent
                                            label="From (dd/mm/yyyy)"
                                            name="input_start_date"
                                            value={this.state.input_start_date}
                                            onChange={this.onChangeHandler_start_date}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <DateInputComponent
                                            label="To (dd/mm/yyyy)"
                                            name="input_end_date"
                                            value={this.state.input_end_date}
                                            onChange={this.onChangeHandler_end_date}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>


                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_points}
                                label="Credit Points Applied"
                                name="input_points"
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_points = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h6">Upload Relevant Documents</Typography>
                    <Typography variant="body2">
                    *e.g. Certificate of Attendance, After-Conference Report, Appointment Letter, Invitation Letter, Notice of AGM, etc.
                    </Typography>
                    <br />
                    <input
                        accept="image/*,application/pdf"
                        className={classes.input}
                        id="contained-button-file_1"
                        multiple
                        type="file"
                        onChange={this.uploadHandler}
                    />
                    <label htmlFor="contained-button-file_1">
                        <Button variant="contained" color="primary" component="span"
                            className={classes.button}>
                            Upload
                        </Button>
                    </label>
                    <Table aria-label="simple table">
                        <TableBody>
                            {this.state.file_name.length === 0 ?
                                <TableRow>
                                    <TableCell>No files uploaded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                this.state.file_name.map((x, i) => (
                                    <TableRow key={x}>
                                        <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                        <TableCell style={{ width: '50%' }}>
                                            <IconButton color="secondary"
                                                onClick={() => this.removeHandler(i)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </form>

                {this.state.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextLoadButton className={classes.button} />
                        {/* <DraftLoadButton className={classes.button} /> */}
                    </Box>

                    :

                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextIdleButton className={classes.button}
                            onClick={this.submitHandler} />
                        {/* <DraftIdleButton className={classes.button}
                            onClick={this.draftHandler} /> */}
                    </Box>
                }
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);