import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        textAlign: 'center'
    }
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Grid item xs={12} className={classes.root}>
                    <Typography variant="h4">
                        Application for Registration as Engineering Survey Technician
                        </Typography>
                    <br />
                    <Typography variant="subtitle1">
                        Please fill in the information for the Engineering Technician application.
                        </Typography>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(HeaderComponent);