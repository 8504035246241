import React from 'react';

import Button from '@material-ui/core/Button';

import SearchIcon from '@material-ui/icons/Search';

class SearchButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            className={this.props.className}
            startIcon={<SearchIcon />}
            onClick={this.props.onClick}
            >
                Search
            </Button>
        )
    }
}

export default SearchButton;