import React from 'react';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    align: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section2ComponentNEW extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Grid container
                direction="row"
                spacing={3}>

                <Grid item xs={12} sm={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Collapse in={this.props.visible}>
                        <Grow in={this.props.visible}>
                            <b>Registered Survey Job</b>
                        </Grow>
                    </Collapse>
                </Grid>

                {this.props.data.map(x => (
                    <Grid item xs={12} sm={3}
                        key={x.description}>
                        <Card className={classes.align}>
                            <CardContent>
                                <Typography variant="h1">
                                    {x.count}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {x.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => this.props.action(x.route)}>
                                    View List
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2ComponentNEW);