import React from 'react';

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';

import StepperComponent from './Component/StepperComponent';
import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';
import ButtonComponent from './Component/ButtonComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class EngineeringTableList extends React.Component {

    redirectionTable = (table) => {
        this.props.history.push({
            pathname: '/ui/EngineeringTable' + table
        })
    }

    goTableSummary = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringTableSummary'
        })
    }

    goApplicationForm = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringForm'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <StepperComponent />
                <br />
                <HeaderComponent />
                <TableComponent action={this.redirectionTable} />
                <ButtonComponent
                    action={this.goTableSummary}
                    action2={this.goApplicationForm} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(EngineeringTableList));