import React from 'react';

import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import BackIdleButton from '../../../Util/BackIdleButton';
import GenericIdleButton from '../../../Util/GenericIdleButton';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {!this.props.stage.length ?
                    <Box pt={4}
                        className={classes.box}>

                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} />
                    </Box>
                    :

                    //Only shows the back button if it is LS Staff
                    localStorage.getItem('perm') === "2" ?
                        <Box pt={4}
                            className={classes.box}>
                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                        :

                        //Show submit button if only the status is Draft
                        localStorage.getItem('perm') !== "2" &&
                            this.props.stage[0]['status'] === "Draft" ?
                            <Box pt={4}
                                className={classes.box}>

                                <GenericIdleButton
                                    className={classes.button}
                                    onClick={this.props.action2}
                                    label="Submit"
                                />
                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />
                            </Box>

                            :

                            <Box pt={4}
                                className={classes.box}>

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />
                            </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);