import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: 'center'
    }
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Divider />
                <br />
                <Grid item xs={12} className={classes.root}>
                    <Typography variant="h3">
                        Table 10
                    </Typography>
                    <Typography variant="h6">
                        Time Rates
                    </Typography>
                    <Typography variant="subtitle1">
                        Fees may be charged by time rates. In this schedule, “field work” means the survey work in the field done by a survey field party headed by a survey technician who is assisted by a party of labourers and equipped with the normal survey equipment (e.g. Theodolite, level, EDM, chain, total station, G.P.S., etc.) suitable for the job in hand. The rates for the normal categories are as follows:
                    </Typography>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HeaderComponent);