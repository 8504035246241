import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class EngineeringClaimSelector extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            percentage: 0,
            backdrop: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })

        axios.put(Config() + '/ls_claim/engineering/application/initialize',
        {
            'form_id': sessionStorage.getItem('form_id_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data === '') {
                this.setState({ percentage: 0 })
            }
            else {
                this.setState({ percentage: response.data })
            }
            this.setState({ backdrop: false })
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    goNext = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringClaimForm'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringJobView'
        })
    }

    render() {
        const { classes } = this.props
        
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <FormComponent
                ref={this.ComponentUI}
                value={this.state.percentage}
                action={this.goNext}
                action2={this.goBack} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(EngineeringClaimSelector));