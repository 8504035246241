import React from 'react';

import TextField from '@material-ui/core/TextField';

class RequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                disabled={this.props.disabled}
                error={this.props.error}
                helperText={this.props.helperText}
                variant="standard"
                margin="normal"
                fullWidth
                type="tel"
                label={this.props.label}
                inputProps={this.props.inputProps}
                onChange={this.props.onChange}
                value={this.props.value}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

export default RequiredTextInput;