import React from 'react';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    align: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
    table: {
        minWidth: 1000
    }
})

class Section5Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid container
                    direction="row"
                    spacing={3}>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Collapse in={this.props.visible}>
                            <Grow in={this.props.visible}>
                                <b>Pending Survey Job Claim Application</b>
                            </Grow>
                        </Collapse>
                    </Grid>

                </Grid>
                <Paper className={classes.paper}>
                    <Table className={classes.table}>
                        <TableBody>
                            {this.props.data.map(x => (
                                <TableRow key={x.description}>
                                    <TableCell style={{ width: '40%', textAlign: 'center' }}>
                                        <Typography variant="h3">
                                            {x.count}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '50%' }}>{x.description}</TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Button variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => this.props.action(x.route)}>
                                            View List
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section5Component);