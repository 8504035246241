import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import SelectRequiredTextInput from '../Util/SelectRequiredTextInput';
import RequiredTextInput from '../Util/RequiredTextInput';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class DialogComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_error: false,
            input_helper: '',
        }
    }

    onChangeHandler = event => {
        this.props.action(event.target.value)
    }

    onChangeExemption = event => {
        const re = /^\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.action2(event.target.value)
        }
    }

    onChangeMandatory = event => {
        const re = /^\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.action3(event.target.value)
        }
    }

    submitHandler = () => {
        if (this.props.value1 === '' ||
            this.props.value2 === '' ||
            this.props.value3 === '') {

            this.setState({
                input_error: true,
                input_helper: 'Please fill in all the required fields.'
            })
        }

        else {
            this.props.action4()
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Dialog
                onClose={this.props.onClose}
                open={this.props.open}>

                <DialogTitle>
                    Recalculate Points
                </DialogTitle>
                {!this.props.data.length ?
                    <DialogContent>
                        <DialogContentText>
                            No data available.
                    </DialogContentText>
                    </DialogContent>
                    :
                    <DialogContent>
                        <DialogContentText>
                            Please select a log year to modify and recalculate.
                    </DialogContentText>
                        <SelectRequiredTextInput
                            label="Select a Log Year"
                            name="input_log_year"
                            value={this.props.value1}
                            onChange={this.onChangeHandler}
                            data={this.props.data}
                        />

                        {/* Value can only be entered when log year is selected */}
                        <RequiredTextInput
                            disabled={!this.props.value1 ? true : false}
                            error={this.state.input_error}
                            helperText={this.state.input_helper}
                            label="Exemption"
                            inputProps={{ maxLength: 2 }}
                            onChange={this.onChangeExemption}
                            value={this.props.value2}
                        />

                        <RequiredTextInput
                            disabled={!this.props.value1 ? true : false}
                            error={this.state.input_error}
                            helperText={this.state.input_helper}
                            label="Mandatory Points"
                            inputProps={{ maxLength: 2 }}
                            onChange={this.onChangeMandatory}
                            value={this.props.value3}
                        />
                    </DialogContent>
                }
                <DialogActions>
                    <Button variant="contained" color="primary"
                        onClick={this.submitHandler}
                        className={classes.button}>
                        Recalculate
                    </Button>
                    <Button variant="contained" color="secondary"
                        onClick={this.props.action5}
                        className={classes.button}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);