import React from 'react';

import Button from '@material-ui/core/Button';

class ButtonBack extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

export default ButtonBack;