import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';

class RequiredTooltipTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="standard"
                margin="normal"
                fullWidth
                multiline
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                InputProps={{
                    endAdornment:
                        <Tooltip interactive
                            classes={{ tooltip: this.props.tooltip }}
                            title={this.props.title}>
                            <InputAdornment position="end">
                                <HelpIcon />
                            </InputAdornment>
                        </Tooltip>
                }}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

export default RequiredTooltipTextInput;