import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../Util/GenericTextInput';
import GenericNumberTextInput from '../../Util/GenericNumberTextInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section1Component extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Typography
                    variant="subtitle1"
                    className={classes.padding}>
                    Details of the survey fee table.
                </Typography>

                {this.props.input_1.map((e1, i) => (
                    <Grid container
                        direction="row"
                        spacing={2}
                        className={classes.padding}
                        key={i}>

                        <Grid item xs={12} sm={1}>
                            <p>{i + 1}</p>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericTextInput
                                label="Particulars"
                                value={this.props.input_1[i]}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Quantity"
                                value={this.props.input_2[i]}
                                inputProps={{ maxLength: 7 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Unit"
                                value={this.props.input_3[i]}
                                inputProps={{ maxLength: 7 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Rate"
                                value={this.props.input_4[i]}
                                inputProps={{ maxLength: 7 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Amount"
                                value={this.props.input_5[i]}
                                inputProps={{ maxLength: 7 }}
                            />
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);