import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class DialogComponent extends React.Component {
    render() {
        const { classes } = this.props
        return (
            <Dialog
            onClose={this.props.onClose}
            open={this.props.open}>
                <DialogTitle>
                    Articled Graduate Application Submitted!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Your articled graduate application has been submitted!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary"
                        onClick={this.props.onClick}
                        className={classes.button}>
                        Got it!
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);