import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="secondary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Back
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3}
                    className={classes.paper}>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.table}>1) Basic charge</TableCell>
                                <TableCell align="right" className={classes.table}>RM 1,300.00</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>2) For logging, logpond & extraction of sea sands</TableCell>
                                <TableCell align="right">as per rates prescribed under Table 1</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">
                                    <Button variant="contained" color="primary"
                                        className={classes.button}
                                        onClick={() => this.props.action(1)}>Go to Table 1</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>3) For residential, commercial and industrial purposes</TableCell>
                                <TableCell align="right">as per rates prescribed under Table 2</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">
                                    <Button variant="contained" color="primary"
                                        className={classes.button}
                                        onClick={() => this.props.action(2)}>Go to Table 2</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>4) For logging road and extraction of river sand/stone</TableCell>
                                <TableCell align="right">as per rates prescribed under Table 6</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">
                                    <Button variant="contained" color="primary"
                                        className={classes.button}
                                        onClick={() => this.props.action(6)}>Go to Table 6</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                <Box pt={4} className={classes.box}>
                    <BackIdleButton
                        className={classes.button}
                        onClick={this.props.action2} />
                </Box>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);