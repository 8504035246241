import React from 'react';

import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import GenericIdleButton from '../../../Util/GenericIdleButton';
import BackIdleButton from '../../../Util/BackIdleButton';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {!this.props.active ?

                    <Box pt={4}
                        className={classes.box}>

                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} />
                    </Box>

                    :

                    this.props.stage_data[0]['curr_stage'] === 'PAPP'
                        &&
                        this.props.stage_data[0]['status'] === 'Approved'
                        &&
                        this.props.active === 'Yes'
                        ?

                        <Box pt={4}
                            className={classes.box}>

                            <GenericIdleButton
                                className={classes.button}
                                onClick={this.props.action2}
                                label="Go to Payment" />

                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />

                        </Box>

                        :

                        this.props.stage_data[0]['curr_stage'] === 'PPAY'
                            &&
                            this.props.stage_data[0]['status'] === 'Approved'
                            &&
                            this.props.active === 'Yes'
                            ?

                            <Box pt={4}
                                className={classes.box}>

                                <GenericIdleButton
                                    className={classes.button}
                                    onClick={this.props.action1}
                                    label="Go to Application Form" />

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />

                            </Box>

                            :

                            this.props.stage_data[0]['curr_stage'] === 'PAPP'
                                &&
                                this.props.stage_data[0]['status'] === 'Rejected'
                                &&
                                this.props.active === 'Yes'
                                ?

                            <Box pt={4}
                                className={classes.box}>

                                <GenericIdleButton
                                    className={classes.button}
                                    onClick={this.props.action1}
                                    label="Go to Application Form (Resubmit)" />

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />

                            </Box>

                            :

                            this.props.stage_data[0]['curr_stage'] === 'PPAY'
                            &&
                            this.props.stage_data[0]['status'] === 'Rejected'
                            &&
                            this.props.active === 'Yes'
                            ?

                            <Box pt={4}
                                className={classes.box}>

                                <GenericIdleButton
                                    className={classes.button}
                                    onClick={this.props.action2}
                                    label="Go to Payment Form (Resubmit)" />

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />

                            </Box>

                            :

                            <Box pt={4}
                                className={classes.box}>

                                <BackIdleButton
                                    className={classes.button}
                                    onClick={this.props.action} />
                            </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);