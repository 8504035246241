import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericIdleTextField from '../../Util/GenericIdleTextField';
import GenericSelectTextInput from '../../Util/GenericSelectTextInput';

import Section3PopoverComponent from './Section3PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section3Component extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [
                {
                    id: '1',
                    value: '1',
                    text: '0 kilometre to 1 kilometre'
                },
                {
                    id: '2',
                    value: '2',
                    text: 'Above 1 kilometre but not more than 5 kilometres'
                },
                {
                    id: '3',
                    value: '3',
                    text: 'Above 5 kilometres but not more than 10 kilometres'
                },
                {
                    id: '4',
                    value: '4',
                    text: 'Above 10 kilometres'
                },
            ]
        }
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 3: Charge for work site to nearest motorable road
                        </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>

                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Please select the distance of the survey worksite from the nearest motorable road.
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section3PopoverComponent />
                        </Grid>
                    </Grid>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}>
                        <Grid item xs={12} sm={6}>
                            <GenericSelectTextInput
                                label="Distance"
                                name="section3_select"
                                value={this.props.value}
                                data={this.state.data}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>

                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericIdleTextField
                                label="Amount"
                                name="section3_amount"
                                value={this.props.valueTotal}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section3Component);