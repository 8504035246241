import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class RadioGroupComponent extends React.Component {
    render() {
        return (
            <FormControl component="fieldset" className={this.props.className}>
                <FormLabel component="legend">Percentage</FormLabel>
                <RadioGroup value={this.props.percentage} onChange={this.props.onChange}>
                    <FormControlLabel value="65" control={<Radio />} label="65% (Post Jan 2017 jobs)"
                    // disabled={parseInt(this.props.value) < 65 ? false : true} 
                    />
                    <FormControlLabel value="85" control={<Radio />} label="85% (Jobs between Year 2014 - Dec 2016)"
                    // disabled={parseInt(this.props.value) < 85 ? false : true} 
                    />
                    <FormControlLabel value="100" control={<Radio />} label="100%"
                    // disabled={parseInt(this.props.value) < 100 ? false : true} 
                    />
                </RadioGroup>
            </FormControl>
        )
    }
}

export default RadioGroupComponent;