import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class GenericIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}>
                {this.props.label}
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <div>
                <Box pt={4}
                    className={classes.box}>

                    {localStorage.getItem('perm') === "2" ?
                        // this.props.data[0]['status'] === "Draft"?
                        sessionStorage.getItem('claim_status') === "Draft"?
                        <div>
                                <GenericIdleButton
                                    className={classes.button}
                                    onClick={this.props.action}
                                    label="Edit Claim" />

                                <BackIdleButton className={classes.button}
                                    onClick={this.props.action2} />
                            </div>
                        :
                            <div>
                                <BackIdleButton className={classes.button}
                                    onClick={this.props.action2} />
                            </div>
                    :
                        sessionStorage.getItem('claim_status') === "Draft"?
                            <React.Fragment>
                                <Typography variant="subtitle1">
                                    If everything is entered correctly, click "Submit" to submit the application.
                                </Typography>
                                <br />
        
                                {this.props.loading ?
                                    <div>
                                        <NextLoadButton className={classes.button} />
                                        <BackLoadButton className={classes.button} />
                                    </div>
                                    :
                                    <div>
                                        <NextIdleButton className={classes.button}
                                            onClick={this.props.action3} />
                                        <BackIdleButton className={classes.button}
                                            onClick={this.props.action2} />
                                    </div>
                                }
                            </React.Fragment>
                        :
                            <div>
                                <BackIdleButton className={classes.button}
                                    onClick={this.props.action2} />
                            </div>
                    }
                </Box>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);