import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.reference}>

                        <Grid item xs={12} sm={4}>
                            Licensed Surveyor Reference:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.reference}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Job Type:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.job_code} {x.job_desc}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            P.T. / Land Title:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.land_title}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            JTU Reference:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.jtu_file}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            District:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.dist_code} {x.dist_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Location:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.location}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.reference}>

                        <Grid item xs={12} sm={4}>
                            Name:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.address}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Country:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.country}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            City:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.city}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            State:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.state}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Postcode:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.postcode}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Telephone Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.tel_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Email Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.email}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
    }
}

class ButtonComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Grid container
                    direction="row"
                    justify="flex-end"
                    spacing={3}
                    className={this.props.className}
                >
                    <Grid item xs={12} sm={4}>
                        <Button variant="contained"
                            color="primary"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}>
                            Go to Application Form
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

class Section1Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1 - Survey Job Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Survey Job Details
                    </Typography>
                </AccordionDetails>

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            data={this.props.data}
                        />
                    </AccordionDetails>
                }

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Landowner / Representative Details
                        </Typography>
                </AccordionDetails>

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <React.Fragment>
                        <AccordionDetails>
                            <Grid2Component
                                className={classes.padding}
                                data={this.props.data}
                            />
                        </AccordionDetails>

                        <AccordionDetails>
                            <ButtonComponent
                                className={classes.padding}
                                classNameButton={classes.button}
                                onClick={this.props.action}
                            />
                        </AccordionDetails>
                    </React.Fragment>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);