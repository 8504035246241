import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class GenericTextInput extends React.Component {
    render() {
        return (
            <TextField
                margin="normal"
                label={this.props.label}
                multiline
                fullWidth
                inputProps={{ maxLength: 200 }}
                inputRef={this.props.inputRef}
            />
        )
    }
}

class File1DialogComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            payment_type: 'bank'
        }
    }

    onChangeHandler = (event) => {
        this.setState({
            payment_type: event.target.value
        })
    }

    submitHandler = () => {
        if (this.state.payment_type === 'bank') {
            this.props.action(
                this.input_remark.value,
                this.state.payment_type,
                ''
            )
        }
        else {
            this.props.action(
                this.input_remark.value,
                this.state.payment_type,
                this.input_cheque_no.value
            )
        }
    }

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Dialog
                    onClose={this.props.onClose}
                    open={this.props.open}>

                    <DialogTitle>
                        Payment Voucher Information
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please type in the relevant information for payment voucher generation:
                        </DialogContentText>
                        <GenericTextInput
                            label="Remark"
                            inputRef={node => (this.input_remark = node)}
                        />
                        <br />
                        <br />
                        <br />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Payment Type</FormLabel>
                            <RadioGroup value={this.state.payment_type}
                                onChange={this.onChangeHandler}>
                                <FormControlLabel value="bank" control={<Radio />} label="Bank Transfer" />
                                <FormControlLabel value="cheque" control={<Radio />} label="Cheque" />
                            </RadioGroup>
                        </FormControl>

                        {this.state.payment_type === "bank" ?
                            null
                            :
                            <GenericTextInput
                                label="Cheque No."
                                inputRef={node => (this.input_cheque_no = node)}
                            />

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.submitHandler}
                            className={classes.button}>
                            Generate
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(File1DialogComponent);