import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import AlertComponent from './AlertComponent';
import GridViewComponent from './GridViewComponent';
import GridEditComponent from './GridEditComponent';
import GridViewUserinfoComponent from './GridViewUserinfoComponent';
import GridEditUserinfoComponent from './GridEditUserinfoComponent';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class FormComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3}
                className={classes.paper}>

                <AlertComponent alert={this.props.alert}
                    error_msg={this.props.error_msg} />

                <Typography variant="h6" gutterBottom>
                    User Login Details
                </Typography>

                <br />

                {this.props.viewState_2 === true ?
                    <GridViewUserinfoComponent
                        data={this.props.data}
                        action={this.props.action5}
                    />
                    :

                    <GridEditUserinfoComponent
                        data={this.props.data}
                        action={this.props.action6}
                        action2={this.props.action7} />
                }

                <br />
                <Divider />
                <br />

                <Typography variant="h6" gutterBottom>
                    User Account Details
                </Typography>

                <br />

                {this.props.viewState === true ?

                    <GridViewComponent
                        data={this.props.data}
                        action={this.props.action}
                        action2={this.props.action2} />

                    :

                    <GridEditComponent
                        data={this.props.data}
                        action3={this.props.action3}
                        action4={this.props.action4} />
                }
            </Paper>
        )
    }
}

export default withStyles(styles)(FormComponent);