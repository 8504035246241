import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import { colors } from '@material-ui/core'

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        color: colors.green[500]
    }
})

class Section3Component extends React.Component {

    openFile = (value) => {
        window.open(value, "_blank")
    }

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    Payment Details
                </Typography>

                {!this.props.data.length ?
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    <Typography
                                        variant="subtitle1">
                                        No data here...
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    :
                    this.props.data.map(x => (
                        <Grid container
                            direction="row"
                            spacing={3}
                            className={classes.padding}
                            key={x.id}>

                            <Grid item xs={12} sm={4}>
                                Amount Paid:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {x.amount_paid === '' ?
                                    <b>{x.amount_paid}</b>
                                    :
                                    <b>{x.amount_paid.toFixed(2)}</b>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                Payment Mode:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.payment_mode}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                Bank:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.bank}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                Cheque / Transaction No.:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.cheque_no}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))
                }
                <br />

                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    Proof of Payment Documents
                </Typography>

                <TableContainer>
                    <Table>
                        <TableBody>
                            {this.props.file.map(x => (
                                <TableRow key={x.id}>
                                    <TableCell>
                                        <b>{x.filename}</b>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained"
                                            className={classes.button}
                                            onClick={() => this.openFile(x.url)}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <br />

                <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Payment Form Submission Details
                    </Typography>

                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.id}>

                        <Grid item xs={12} sm={4}>
                            Submitted By:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ls_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Submitted On:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.created_at}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section3Component);