import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section7Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Grid container
                direction="row"
                spacing={3}>

                <Grid item xs={12} sm={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Collapse in={this.props.visible}>
                        <Grow in={this.props.visible}>
                            <b>Engineering Technician Termination</b>
                        </Grow>
                    </Collapse>
                </Grid>

                {!this.props.data.length ?
                    <Grid item xs={12} sm={12}>
                        <Collapse in={this.props.visible}>
                            <Grow in={this.props.visible}>
                                <Typography variant="h4">
                                    <b>It is empty here...</b>
                                </Typography>
                            </Grow>
                        </Collapse>
                    </Grid>
                    :
                    this.props.data.map(x => (
                        <Grid item xs={12} sm={3}
                        key={x.form_id}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1">
                                    <p>There is a new termination.</p>
                                </Typography>
                                <p>Technician Name: <b>{x.name}</b></p>
                                <p>Licensed Surveyor Name: <b>{x.ls_name}</b></p>
                                <p>Reason of Termination: <b>{x.terminate_comment}</b></p>
                                <p>Terminated On: <b>{x.updated_at}</b></p>

                                <Button variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => this.props.action(x.technician_id, x.curr_stage)}>
                                    View
                                            </Button>
                            </Paper>
                            </Grid>
                    ))
                }
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section7Component);