import React from 'react';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    paper: {
        maxWidth: 1200,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    tableButton: {
        fontWeight: 'bold',
        color: localStorage.getItem('theme') === 'dark' ?
            theme.palette.getContrastText("#bb86fc") :
            theme.palette.getContrastText(colors.purple[500]),

        backgroundColor: localStorage.getItem('theme') === 'dark' ?
            "#bb86fc" :
            colors.purple[500],
        '&:hover': localStorage.getItem('theme') === 'dark' ?
            {
                backgroundColor: "#9370db"
            } :
            {
                backgroundColor: colors.purple[700]
            },
        minWidth: 80
    }
})

class TotalSectionComponent extends React.Component {
    render() {
        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell
                            align="right">

                            <TextField
                                variant="outlined"
                                margin="normal"
                                name="input_sst"
                                label="SST / Prof Fee"
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            RM
                                </InputAdornment>
                                }}
                                inputProps={{ maxLength: 12 }}
                                value={this.props.value}
                                onChange={this.props.onChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell
                            align="right">
                            <TextField
                                variant="filled"
                                margin="normal"
                                name="total_amount"
                                label="Total"
                                disabled
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            RM
                                </InputAdornment>
                                }}
                                value={this.props.value2}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

class TableComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            error_msg: ''
        }
    }

    onChangeHandler = event => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.onChange(event.target.value)
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        const cellHeader = [
            {
                id: "table_name",
                label: "Table Name",
            },
            {
                id: "table_fee",
                label: "Survey Fee & Charges"
            },
        ]
        return (
            <Paper className={classes.paper}>

                {this.state.alert ?
                    <div>
                        <Alert severity="error">
                            {this.state.error_msg}
                        </Alert>
                        <br />
                    </div>
                    :
                    null}

                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.cellHeader}
                                        key={cellHeader.id}
                                        padding="default"
                                        align="center"
                                    >
                                        {cellHeader.label}
                                    </TableCell>
                                ))}
                                <TableCell className={classes.cellHeader}
                                    padding="default"
                                    align="center"
                                >
                                    Action</TableCell>
                            </TableRow>
                        </TableHead>

                        {!this.props.array.length ?
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={3}>
                                        You have not submit any Survey Fee Table yet.
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {this.props.array.map((x) => (
                                    <TableRow key={x.id}>
                                        <TableCell align="center">Table {x.table_id}</TableCell>
                                        <TableCell align="center">{(x.total_amount).toFixed(2)}</TableCell>
                                        <TableCell align="center">
                                            <Button className={classes.tableButton}
                                                onClick={() => this.props.action(x.table_id)}>
                                                Edit
                                        </Button>
                                        &nbsp;
                                        <Button className={classes.tableButton}
                                                onClick={() => this.props.action2(x.table_id, x.id)}>
                                                Remove
                                        </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>

                <TotalSectionComponent
                    onChange={this.onChangeHandler}
                    value={this.props.value}
                    value2={this.props.value2}
                />
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);