import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../../../Claim Review/Util/GenericTextInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                    direction="row"
                    spacing={3}
                    className={this.props.className}
                    key={x.total_deposit}>
                    <Grid item xs={6} sm={3}>
                        Available Balance:
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.available_bal.toFixed(2)}</b>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {/* <Grid item xs={6} sm={3}>
                        Actual Survey Fee:
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}> */}

                        {/* <b style={{ color: 'green' }}>
                            RM {x.current_claim.toFixed(2)}
                        </b> */}

                        {/* RM <b>{x.actual_survey_fee_current.toFixed(2)}</b>
                    </Grid> */}

                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        {sessionStorage.getItem('claim_percent_review')} % Survey Fee:
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.new_survey_fee.toFixed(2)}</b>
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>

                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        LJS 5% Admin Charge:
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.curr_admin_charge.toFixed(2)}</b>
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>

                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        Disbursement:
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.new_disbursement.toFixed(2)}</b>
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>

                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        Prof. Fees / SST:
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.new_sst.toFixed(2)}</b>
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>

                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        Excess Fee:
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.new_excess_fee.toFixed(2)}</b>
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        Remaining Balance:
                    </Grid>
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}></Grid>
                    <Grid item xs={6} sm={3}>
                        RM <b>{x.remaining_bal.toFixed(2)}</b>
                    </Grid>
                    </Grid>
                ))}

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonIdleComponent
                        color="primary"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                        name="Edit Claim Amount"
                    />
                </Box>
            </div>
        )
    }
}

class Grid1ComponentEdit extends React.Component {
    constructor() {
        super()
        this.state = {
            input_claim_survey_fee: 0.0,
            input_claim_levy: 0.0,
            input_claim_disbursement: 0.0,
            input_claim_sst: 0.0
        }
    }

    onChangeHandler_surveyfee = (e) => {
        const re = /^[0-9\b]+$/
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({input_claim_survey_fee: e.target.value})
        }
        console.log(" input_claim_survey_fee = "+this.state.input_claim_survey_fee)
    }

    onChangeHandler_levy = (e) => {
        const re = /^[0-9\b]+$/
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({input_claim_levy: e.target.value})
        }
        console.log(" input_claim_levy = "+this.state.input_claim_levy)
    }

    onChangeHandler_disbursement = (e) => {
        const re = /^[0-9\b]+$/
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({input_claim_disbursement: e.target.value})
        }
        console.log(" input_claim_disbursement = "+this.state.input_claim_disbursement)
    }

    onChangeHandler_sst = (e) => {
        const re = /^[0-9\b]+$/
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({input_claim_sst: e.target.value})
        }
        console.log(" input_claim_sst = "+this.state.input_claim_sst)
    }

    submitHandler = () => {
        this.props.action(
            this.input_claim_survey_fee.value,
            this.input_claim_levy.value,
            this.input_claim_disbursement.value,
            this.input_claim_sst.value
        )
    }

    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.total_deposit}>

                        <Grid item xs={12} sm={4}>
                            Survey Fees Amount: RM
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_claim_survey_fee = node)}
                                defaultValue={x.new_survey_fee.toFixed(2)}
                                onChange={this.onChangeHandler_surveyfee}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            LJS 5% Admin Charge: RM
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_claim_levy = node)}
                                defaultValue={x.curr_admin_charge.toFixed(2)}
                                onChange={this.onChangeHandler_levy}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Disbursement: RM
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_claim_disbursement = node)}
                                defaultValue={x.new_disbursement.toFixed(2)}
                                onChange={this.onChangeHandler_disbursement}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Prof. Fees / SST:: RM
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_claim_sst = node)}
                                defaultValue={x.new_sst.toFixed(2)}
                                onChange={this.onChangeHandler_sst}
                            />
                        </Grid>
                    </Grid>
                ))}

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonLoadComponent
                            color="primary"
                            className={this.props.classNameButton}
                            name="Updating..."
                        />

                        <ButtonLoadComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            name="..."
                        />
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonIdleComponent
                            color="primary"
                            className={this.props.classNameButton}
                            onClick={this.submitHandler}
                            name="Update"
                        />
                        <ButtonIdleComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}
                            name="Cancel"
                        />
                    </Box>
                }
            </div>
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color={this.props.color}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.name}
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                {this.props.name}
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BreakdownComponent2 extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        claim_survey_fee, claim_levy, claim_disbursement, claim_sst
    ) => {

        this.props.action(
            claim_survey_fee, claim_levy, claim_disbursement, claim_sst
        )
    }

    render() {
        const { classes } = this.props
        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Breakdown for Current Selected Claim Percentage -
                        <b> {sessionStorage.getItem('claim_percent_review')} %</b>
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        
                {this.state.viewState === true ?
                            <React.Fragment>
                                <Grid1Component
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>

                            :

                            <React.Fragment>
                                <Grid1ComponentEdit
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    loading={this.props.loading}
                                    action={this.submitHandler}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>
                        }

                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(BreakdownComponent2);