import React from 'react'

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2),
        maxWidth: 700
    },
    table: {
        borderBottom: "none"
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section3PopoverComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            anchor: null
        }
    }

    toggleHandler = e => {
        this.setState({
            open: !this.state.open,
            anchor: e.currentTarget
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.toggleHandler}
                >
                    Reference
                </Button>

                <Popover
                    open={this.state.open}
                    onClose={this.toggleHandler}
                    anchorEl={this.state.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.padding}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.table}>Survey of land and preparation of index and storey plans as required under Section 6 of Land (Subsidiary Titles) Enactment No. 9 of 1972:</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="right" style={{ width: 200 }}>RM</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>a) For land upto 200 square metres</TableCell>
                                    <TableCell className={classes.table} align="right">4,500.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>b) Upto 300 square metres</TableCell>
                                    <TableCell className={classes.table} align="right">5,625.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>c) Upto 400 square metres</TableCell>
                                    <TableCell className={classes.table} align="right">6,375.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>d) Upto 500 square metres</TableCell>
                                    <TableCell className={classes.table} align="right">6,750.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>e) For the next 500 – 1,000 square metres add</TableCell>
                                    <TableCell className={classes.table} align="right">10.50 per sq. metre</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>f) For the next 1,000 – 1,500 square metres add</TableCell>
                                    <TableCell className={classes.table} align="right">7.50 per sq. metre</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>g) For the next 1,500 – 3,000 square metres add</TableCell>
                                    <TableCell className={classes.table} align="right">3.00 per sq. metre</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>h) Above 3,000 square metres add</TableCell>
                                    <TableCell className={classes.table} align="right">1.50 per sq. metre</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.table}>The above rates include the cost of connections, controls and refixation or reestablishment of missing marks and preparation of the plan.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section3PopoverComponent);