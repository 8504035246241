import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: 'center'
    }
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Divider />
                <br />
                <Grid item xs={12} className={classes.root}>
                    <Typography variant="h3">
                        Table 6
                    </Typography>
                    <Typography variant="h6">
                        For Survey of Land for Reserves & Special Purpose
                    </Typography>
                    <Typography variant="subtitle1">
                        The Table covers the survey of land that are not covered by Table 1 to 4 i.e. Forest, Game, Wild Life, National Park, Catchment areas, Grazing, Native, Government or other similar reserves. Each reserve may cover different types of terrain, such as flat, undulating, hilly, fresh water swamp, tidal swamp etc. Different rates have been charged based on different conditions of each item and the total of the separate items becomes the surveys fees of the job.
                    </Typography>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HeaderComponent);