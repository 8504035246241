import React from 'react';
import Button from '@material-ui/core/Button';

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

export default BackLoadButton;