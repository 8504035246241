import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import ButtonBack from '../Util/ButtonBack';
import ButtonEdit from '../Util/ButtonEdit';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class GridViewComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.ls_no}>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Licensed Surveyor No:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_no}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Title:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_title}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Full Name:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_name}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            IC No:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ic_no}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Company Name:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.co_name}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Status:
                        </Grid>
                        {x.ls_status === 'A' ?
                            <Grid item xs={12} sm={8} md={8} lg={8}>
                                <b>Active</b>
                            </Grid>
                        :
                            x.ls_status === 'I' ?
                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <b>Inactive</b>
                                </Grid>
                            :
                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <b>Undefined</b>
                                </Grid>
                        }
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Email Address:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.email}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Phone Number:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.phone}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Mobile Number:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.mobile}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Fax:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.fax}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 1:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.addr1}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 2:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.addr2}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 3:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.addr3}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 4:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.addr4}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 1:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.paddr1}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 2:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.paddr2}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 3:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.paddr3}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 4:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.paddr4}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            License Number:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.lic_no}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Service Tax:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.svc_tax}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            GL Account No.:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.gl_acctno}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Default Payee Name:
                        </Grid>
                        {x.payee_ind === 'LS' ?
                            <Grid item xs={12} sm={8} md={8} lg={8}>
                                <b>Licensed Surveyor - {x.ls_name}</b>
                            </Grid>
                        :
                            x.payee_ind === 'CO' ?
                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <b>Company - {x.co_name}</b>
                                </Grid>
                            :
                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <b>Undefined</b>
                                </Grid>
                        }
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            LS Account No.:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_acct_no}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            LS Bank:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_bank}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            LS Bank Branch:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_bank_branch}</b>
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4}
                    className={classes.box}>

                    <ButtonEdit
                        className={classes.button}
                        onClick={this.props.action}
                    />

                    <ButtonBack
                        className={classes.button}
                        onClick={this.props.action2}
                    />
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(GridViewComponent);