import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class CadastralTechnicianTerminateForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            dialog: false
        }
    }

    addData = (
        file_object, file_name, input
    ) => {
        const data = new FormData()

        for (let i = 0; i < file_object.length; i++) {
            data.append('file_object', file_object[i])
            data.append('file_name', file_name[i])
        }

        data.append('input', input)
        data.append('technician_id', sessionStorage.getItem('technician_id_view'))

        axios.post(Config() + '/ls_technician/cadastral/termination/add',
            data,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTechnicianView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <DialogComponent
                    onClose={this.closeDialog}
                    open={this.state.dialog}
                    onClick={this.goBack} />

                <HeaderComponent />

                <FormComponent
                    action={this.addData}
                    action2={this.goBack}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(CadastralTechnicianTerminateForm));