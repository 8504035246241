import React from 'react'

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2),
        overflow: "auto",
        maxHeight: 500
    },
    table: {
        borderBottom: "none"
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section2PopoverComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            anchor: null
        }
    }

    toggleHandler = e => {
        this.setState({
            open: !this.state.open,
            anchor: e.currentTarget
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.toggleHandler}
                >
                    Reference
                </Button>

                <Popover
                    open={this.state.open}
                    onClose={this.toggleHandler}
                    anchorEl={this.state.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.padding}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Charge for each lot as per rates shown below:</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Table>
                        <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center">Size of Lot</TableCell>
                                        <TableCell align="center">(a) <br />Residential <br />
                                        etc. <br />RM
                                        </TableCell>
                                        <TableCell align="center">
                                            (b) <br />Commercial <br />
                                        etc. <br />RM
                                        </TableCell>
                                        <TableCell align="center">
                                            (c) <br />Industrial <br />
                                        etc. <br />RM
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">a</TableCell>
                                        <TableCell>Lot of upto 200 sq. metres</TableCell>
                                        <TableCell align="center">525.00</TableCell>
                                        <TableCell align="center">675.00</TableCell>
                                        <TableCell align="center">600.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">b</TableCell>
                                        <TableCell>For each additional 100 sq. metres or part thereof upto 500 sq. metres</TableCell>
                                        <TableCell align="center">37.50</TableCell>
                                        <TableCell align="center">37.50</TableCell>
                                        <TableCell align="center">37.50</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">c</TableCell>
                                        <TableCell>500 sq. metres</TableCell>
                                        <TableCell align="center">637.50</TableCell>
                                        <TableCell align="center">787.50</TableCell>
                                        <TableCell align="center">712.50</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">d</TableCell>
                                        <TableCell>For each additional 100 sq. metres or part thereof upto 1,000 sq. metres</TableCell>
                                        <TableCell align="center">22.50</TableCell>
                                        <TableCell align="center">22.50</TableCell>
                                        <TableCell align="center">22.50</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">e</TableCell>
                                        <TableCell>1,000 sq. metres</TableCell>
                                        <TableCell align="center">750.00</TableCell>
                                        <TableCell align="center">900.00</TableCell>
                                        <TableCell align="center">825.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">f</TableCell>
                                        <TableCell>For each additional 100 sq. metres or part thereof upto 2,000 sq. metres</TableCell>
                                        <TableCell align="center">15.00</TableCell>
                                        <TableCell align="center">15.00</TableCell>
                                        <TableCell align="center">15.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">g</TableCell>
                                        <TableCell>2,000 sq. metres</TableCell>
                                        <TableCell align="center">900.00</TableCell>
                                        <TableCell align="center">1,050.00</TableCell>
                                        <TableCell align="center">975.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">h</TableCell>
                                        <TableCell>For each additional 100 sq. metres or part thereof upto 5,000 sq.metres</TableCell>
                                        <TableCell align="center">12.00</TableCell>
                                        <TableCell align="center">12.00</TableCell>
                                        <TableCell align="center">12.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">i</TableCell>
                                        <TableCell>5,000 sq. metres</TableCell>
                                        <TableCell align="center">1,260.00</TableCell>
                                        <TableCell align="center">1,410.00</TableCell>
                                        <TableCell align="center">1,335.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">j</TableCell>
                                        <TableCell>For each additional 100 sq. metres or part thereof upto 10,000 sq. metres (1 hectare)</TableCell>
                                        <TableCell align="center">9.00</TableCell>
                                        <TableCell align="center">9.00</TableCell>
                                        <TableCell align="center">9.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">k</TableCell>
                                        <TableCell>1 hectare</TableCell>
                                        <TableCell align="center">1,710.00</TableCell>
                                        <TableCell align="center">1,860.00</TableCell>
                                        <TableCell align="center">1,785.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">l</TableCell>
                                        <TableCell>For each additional 1,000 sq. metres or part thereof upto 10 hectares</TableCell>
                                        <TableCell align="center">75.00</TableCell>
                                        <TableCell align="center">105.00</TableCell>
                                        <TableCell align="center">90.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">m</TableCell>
                                        <TableCell>10 hectares</TableCell>
                                        <TableCell align="center">8,460.00</TableCell>
                                        <TableCell align="center">11,310.00</TableCell>
                                        <TableCell align="center">9,885.00</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">n</TableCell>
                                        <TableCell>For each additional hectare or part thereof above 10 hectares</TableCell>
                                        <TableCell align="center">150.00</TableCell>
                                        <TableCell align="center">165.00</TableCell>
                                        <TableCell align="center">180.00</TableCell>
                                    </TableRow>
                                </TableBody>
                        </Table>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2PopoverComponent);