import React from 'react';

import TextField from '@material-ui/core/TextField';

class GenericTextInput extends React.Component {
    render() {
        return (
            <TextField
            margin="normal"
            label={this.props.label}
            multiline
            fullWidth
            inputProps={this.props.inputProps}
            inputRef={this.props.inputRef}
            />
        )
    }
}

export default GenericTextInput;