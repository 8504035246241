import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class BreakdownComponent2 extends React.Component {
    render() {
        const { classes } = this.props
        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Breakdown for Current Selected Claim Percentage - 
                        <b> {sessionStorage.getItem('claim_percent')} %</b>
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {this.props.data.length === 0 ?
                        null

                        :

                        this.props.data.map(x => (
                            <Grid container
                                direction="row"
                                spacing={3}
                                className={classes.padding}
                                key={x.total_deposit}>
                                <Grid item xs={6} sm={3}>
                                    Available Balance:
                        </Grid>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.available_bal.toFixed(2)}</b>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                {/* <Grid item xs={6} sm={3}>
                                    Actual Survey Fee:
                                </Grid>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}> */}

                                    {/* <b style={{ color: 'green' }}>
                                        RM {x.current_claim.toFixed(2)}
                                        </b> */}

                                    {/* RM <b>{x.actual_survey_fee_current.toFixed(2)}</b>
                                </Grid> */}

                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                {sessionStorage.getItem('claim_percent')} % Survey Fee:
                            </Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.new_survey_fee.toFixed(2)}</b>
                                </Grid>
                                <Grid item xs={6} sm={3}></Grid>

                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                    LJS 5% Admin Charge:
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.curr_admin_charge.toFixed(2)}</b>
                                </Grid>
                                <Grid item xs={6} sm={3}></Grid>

                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                    Disbursement:
                            </Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.new_disbursement.toFixed(2)}</b>
                                </Grid>
                                <Grid item xs={6} sm={3}></Grid>

                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                    Prof. Fees / SST:
                            </Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.new_sst.toFixed(2)}</b>
                                </Grid>
                                <Grid item xs={6} sm={3}></Grid>

                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                    Excess Fee:
                            </Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.new_excess_fee.toFixed(2)}</b>
                                </Grid>
                                <Grid item xs={6} sm={3}></Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={6} sm={3}>
                                    Remaining Balance:
                            </Grid>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}>
                                    RM <b>{x.remaining_bal.toFixed(2)}</b>
                                </Grid>
                            </Grid>
                        ))
                    }
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(BreakdownComponent2);