import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
// import Section1Component from './Component/Section1Component';
// import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';
import Section6Component from './Component/Section6Component';
// import Section7Component from './Component/Section7Component';

import Section1ComponentNEW from './Component/Section1ComponentNEW';
import Section2ComponentNEW from './Component/Section2ComponentNEW';
import Section3ComponentNEW from './Component/Section3ComponentNEW';
import Section4ComponentNEW from './Component/Section4ComponentNEW';
import Section7ComponentNEW from './Component/Section7ComponentNEW';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class SurveyorHome extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_data: [],
            section2_data: [],
            section3_data: [],
            section4_data: [],
            section5_data: [],
            section6_data: [],

            section1_new_data: [],
            section2_new_data: [],
            section3_new_data: [],
            section4_new_data: [],
            section7_new_data: [],

            backdrop: false,

            visible: false,
        }
    }

    componentDidMount() {
        //Check if user is a ls staff
        if (localStorage.getItem('perm') === "2") {
            this.props.history.push({ pathname: '/' })
        }
        else {
            this.setState({ backdrop: true })
            this.fetchData()
        }
    }

    fetchData = () => {
        axios.get(Config() + '/landing/surveyor/get',
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section2_data: response.data[2],
                        section3_data: response.data[3],
                        section4_data: response.data[4],
                        section5_data: response.data[5],
                        section6_data: response.data[6],
                        section1_new_data: response.data[7],
                        section2_new_data: response.data[8],
                        section3_new_data: response.data[9],
                        section4_new_data: response.data[10],
                        section7_new_data: response.data[11],

                        backdrop: false,
                        visible: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false,
                    visible: true
                })
            })
    }

    goSurveyJobClaimList = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/LSCadastralSurveyJobPendingClaimDashboardList'
            })
        }
    //     else if (route === "Engineering") {
    //         this.props.history.push({
    //             pathname: '/ui/LSEngineeringSurveyJobPendingDashboardList'
    //         })
    //     }
    //     else if (route === "Privatised") {
    //         this.props.history.push({
    //             pathname: '/ui/LSPrivatisedSurveyJobPendingDashboardList'
    //         })
    //     }
    }

    goSurveyJobList = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/LSCadastralSurveyJobPendingDashboardList'
            })
        }
        else if (route === "Engineering") {
            this.props.history.push({
                pathname: '/ui/LSEngineeringSurveyJobPendingDashboardList'
            })
        }
    }

    goTechnicianList = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/LSCadastralTechnicianPendingDashboardList'
            })
        }
        else if (route === "Engineering") {
            this.props.history.push({
                pathname: '/ui/LSEngineeringTechnicianPendingDashboardList'
            })
        }
    }

    goArticledGraduateList = () => {
        this.props.history.push({
            pathname: '/ui/LSArticledGraduatePendingDashboardList'
        })
    }

    goCPDList = () => {
        this.props.history.push({
            pathname: '/ui/LSCPDPendingDashboardList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goCadastralApplicationSummary = (form_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('form_id', form_id)
        this.props.history.push({
            pathname: '/ui/CadastralApplicationSummary'
        })
    }

    goEngineeringApplicationSummary = (form_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('form_id', form_id)
        this.props.history.push({
            pathname: '/ui/EngineeringApplicationSummary'
        })
    }

    goCadastralTechnicianSummary = (technician_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('technician_id_view', technician_id)
        this.props.history.push({
            pathname: '/ui/CadastralTechnicianView'
        })
    }

    goEngineeringTechnicianSummary = (technician_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('technician_id_view', technician_id)
        this.props.history.push({
            pathname: '/ui/EngineeringTechnicianView'
        })
    }

    goArticledGraduateSummary = (articledgraduate_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('articledgraduate_id_view', articledgraduate_id)
        this.props.history.push({
            pathname: '/ui/ArticledGraduateView'
        })
    }

    goCPDSummary = (cpd_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('cpd_id_view', cpd_id)
        this.props.history.push({
            pathname: '/ui/CPDView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <AlertComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg} />

                <HeaderComponent
                    visible={this.state.visible} />

                <Section1ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section1_new_data}
                    action={this.goSurveyJobList} />

                <Section7ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section7_new_data}
                    action={this.goSurveyJobClaimList} />

                <Section2ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section2_new_data}
                    action={this.goTechnicianList} />

                <Section3ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section3_new_data}
                    action={this.goArticledGraduateList} />

                <Section4ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section4_new_data}
                    action={this.goCPDList} />


                {/* Cadastral Survey Job Application */}
                {/* <Section1Component
                    visible={this.state.visible}
                    data={this.state.section1_data}
                    action={this.goCadastralApplicationSummary} /> */}

                {/* Engineering Survey Job Application */}
                {/* <Section2Component
                    visible={this.state.visible}
                    data={this.state.section2_data}
                    action={this.goEngineeringApplicationSummary} /> */}

                {/* Cadastral Survey Technician Application */}
                <Section3Component
                    visible={this.state.visible}
                    data={this.state.section3_data}
                    action={this.goCadastralTechnicianSummary} />

                {/* Engineering Survey Technician Application */}
                <Section4Component
                    visible={this.state.visible}
                    data={this.state.section4_data}
                    action={this.goEngineeringTechnicianSummary} />

                {/* Articled Graduate Application */}
                <Section5Component
                    visible={this.state.visible}
                    data={this.state.section5_data}
                    action={this.goArticledGraduateSummary} />

                {/* CPD Application */}
                <Section6Component
                    visible={this.state.visible}
                    data={this.state.section6_data}
                    action={this.goCPDSummary} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(SurveyorHome));