import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

class GenericLoadTextField extends React.Component {
    render() {
        return (
            <TextField
                variant="filled"
                margin="normal"
                fullWidth
                disabled
                label={this.props.label}
                name={this.props.name}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        RM
                        </InputAdornment>,
                    endAdornment: <InputAdornment position="end">
                        <CircularProgress size={30}
                            variant="indeterminate" />
                    </InputAdornment>
                }}
                value={this.props.value}
            />
        )
    }
}

export default GenericLoadTextField;