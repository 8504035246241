import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class GenericTextInput extends React.Component {
    render() {
        return (
            <TextField
            margin="normal"
            label="Comment"
            multiline
            fullWidth
            inputProps={{ maxLength: 100 }}
            inputRef={this.props.inputRef}
            />
        )
    }
}

class FirstCheckerDialogComponent extends React.Component {
    render() {
        return (
            <Dialog
            onClose={this.props.onClose}
            open={this.props.open}>

                <DialogTitle>
                    First Checker Comment
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type in the comments for the selected engineering survey job claim application as First Checker:
                    </DialogContentText>
                    <GenericTextInput
                    inputRef={this.props.inputRef}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={this.props.onClick}
                    className={this.props.className}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class SecondCheckerDialogComponent extends React.Component {
    render() {
        return (
            <Dialog
            onClose={this.props.onClose}
            open={this.props.open}>

                <DialogTitle>
                    Second Checker Comment
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type in the comments for the selected engineering survey job claim application as Second Checker:
                    </DialogContentText>
                    <GenericTextInput
                    inputRef={this.props.inputRef}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={this.props.onClick}
                    className={this.props.className}>
                        Approve
                    </Button>

                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.props.onClick2}
                    className={this.props.className}>
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class DialogComponent extends React.Component {
    render() {
        const { classes } = this.props
        return (
            <div>
                <FirstCheckerDialogComponent
                onClose={this.props.onClose1}
                open={this.props.open1}
                inputRef={node => (this.input_comment = node)}
                onClick={() => this.props.action1(this.input_comment.value)}
                className={classes.button}
                />

                <SecondCheckerDialogComponent
                onClose={this.props.onClose2}
                open={this.props.open2}
                inputRef={node => (this.input_comment = node)}
                onClick={() => this.props.action2(this.input_comment.value)}
                onClick2={() => this.props.action3(this.input_comment.value)}
                className={classes.button}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);