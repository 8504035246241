import React from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../../Config';
import { ResponseStatusCheck } from '../../../../../Response/ResponseStatus';

import StepperComponent from '../../CadastralTableList/Component/StepperComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class Table10 extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        if (sessionStorage.getItem('form_id') === '' || sessionStorage.getItem('form_id') === undefined ||
            sessionStorage.getItem('form_id') === null) {
            this.props.history.push({
                pathname: '/ui/SurveyorHome'
            })
        }
        else {
            this.setState({ backdrop: true })
            this.fetchData()
        }
    }

    fetchData = () => {
        axios.put(Config() + '/ls_surveyjob/cadastral/application/sftable_10/get',
            {
                'form_id': sessionStorage.getItem('form_id')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        console.log("New calculator.")
                        this.setState({ backdrop: false })
                    }
                    else {
                        let section1_input = response.data[1][0]['section1_input']
                        let section2_input_1 = response.data[1][0]['section2_input_1']
                        let section2_input_2 = response.data[1][0]['section2_input_2']

                        let section1_amount = response.data[1][0]['section1_amount'].toFixed(2)
                        let section2_amount = response.data[1][0]['section2_amount'].toFixed(2)

                        this.setState({ backdrop: false })

                        this.ComponentUI.current.getData(
                            section1_input, section2_input_1, section2_input_2,
                            section1_amount, section2_amount
                        )
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    submitHandler = (
        section1_input, section2_input_1, section2_input_2,
        total_amount
    ) => {
        axios.post(Config() + '/ls_surveyjob/cadastral/application/sftable_10/add',
            {
                'form_id': sessionStorage.getItem('form_id'),
                'section1_input': section1_input,
                'section2_input_1': section2_input_1,
                'section2_input_2': section2_input_2,
                'total_amount': total_amount
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.props.history.push({
                        pathname: '/ui/CadastralTableSummary'
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTableList'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <StepperComponent />
                <br />
                <HeaderComponent />
                <br />
                <FormComponent
                    action={this.submitHandler}
                    action2={this.goBack}
                    ref={this.ComponentUI} />
            </div>

        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Table10));