import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import ButtonComponent from './Component/ButtonComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class PrivatisedArchiveJobView extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_data: [],
            section1_file_data: [],

            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_surveyjob/privatised/view/get/form',
            {
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section1_file_data: response.data[2],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    submitHandler = () => {
        this.setState({ backdrop: true })
        axios.post(Config() + '/ls_surveyjob/privatised/view/add',
        {
            'form_id': sessionStorage.getItem('form_id_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({
                backdrop: false
            })
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/PrivatisedJobList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <AlertComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                />

                <Section1Component
                data={this.state.section1_data}
                file={this.state.section1_file_data}
                />

                <ButtonComponent
                    stage={this.state.section1_data}
                    action={this.goBack}
                    action2={this.submitHandler}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedArchiveJobView));