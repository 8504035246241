import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios'

import { Config } from '../Config';
import { ResponseStatusCheck } from '../Response/ResponseStatus';

import NavigationFrame from '../Staff/Shared/NavigationFrame';

const styles = theme => ({
    toolbar: theme.mixins.toolbar
})

class PrivateRoute extends React.Component {
    getToken = () => {
        return localStorage.getItem('token')
    }

    logoutHandler = () => {
        axios.get(Config() + '/authentication/staff/logout',
        {
            withCredentials: true
        })
        .then(response => {
            console.log(response)
            sessionStorage.clear()
        })
        .catch(error => {
            if (!error.response) {
                console.log("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    console.log(ResponseStatusCheck(error))
                }
                else {
                    console.log("Something went very wrong!")
                }
            }
        })
        this.props.history.push({
            pathname: '/'
        })
    }
    
    render() {
        const { classes } = this.props
        const Component = this.props.component
        const isAuthenticated = this.getToken();

        return isAuthenticated ? (
            <React.Fragment>
            <NavigationFrame action={this.props.action}
            logout={this.logoutHandler} />
            <div className={classes.toolbar} />
            <Component />

            </React.Fragment>
        ) :
        (
            <Redirect to={{ pathname: '/'}} />
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivateRoute));