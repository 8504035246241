import React from 'react';

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';
import Review1Component from './Component/Review1Component';
import ButtonComponent from './Component/ButtonComponent';
import DialogComponent from './Component/DialogComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class ArticledGraduateReview extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_data: [],
            section2_data: [],
            section3_data: [],
            section4_data: [],
            section5_data: [],

            backdrop: false,

            dialog_1: false
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.put(Config() + '/articledgraduate/view/get',
            {
                'articledgraduate_id': sessionStorage.getItem('articledgraduate_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section2_data: response.data[2],
                        section3_data: response.data[3],
                        section4_data: response.data[4],
                        section5_data: response.data[5],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    openFirstCheckerDialog = () => {
        this.setState({ dialog_1: true })
    }

    closeFirstCheckerDialog = () => {
        this.setState({ dialog_1: false })
    }

    approveFirstChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/articledgraduate/review/update/checker1/approve',
            {
                'articledgraduate_id': sessionStorage.getItem('articledgraduate_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectFirstChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/articledgraduate/review/update/checker1/reject',
            {
                'articledgraduate_id': sessionStorage.getItem('articledgraduate_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    updateHandler = (
        graduate_name, ic_no, home_address, office_address,
        qualification, prac_exp, remark
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/articledgraduate/update/edit',
            {
                'articledgraduate_id': sessionStorage.getItem('articledgraduate_id_review'),
                'graduate_name': graduate_name,
                'ic_no': ic_no,
                'home_address': home_address,
                'office_address': office_address,
                'qualification': qualification,
                'prac_exp': prac_exp,
                'remark': remark
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    updatePhotoHandler = (file_object, file_name, file_size) => {
        if (
            Number(file_size.reduce((prev, next) =>
                (Number(prev) + Number(next)), 0)) >= 50000000) {
            this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
        }

        else {
            const data = new FormData()

            for (let i = 0; i < file_object.length; i++) {
                data.append('file_object', file_object[i])
                data.append('file_name', file_name[i])
            }

            data.append('articledgraduate_id', sessionStorage.getItem('articledgraduate_id_review'))

            axios.post(Config() + '/articledgraduate/update/edit/photo',
                data,
                {
                    withCredentials: true
                })
                .then(response => {
                    if (response.data['result'] === "Success") {
                        this.init()
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        this.errorHandler("Code issue probably.")
                    }
                    else {
                        if (ResponseStatusCheck(error)) {
                            //If Session Expired
                            if (ResponseStatusCheck(error) === 401) {
                                console.log("Display some window here.")
                            }
                            else {
                                this.errorHandler(ResponseStatusCheck(error))
                            }
                        }
                        else {
                            this.errorHandler("Something went very wrong!")
                        }
                    }
                })
        }
    }

    updateRelevantCertHandler = (file_object, file_name, file_size) => {
        if (
            Number(file_size.reduce((prev, next) =>
                (Number(prev) + Number(next)), 0)) >= 50000000) {
            this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
        }

        else {
            this.setState({ backdrop: true })
            const data = new FormData()

            for (let i = 0; i < file_object.length; i++) {
                data.append('file_object', file_object[i])
                data.append('file_name', file_name[i])
            }

            data.append('articledgraduate_id', sessionStorage.getItem('articledgraduate_id_review'))

            axios.post(Config() + '/articledgraduate/update/edit/relevantcert',
                data,
                {
                    withCredentials: true
                })
                .then(response => {
                    if (response.data['result'] === "Success") {
                        this.init()
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        this.errorHandler("Code issue probably.")
                    }
                    else {
                        if (ResponseStatusCheck(error)) {
                            //If Session Expired
                            if (ResponseStatusCheck(error) === 401) {
                                console.log("Display some window here.")
                            }
                            else {
                                this.errorHandler(ResponseStatusCheck(error))
                            }
                        }
                        else {
                            this.errorHandler("Something went very wrong!")
                        }
                    }
                    this.setState({ backdrop: false })
                })
        }
    }

    updateFormIndentureHandler = (file_object, file_name, file_size) => {
        if (
            Number(file_size.reduce((prev, next) =>
                (Number(prev) + Number(next)), 0)) >= 50000000) {
            this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
        }

        else {
            this.setState({ backdrop: true })
            const data = new FormData()

            for (let i = 0; i < file_object.length; i++) {
                data.append('file_object', file_object[i])
                data.append('file_name', file_name[i])
            }

            data.append('articledgraduate_id', sessionStorage.getItem('articledgraduate_id_review'))

            axios.post(Config() + '/articledgraduate/update/edit/formindenture',
                data,
                {
                    withCredentials: true
                })
                .then(response => {
                    if (response.data['result'] === "Success") {
                        this.init()
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        this.errorHandler("Code issue probably.")
                    }
                    else {
                        if (ResponseStatusCheck(error)) {
                            //If Session Expired
                            if (ResponseStatusCheck(error) === 401) {
                                console.log("Display some window here.")
                            }
                            else {
                                this.errorHandler(ResponseStatusCheck(error))
                            }
                        }
                        else {
                            this.errorHandler("Something went very wrong!")
                        }
                    }
                    this.setState({ backdrop: false })
                })
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/StaffArticledGraduateList'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    open={this.state.dialog_1}
                    onClose={this.closeFirstCheckerDialog}
                    action1={this.approveFirstChecker}
                    action2={this.rejectFirstChecker}
                />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Typography variant="h4">
                        Articled Graduate Information
                    </Typography>

                    <br />

                    <Section1Component
                        data={this.state.section1_data}
                        image={this.state.section2_data}
                        loading={this.state.loading}
                        action={this.updateHandler} />

                    <Section2Component
                        data={this.state.section2_data}
                        action={this.updatePhotoHandler} />

                    <Section3Component
                        data={this.state.section3_data}
                        action={this.updateRelevantCertHandler} />

                    <Section4Component
                        data={this.state.section4_data}
                        action={this.updateFormIndentureHandler} />

                    <Review1Component
                        data={this.state.section1_data} />
                    <br />

                    {/* If the status is Inactive then show this section, 
                    else display null 
                    */}

                    {!this.state.section1_data.length ?
                        null
                        :
                        this.state.section1_data[0]['status'] === "Inactive" ?
                            <Section5Component
                                data={this.state.section5_data}
                                data2={this.state.section1_data} />
                            :
                            null
                    }
                </Paper>

                <ButtonComponent
                    stage_data={this.state.section1_data}
                    action={this.goBack}
                    action1={this.openFirstCheckerDialog} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(ArticledGraduateReview));