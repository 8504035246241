import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericNumberTextInput from '../../Util/GenericNumberTextInput';
import GenericIdleTextField from '../../Util/GenericIdleTextField';

import Section3PopoverComponent from './Section3PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section3Component extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 3: Charge for positioning of each point
                        </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>

                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Please fill in the charge for positioning for each point.
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section3PopoverComponent />
                        </Grid>
                    </Grid>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}>
                        <Grid item xs={12} sm={4}>
                            <GenericNumberTextInput
                                label="Point/s"
                                name="section3_input"
                                value={this.props.value}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>

                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericIdleTextField
                                label="Amount"
                                name="section3_amount"
                                value={this.props.valueTotal}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section3Component);