import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class TerminateIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Terminate
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class SubmitIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

// class GenericIdleButton extends React.Component {
//     render() {
//         return (
//             <Button
//                 variant="contained"
//                 color="primary"
//                 className={this.props.className}
//                 onClick={this.props.onClick}>
//                 {this.props.label}
//             </Button>
//         )
//     }
// }

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {!this.props.stage.length ?
                    <Box pt={4}
                        className={classes.box}>

                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} />
                    </Box>

                    :

                    //Only shows the back button if it is LS Staff
                    localStorage.getItem('perm') === "2" ?
                    <Box pt={4}
                        className={classes.box}>
                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} />
                    </Box>

                    :

                    //Show terminate button if only the status is Active
                    this.props.stage[0]['status'] === "Active" ?
                        <Box pt={4}
                            className={classes.box}>

                            <TerminateIdleButton
                                className={classes.button}
                                onClick={this.props.action2}
                            />
                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                        :

                        //Show submit button if only the status is Draft
                        localStorage.getItem('perm') !== "2" &&
                        this.props.stage[0]['status'] === "Draft" ?
                        <Box pt={4}
                            className={classes.box}>

                            <SubmitIdleButton
                                className={classes.button}
                                onClick={this.props.action3}
                            />
                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                        :

                        <Box pt={4}
                            className={classes.box}>
                            <BackIdleButton
                                className={classes.button}
                                onClick={this.props.action} />
                        </Box>

                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);