import React from 'react'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

import image from '../../../Resources/Image1.png';
import LandingImage from '../../../Resources/eConnectLoginLJS6a.png';

const styles = theme => ({
    root: {
        height: '100vh'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    controlLabel: {
        display: 'flex',
        textAlign: 'left', //force control label to align left
    },
    image: {
        // backgroundImage: `url(/api/static/LJS%20Login%20Image.PNG)`,
        // backgroundImage: `url(/api/static/LJS%20Login%20Image.png)`,
        // backgroundPosition: 'center', 
        // backgroundImage: `url(/api/static/eConnect%20LoginLJS6a.png)`,
        backgroundImage: `url(${LandingImage})`,
        backgroundPosition: 'bottom',
        backgroundSize: '100% 100%', 
    },
})

class UsernameTextInput extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Username"
                autoFocus
                name="input_username"
                onChange={this.props.onChange}
            />
        )
    }
}

class PasswordTextInput extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                name="input_password"
                onChange={this.props.onChange}
            />
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary"
                fullWidth type="submit"
                onClick={this.props.onClick}
                className={this.props.className}>
                Sign In as Licensed Surveyor
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary"
                fullWidth type="submit"
                disabled
            >
                Signing In... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class ForgotLinkComponent extends React.Component {
    render() {
        return (
            <Grid container
                style={{ textAlign: 'right' }} //force align to right 
            >
                <Grid item xs>
                    <Link href='/ui/SurveyorPasswordReset' variant="body2">
                        Forgot Password?
                    </Link>
                </Grid>
            </Grid>
        )
    }
}

class GridComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            input_username: '',
            input_password: '',

            error_username: false,
            error_password: false,

            loading: false,
            alert: false
        }
    }
    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value,

            //test performance only
            error_username: false,
            error_password: false,
            alert: false,
            alert_msg: ''
        })
    }

    submitHandler = event => {
        //prevent the page from refreshing during submit action
        event.preventDefault()
        event.stopPropagation()

        if (this.state.input_username === '' || this.state.input_password === '') {
            this.setState({
                error_username: this.state.input_username ? false : true,
                error_password: this.state.input_password ? false : true
            })
        }
        else {
            this.setState({
                error_username: false,
                error_password: false,
                loading: true
            })
            this.props.action(this.state.input_username, this.state.input_password)
        }
    }

    errorHandler = (msg) => {
        this.setState({
            loading: false,
            alert: true,
            alert_msg: msg
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={8}
                className={classes.image} />
                <Grid item xs={12} sm={8} md={4} component={Paper}
                    elevation={6} square>
                    <div className={classes.paper}>
                        <img src={image} alt="Sabah Surveyors Board"
                            style={{ width: 160 }}></img>
                        <br />
                        <Typography component="h1" variant="h2">
                            e-Connect LJS
                        </Typography>
                        {/* <Typography component="h2" variant="h5">
                            Sabah Surveyors Board
                        </Typography> */}
                        <br />
                        <br />
                        <br />
                        <form className={classes.form} noValidate
                            onSubmit={this.submitHandler}>
                            <UsernameTextInput
                                error={this.state.error_username}
                                helperText={this.state.error_username ?
                                    "Username cannot be empty." :
                                    null}
                                onChange={this.onChangeHandler} />
                            <PasswordTextInput
                                error={this.state.error_password}
                                helperText={this.state.error_password ?
                                    "Password cannot be empty." :
                                    null}
                                onChange={this.onChangeHandler} />
                            <br />
                            <ForgotLinkComponent />
                            <br />

                            {this.state.loading ?
                                <ButtonLoadComponent /> :
                                <ButtonIdleComponent onClick={this.submitHandler} />
                            }

                            {this.state.alert ?
                                <Alert severity="error">
                                    {this.state.alert_msg}
                            </Alert>
                                :
                                null}

                            {/* <FormControlLabel
                                control={<Checkbox value="input_remember" color="primary" />}
                                label="Remember Me?"
                                className={classes.controlLabel}
                            /> */}
                        </form>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(GridComponent);