import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    }
})

class Section6Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 6 - Claims
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.props.action}>
                        View Claim Details
                </Button>
                </AccordionDetails>

            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section6Component);