import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1ComponentNEW from './Component/Section1ComponentNEW';
import Section2ComponentNEW from './Component/Section2ComponentNEW';
// import TestComponent from './Component/TestComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';
import Section6Component from './Component/Section6Component';
import Section7Component from './Component/Section7Component';
import Section8Component from './Component/Section8Component';
import Section9Component from './Component/Section9Component';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class StaffHome extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_new_data: [],
            section2_new_data: [],
            section1_data: [],
            section2_data: [],
            section3_data: [],
            section4_data: [],
            section5_data: [],
            section6_data: [],
            section7_data: [],
            section8_data: [],
            section9_data: [],

            backdrop: false,
            visible: false
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    fetchData = () => {
        axios.get(Config() + '/landing/staff/get',
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section2_data: response.data[2],
                        section3_data: response.data[3],
                        section4_data: response.data[4],
                        section5_data: response.data[5],
                        section6_data: response.data[6],
                        section7_data: response.data[7],
                        section8_data: response.data[8],
                        section9_data: response.data[9],
                        section1_new_data: response.data[10],
                        section2_new_data: response.data[11],

                        backdrop: false,
                        visible: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false,
                    visible: true
                })
            })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goSurveyJobList_New = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/StaffCadastralNewDashboardList'
            })
        }
        else if (route === "Engineering") {
            this.props.history.push({
                pathname: '/ui/StaffEngineeringNewDashboardList'
            })
        }
        else if (route === "Privatised") {
            this.props.history.push({
                pathname: '/ui/StaffPrivatisedNewDashboardList'
            })
        }
    }

    goSurveyJobList_Registered = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/StaffCadastralRegisteredDashboardList'
            })
        }
        else if (route === "Engineering") {
            this.props.history.push({
                pathname: '/ui/StaffEngineeringRegisteredDashboardList'
            })
        }
        else if (route === "Privatised") {
            this.props.history.push({
                pathname: '/ui/StaffPrivatisedRegisteredDashboardList'
            })
        }
    }

    goCadastralReview = (form_id, curr_stage) => {
        sessionStorage.setItem('form_id_review', form_id)
        sessionStorage.setItem('curr_stage', curr_stage)
        this.props.history.push({
            pathname: '/ui/CadastralSurveyJobReview'
        })
    }

    goEngineeringReview = (form_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('form_id_review', form_id)
        this.props.history.push({
            pathname: '/ui/EngineeringSurveyJobReview'
        })
    }

    goCadastralTechnicianReview = (technician_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('technician_id_review', technician_id)
        this.props.history.push({
            pathname: '/ui/CadastralTechnicianReview'
        })
    }

    goEngineeringTechnicianReview = (technician_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('technician_id_review', technician_id)
        this.props.history.push({
            pathname: '/ui/EngineeringTechnicianReview'
        })
    }

    goArticledGraduateReview = (articledgraduate_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('articledgraduate_id_review', articledgraduate_id)
        this.props.history.push({
            pathname: '/ui/ArticledGraduateReview'
        })
    }

    goCPDReview = (cpd_id) => {
        sessionStorage.clear()
        sessionStorage.setItem('cpd_id_review', cpd_id)
        this.props.history.push({
            pathname: '/ui/CPDReview'
        })
    }

    clickHandler = () => {
        axios.get(Config() + '/authentication/staff/test',
            {
                withCredentials: true
            })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                if (!error.response) {
                    console.log("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        console.log(ResponseStatusCheck(error))
                    }

                    else {
                        console.log("Something went very wrong!")
                    }
                }
            })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <AlertComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg} />

                <HeaderComponent
                    visible={this.state.visible} />

                <Section1ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section1_new_data}
                    action={this.goSurveyJobList_New} />

                <Section2ComponentNEW
                    visible={this.state.visible}
                    data={this.state.section2_new_data}
                    action={this.goSurveyJobList_Registered} 
                    />

                {/* <TestComponent /> */}

                {/* Cadastral Survey Job Application */}
                <Section1Component
                    visible={this.state.visible}
                    data={this.state.section1_data}
                    action={this.goCadastralReview} />

                {/* Engineering Survey Job Application */}
                <Section2Component
                    visible={this.state.visible}
                    data={this.state.section2_data}
                    action={this.goEngineeringReview} />

                {/* Cadastral Survey Technician Application */}
                <Section3Component
                    visible={this.state.visible}
                    data={this.state.section3_data}
                    action={this.goCadastralTechnicianReview} />

                {/* Engineering Survey Technician Application */}
                <Section4Component
                    visible={this.state.visible}
                    data={this.state.section4_data}
                    action={this.goEngineeringTechnicianReview} />

                {/* Articled Graduate Application */}
                <Section5Component
                    visible={this.state.visible}
                    data={this.state.section5_data}
                    action={this.goArticledGraduateReview} />

                {/* Cadastral Survey Technician Termination */}
                <Section6Component
                    visible={this.state.visible}
                    data={this.state.section6_data}
                    action={this.goCadastralTechnicianReview} />

                {/* Engineering Survey Technician Termination */}
                <Section7Component
                    visible={this.state.visible}
                    data={this.state.section7_data}
                    action={this.goEngineeringTechnicianReview} />

                {/* Articled Graduate Termination */}
                <Section8Component
                    visible={this.state.visible}
                    data={this.state.section8_data}
                    action={this.goArticledGraduateReview} />

                {/* CPD Application */}
                <Section9Component
                    visible={this.state.visible}
                    data={this.state.section9_data}
                    action={this.goCPDReview} />

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(StaffHome));