import React from 'react';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    heading: {
        fontFamily: 'Arial'
    },
    title: {
        color: '#73C2FB'
    },
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Grid container
                direction="row"
                spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Collapse in={this.props.visible}>
                        <Grow in={this.props.visible}>
                            <Typography variant="h4"
                                className={classes.heading}>
                                Hello <b>{localStorage.getItem('user_name')} !</b>
                            </Typography>
                        </Grow>
                    </Collapse>
                    <br />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography
                    variant="h6"
                    className={classes.title}>
                        <b>Your Dashboard</b>
                    </Typography>
                    <br />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HeaderComponent);