import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class RadioGroupComponent extends React.Component {
    render() {
        return (
            <FormControl component="fieldset" className={this.props.className}>
                <FormLabel component="legend">Percentage</FormLabel>
                <RadioGroup value={this.props.percentage} onChange={this.props.onChange}>
                    <FormControlLabel value="65" control={<Radio />} label="65% (Post Jan 2017 jobs)" />
                    <FormControlLabel value="85" control={<Radio />} label="85% (Jobs between Year 2014 - Dec 2016)" />
                    <FormControlLabel value="100" control={<Radio />} label="100%" />
                </RadioGroup>
            </FormControl>
        )
    }
}

export default RadioGroupComponent;