import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import StepperComponent from './Component/StepperComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class SupportingDocumentForm extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        if (sessionStorage.getItem('form_id') === '' || sessionStorage.getItem('form_id') === undefined ||
            sessionStorage.getItem('form_id') === null) {
            this.props.history.push({
                pathname: '/ui/SurveyorHome'
            })
        }
    }

    submitHandler = (file_object, file_name) => {
        this.setState({ loading: true })

        const data = new FormData()

        for (var i = 0; i < file_object.length; i++) {
            data.append('file', file_object[i])
            data.append('filename', file_name[i])
        }

        data.append('form_id', sessionStorage.getItem('form_id'))
        axios.post(Config() + '/ls_surveyjob/engineering/application/supportingdoc/add',
            data,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.goNext()
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    goNext = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringApplicationSummary'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringTableSummary'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <StepperComponent />
                <br />
                <HeaderComponent />
                <FormComponent
                    loading={this.state.loading}
                    action={this.submitHandler}
                    action2={this.goBack} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(SupportingDocumentForm));