import React from 'react';

import Alert from '@material-ui/lab/Alert';

class AlertComponent extends React.Component {
    render() {
        return (
            <div>
                {this.props.alert ?
                <Alert severity="error">
                    {this.props.error_msg}
                </Alert>
                :
                null
                }
            </div>
        )
    }
}

export default AlertComponent;