import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import ButtonEdit from '../Util/ButtonEdit';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 150
    },
})

class GridViewUserinfoComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.ls_user_id}>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Username:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_user_name}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Password:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>*****</b>
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4}
                    className={classes.box}>

                    <ButtonEdit
                        className={classes.button}
                        onClick={this.props.action}
                    />
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(GridViewUserinfoComponent);