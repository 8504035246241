import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

import RadioGroupComponent from './RadioGroupComponent';

import RequiredTextInput from '../../../Util/RequiredTextInput';
import TelTextInput from '../../../Util/TelTextInput';
import GenericTextInput from '../../../Util/GenericTextInput';
import SelectTextInput from '../../../Util/SelectTextInput';
import ControlledTextInput from '../../../Util/ControlledTextInput';
import NextLoadButton from '../../../Util/NextLoadButton';
import NextIdleButton from '../../../Util/NextIdleButton';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
    input: {
        display: 'none',
    },
    radiogroup: {
        margin: 20
    }
})

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_district: '',
            input_contract_sum: '',

            percentage: '',

            file_object: [],
            file_name: [],
            file_size: [],

            shrink: true

        }
    }

    //Added initialization function on 15/10/2021
    initializeHandler = (data) => {
        console.log(data[0]['percentage'])
        this.input_reference.value = data[0]['ls_reference']
        this.input_project_name.value = data[0]['project_name']
        this.input_year.value = data[0]['year']
        this.input_package_no.value = data[0]['package_no']
        this.input_pt_no.value = data[0]['pt_no']
        this.input_rsp_no.value = data[0]['rsp_no']

        this.setState({
            input_district: data[0]['dist_code'],
            input_contract_sum: data[0]['contract_sum'],
            percentage: data[0]['percentage'].toString()
        })
    }

    onChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onChangeHandler_decimal = (event) => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ [event.target.name]: event.target.value })
        }
    }

    onChangeHandler_radio = (event) => {
        this.setState({
            percentage: event.target.value
        })
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object: [...prevState.file_object, file],
                file_name: [...prevState.file_name, file.name],
                file_size: [...prevState.file_size, file.size]
            }))
        })
    }

    removeHandler = (i) => {
        let file_object = [...this.state.file_object]
        let file_name = [...this.state.file_name]
        let file_size = [...this.state.file_size]
        file_object.splice(i, 1)
        file_name.splice(i, 1)
        file_size.splice(i, 1)
        this.setState({ file_object, file_name, file_size })
    }

    submitHandler = () => {
        this.setState({
            error_reference: !this.input_reference.value ? true : false,
            error_project_name: !this.input_project_name.value ? true : false,
            error_year: !this.input_year.value ? true : false,
            error_package_no: !this.input_package_no.value ? true : false,
            error_district: !this.state.input_district ? true : false,
            error_contract_sum: !this.state.input_contract_sum ? true : false,
            error_rsp_no: !this.input_rsp_no.value ? true : false,
        })

        this.props.action(
            this.input_reference.value,
            this.input_project_name.value,
            this.input_year.value,
            this.input_package_no.value,
            this.input_pt_no.value,
            this.state.input_district,
            this.state.input_contract_sum,
            this.input_rsp_no.value,
            this.state.percentage,
            this.state.file_object,
            this.state.file_name,
            this.state.file_size
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                <form noValidate>

                    {this.props.alert ?
                        <Alert severity="error">
                            {this.props.error_msg}
                        </Alert>
                        :
                        null
                    }
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_reference}
                                label="Licensed Surveyor Reference"
                                name="input_reference"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_reference = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_project_name}
                                label="Project Name"
                                name="input_project_name"
                                inputProps={{ maxLength: 300 }}
                                inputRef={node => (this.input_project_name = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TelTextInput
                                error={this.state.error_year}
                                label="Year"
                                name="input_year"
                                inputProps={{ maxLength: 4 }}
                                inputRef={node => (this.input_year = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <GenericTextInput
                                label="Package No."
                                name="input_package_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_package_no = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <GenericTextInput
                                label="PT No. (if any)"
                                name="input_pt_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_pt_no = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <SelectTextInput
                                error={this.state.error_district}
                                label="District"
                                name="input_district"
                                value={this.state.input_district}
                                onChange={this.onChangeHandler}
                                data={this.props.data}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <ControlledTextInput
                                error={this.state.error_contract_sum}
                                label="Contract Sum"
                                name="input_contract_sum"
                                value={this.state.input_contract_sum}
                                inputProps={{ maxLength: 10 }}
                                InputLabelProps={{ shrink: this.state.shrink }}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <RequiredTextInput
                                error={this.state.error_rsp_no}
                                label="RSP No."
                                name="input_rsp_no"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_rsp_no = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Divider />
                    <br />
                    <RadioGroupComponent className={classes.radiogroup}
                        percentage={this.state.percentage}
                        onChange={this.onChangeHandler_radio} />

                    <br />
                    <Divider />
                    <br />

                    <Typography variant="h6">Upload Documents</Typography>
                    <Typography variant="caption">**Please re-upload the files when editing the application.</Typography>
                    <Typography variant="caption">
                        File References:<br />
                        - Claim Details<br />
                        - JTU/SEDIA Voucher (if available)
                    </Typography>

                    <br />

                    <input
                        accept="image/*,application/pdf"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={this.uploadHandler}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span"
                            className={classes.button}>
                            Upload
                        </Button>
                    </label>

                    <Table>
                        <TableBody>
                            {this.state.file_name.length === 0 ?
                                <TableRow>
                                    <TableCell>No files uploaded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                this.state.file_name.map((x, i) => (
                                    <TableRow key={x}>
                                        <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                        <TableCell style={{ width: '50%' }}>
                                            <IconButton color="secondary"
                                                onClick={() => this.removeHandler(i)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <Divider />

                    {this.props.loading ?
                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextLoadButton className={classes.button} />
                        </Box>
                        :
                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextIdleButton className={classes.button}
                                onClick={this.submitHandler} />
                        </Box>
                    }
                </form>
            </Paper >
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);