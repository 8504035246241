import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericIdleTextField from '../../Util/GenericIdleTextField';

import Section5PopoverComponent from './Section5PopoverComponent';
import GenericNumberTextInput from '../../Util/GenericNumberTextInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section5Component extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 5: Charge for common user area
                        </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>

                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Please provide the total size in common user area.
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section5PopoverComponent />
                        </Grid>
                    </Grid>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}>
                        <Grid item xs={12} sm={6}>
                            <GenericNumberTextInput
                                label="Area (In Sq. M.)"
                                name="section5_input"
                                value={this.props.value}
                                inputProps={{ maxLength: 6 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>

                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericIdleTextField
                                label="Amount"
                                name="section5_amount"
                                value={this.props.valueTotal}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section5Component);