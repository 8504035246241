import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
})

class BackdropComponent extends React.Component {
    // constructor(props){
    //     super(props)
    //     this.state = {
    //         active: false
    //     }
    // }

    render() {
        const { classes } = this.props
        return (
            <Backdrop className={classes.backdrop} open={this.props.open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}

export default withStyles(styles)(BackdropComponent);