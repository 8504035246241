import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';
import DialogComponent from './Component/DialogComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class PrivatisedPaymentForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            amount: '',
            bank: [],

            backdrop: false,
            dialog: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_surveyjob/privatised/application/payment/initialize',
        {
            'form_id': sessionStorage.getItem('form_id_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    amount: response.data[1],
                    bank: response.data[2],

                    backdrop: false
                })
                if (response.data[1] === '') {
                    this.ComponentUI.current.errorHandler("Please contact LJS to fill up the total admin charge amount.")
                }
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
            this.setState({
                backdrop: false
            })
        })
    }

    addData = (
        payment_mode, bank, cheque, 
        file_object, file_name
    ) => {
        axios.post(Config() + '/ls_surveyjob/privatised/application/payment/add',
        {
            'payment_mode': payment_mode,
            'bank': bank,
            'cheque_no': cheque,
            'form_id': sessionStorage.getItem('form_id_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.addFile(file_object, file_name)
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    addFile = (file_object, file_name) => {
        const data = new FormData()

        for (var i=0; i < file_object.length; i++) {
            data.append('file', file_object[i])
            data.append('filename', file_name[i])
        }

        data.append('form_id', sessionStorage.getItem('form_id_view'))

        axios.post(Config() + '/ls_surveyjob/privatised/application/payment/add/file',
        data,
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.setState({ 
                    dialog: true
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    closeDialog = () => {
        this.setState({
            dialog: false
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/PrivatisedJobView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                onClose={this.closeDialog}
                open={this.state.dialog}
                onClick={this.goBack} />

                <HeaderComponent />

                <FormComponent
                amount={this.state.amount}
                bank={this.state.bank} 
                action={this.addData}
                action2={this.goBack}
                ref={this.ComponentUI}
                />

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedPaymentForm));