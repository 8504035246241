import React from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import SelectRequiredTextInput from '../Util/SelectRequiredTextInput';

const styles = theme => ({
    paper: {
        maxWidth: 500,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class FormComponent extends React.Component {
    
    onChangeHandler = event => {
        this.props.action(event.target.value)
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3} className={classes.paper}>
                    <SelectRequiredTextInput
                    label="Select a Licensed Surveyor"
                    name="input_ls_name"
                    value={this.props.value}
                    onChange={this.onChangeHandler}
                    data={this.props.data}
                    />

                    <Button 
                    variant="contained"
                    color="primary"
                    disabled={!this.props.value ? true : false}
                    className={classes.button}
                    onClick={() => this.props.action2(this.props.value)}
                    >
                        Modify Points
                    </Button>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);