import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

class AdornmentNumberTextInput extends React.Component {
    render() {
        return (
            <TextField
                variant="outlined"
                margin="normal"
                label={this.props.label}
                name={this.props.name}
                type="tel"
                value={this.props.value}
                inputProps={this.props.inputProps}
                onChange={this.props.onChange}
                InputProps={{
                    startAdornment: <InputAdornment position="start">RM</InputAdornment>
                }}
            />
        )
    }
}

export default AdornmentNumberTextInput;