export function ResponseStatusCheck(error) {
    if (error.response.status === 500) {
        return "The server has encountered an error when processing the request. Please contact the system administrator."
    }
    else if (error.response.status === 403) {
        return "Invalid Username or Password!"
    }
    else if (error.response.status === 401) {
        return 401
    }
}