import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import GenericTextInput from '../Util/GenericTextInput';
import RequiredSelectTextInput from '../Util/RequiredSelectTextInput';
import ButtonSubmitIdle from '../Util/ButtonSubmitIdle';
import ButtonSubmitLoad from '../Util/ButtonSubmitLoad';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    },
})

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            shrink: true,
            status_data: [
                {
                    'key': 'A',
                    'value': 'A',
                    'text': 'Active'
                },
                {
                    'key': 'I',
                    'value': 'I',
                    'text': 'Inactive'
                }
            ],
            payee_ind_data: [
                {
                    'key': 'S',
                    'value': 'LS',
                    'text': 'Licensed Surveyor'
                },
                {
                    'key': 'C',
                    'value': 'CO',
                    'text': 'Company'
                }
            ],
            swift_code_data: [],
            input_ls_status: '',
            input_ls_payee_ind: '',
            input_ls_bank: '',
        }
    }

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {
        this.setState({
            error_ls_no: !this.input_ls_no.value ? true : false,
            error_ls_name: !this.input_ls_name.value ? true : false,
            error_ls_status: !this.state.input_ls_status ? true : false
        })
        this.props.action(
            this.input_ls_no.value,
            this.input_ls_title.value,
            this.input_ls_name.value,
            this.input_ic_no.value,
            this.input_co_name.value,
            this.state.input_ls_status,
            this.input_email.value,
            this.input_phone.value,
            this.input_mobile.value,
            this.input_fax.value,
            this.input_addr1.value,
            this.input_addr2.value,
            this.input_addr3.value,
            this.input_addr4.value,
            this.input_paddr1.value,
            this.input_paddr2.value,
            this.input_paddr3.value,
            this.input_paddr4.value,
            this.input_lic_no.value,
            this.input_svc_tax.value,
            this.input_gl_acctno.value,
            this.input_ls_acct_no.value,
            this.state.input_ls_bank,
            this.input_ls_bank_branch.value,
            this.state.input_ls_payee_ind
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6">
                    Licensed Surveyor Details
                </Typography>
                <br />

                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null}

                <br />

                <form noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_ls_no}
                                label="LS No."
                                name="input_ls_no"
                                inputProps={{ maxLength: 3 }}
                                inputRef={node => (this.input_ls_no = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Title"
                                name="input_ls_title"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_ls_title = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_ls_name}
                                label="Full Name"
                                name="input_ls_name"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ls_name = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="IC No"
                                name="input_ic_no"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_ic_no = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Company Name"
                                name="input_co_name"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_co_name = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RequiredSelectTextInput
                                error={this.state.error_ls_status}
                                label="Status"
                                name="input_ls_status"
                                value={this.state.input_ls_status}
                                onChange={this.onChangeHandler}
                                data={this.state.status_data}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Email Address"
                                name="input_email"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_email = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Phone Number"
                                name="input_phone"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_phone = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Mobile Number"
                                name="input_mobile"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_mobile = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Fax"
                                name="input_fax"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_fax = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Address Line 1"
                                name="input_addr1"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr1 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Address Line 2"
                                name="input_addr2"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr2 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Address Line 3"
                                name="input_addr3"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr3 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Address Line 4"
                                name="input_addr4"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr4 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Postal Address Line 1"
                                name="input_paddr1"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr1 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Postal Address Line 2"
                                name="input_paddr2"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr2 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Postal Address Line 3"
                                name="input_paddr3"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr3 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Postal Address Line 4"
                                name="input_paddr4"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr4 = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="License Number"
                                name="input_lic_no"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_lic_no = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Service Tax"
                                name="input_svc_tax"
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_svc_tax = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="GL Account No."
                                name="input_gl_acctno"
                                inputProps={{ maxLength: 12 }}
                                inputRef={node => (this.input_gl_acctno = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RequiredSelectTextInput
                                label="Default Payee Name"
                                name="input_ls_payee_ind"
                                value={this.state.input_ls_payee_ind}
                                onChange={this.onChangeHandler}
                                data={this.state.payee_ind_data}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="LS Account No."
                                name="input_ls_acct_no"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_ls_acct_no = node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RequiredSelectTextInput
                                error={this.state.error_ls_bank}
                                label="LS Bank"
                                name="input_ls_bank"
                                value={this.state.input_ls_bank}
                                onChange={this.onChangeHandler}
                                data={this.props.swift_code_data}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTextInput
                                label="LS Bank Branch"
                                name="input_ls_bank_branch"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ls_bank_branch = node)}
                            />
                        </Grid>
                    </Grid>
                </form>

                <Box pt={4}
                    className={classes.box}>

                    {this.props.loading ?
                        <ButtonSubmitLoad
                            className={classes.button}
                        />

                        :

                        <ButtonSubmitIdle
                            className={classes.button}
                            onClick={this.submitHandler}
                        />
                    }
                </Box>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);