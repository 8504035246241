import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';
import DialogComponent from './Component/DialogComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class EngineeringPaymentForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            amount: '',
            bank: [],

            backdrop: false,
            dialog: false,
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    fetchData = () => {
        axios.put(Config() + '/ls_surveyjob/engineering/application/payment/initialize',
            {
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        amount: response.data[1].toFixed(2),
                        bank: response.data[2],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    addData = (
        payment_mode, bank, cheque, 
        // ref,
        file_object, file_name
    ) => {
        axios.post(Config() + '/ls_surveyjob/engineering/application/payment/add',
            {
                'payment_mode': payment_mode,
                'bank': bank,
                'cheque_no': cheque,
                // 'ref_no': ref,
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.addFile(file_object, file_name)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    addFile = (file_object, file_name) => {
        const data = new FormData()

        for (var i = 0; i < file_object.length; i++) {
            data.append('file', file_object[i])
            data.append('filename', file_name[i])
        }

        data.append('form_id', sessionStorage.getItem('form_id_view'))

        axios.post(Config() + '/ls_surveyjob/engineering/application/payment/add/file',
            data,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    closeDialog = () => {
        this.setState({
            dialog: false
        })
    }

    goNext = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/EngineeringJobList'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringJobView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    onClose={this.closeDialog}
                    open={this.state.dialog}
                    onClick={this.goNext} />

                <HeaderComponent />

                <FormComponent
                    amount={this.state.amount}
                    bank={this.state.bank}
                    action={this.addData}
                    action2={this.goBack}
                    ref={this.ComponentUI}
                />

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(EngineeringPaymentForm));