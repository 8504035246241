import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import PasswordTextInput from '../Util/PasswordTextInput';
import SelectTextInput from '../Util/SelectTextInput';
import GenericTextInput from '../Util/GenericTextInput';
import NextIdleButton from '../Util/NextIdleButton';
import NextLoadButton from '../Util/NextLoadButton';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_ls_name: '',
        }
    }

    onChangeHandler = event => {
        this.setState({
            input_ls_name: event.target.value
        })
        this.props.action2(event.target.value)
    }

    submitHandler = () => {
        if (this.state.input_ls_name === '' ||
        this.input_login_name.value === '' ||
        this.input_password.value === '' ||
        this.input_ls_staff_name.value === '') {
            this.errorHandler("Please fill in the required input/s.")
        }
        
        else {
            this.props.action(
                this.state.input_ls_name,
                this.input_login_name.value,
                this.input_password.value,
                this.input_ls_staff_name.value,
                this.input_email_1.value,
                this.input_email_2.value,
                this.input_contact_no.value,
                this.input_designation.value
            )
        }
    }

    selecterrorHandler = (response) => {
        this.setState({
            error_ls_name: response
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }


    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                <form noValidate>

                    {this.state.alert ?
                        <Alert severity="error">
                            {this.state.error_msg}
                        </Alert>
                        :
                        null}

                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectTextInput
                                error={this.state.error_ls_name}
                                helperText={this.state.error_ls_name ? "The selected licensed surveyor already has maximum number of LS Staff registered." : null}
                                label="Please choose an option:"
                                name="input_ls_name"
                                value={this.state.input_ls_name}
                                onChange={this.onChangeHandler}
                                data={this.props.data}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_login_name}
                                label="Login Name"
                                inputProps={{ maxLength: 15 }}
                                inputRef={node => (this.input_login_name = node)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordTextInput
                                error={this.state.error_password}
                                label="Login Password"
                                inputProps={{ maxLength: 12 }}
                                inputRef={node => (this.input_password = node)}
                            />
                        </Grid>
                    </Grid>

                    <br />

                    <Typography variant="h6" gutterBottom>
                        LS Staff Information
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_ls_staff_name}
                                label="Name"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_ls_staff_name = node)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Email"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_email_1 = node)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Email (Alternative)"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_email_2 = node)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Contact No."
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_contact_no = node)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Designation"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_designation = node)}
                            />
                        </Grid>
                    </Grid>

                    {this.state.loading ?

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextLoadButton className={classes.button} />
                        </Box>

                        :

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextIdleButton className={classes.button}
                                onClick={this.submitHandler} />
                        </Box>
                    }
                </form>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);