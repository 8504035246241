import React from 'react';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import GridComponent from './Component/GridComponent';

class SurveyorLogin extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
    }
    
    loginHandler = (username, password) => {
        axios.post(Config() + '/authentication/surveyor/login',
        {
            username: username,
            password: password
        },
        {
            withCredentials: true
        })
        .then(response => {
            console.log(response)
            localStorage.clear()
            localStorage.setItem('user_name', response.data['username'])
            localStorage.setItem('token', 'some key')
            localStorage.setItem('perm', response.data['access_level'])

            //LS Staff route added on 07/10/2021
            if (localStorage.getItem('perm') === "1") {
                this.props.history.push({
                    pathname: '/ui/SurveyorHome'
                })
            }
            else if (localStorage.getItem('perm') === "2") {
                this.props.history.push({
                    pathname: '/ui/LSStaffHome'
                })
            }
            else {
                this.props.history.push({
                    pathname: '/'
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                }

                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }
    
    render() {
        return (
            <div>
                <GridComponent action={this.loginHandler} ref={this.ComponentUI} />
            </div>
        )
    }
}

export default SurveyorLogin;