import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import axios from "axios";
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },

    contentMobile: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    contentDesktop: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginLeft: 240,
    },
})

class CadastralTechnicianPendingDashboardList extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,

            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData(this.state.page, this.state.rowsPerPage)
    }

    fetchData = (page, rowsPerPage) => {
        this.setState({ backdrop: true })

        if (localStorage.getItem('perm') === "2") {
            var url = Config() + '/ls_dashboard/list/cadastral/technician/ls_staff/page=' + parseInt(page + 1) + '/count=' + rowsPerPage
        }
        else {
            url = Config() + '/ls_dashboard/list/cadastral/technician/page=' + parseInt(page + 1) + '/count=' + rowsPerPage
        }

        axios.get(url,
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    data: response.data[1],
                    page: page,
                    count: response.data[3]['totalcount'],

                    backdrop: false
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                        // this.setState({ invalid_session: true })
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    //sorting function for table
    sortHandler = (clickedColumn) => {
        const { column, data, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    //handle page changes and set user defined page number and rows per page
    pageHandler = (newPage) => {
        this.setState({
            page: newPage,
            backdrop: true
        })
        this.fetchData(newPage, this.state.rowsPerPage)
    }

    //handle changes in rows display per page
    rowHandler = (value) => {
        this.setState({
            rowsPerPage: value,
            page: 0,
            backdrop: true
        })
        this.fetchData(0, value)
    }

    goView = (technician_id) => {
        if (localStorage.getItem('perm') === "2") {
            sessionStorage.setItem('technician_id', technician_id)
            this.props.history.push({
                pathname: '/ui/CadastralTechnicianForm'
            })
        }
        else {
            sessionStorage.setItem('technician_id_view', technician_id)
            this.props.history.push({
                pathname: '/ui/CadastralTechnicianView'
            })
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <br />
                
                <TableComponent
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                data={this.state.data}
                action={this.goView}
                actionSort={this.sortHandler}
                column={this.state.column}
                direction={this.state.direction}
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.pageHandler}
                onChangeRowsPerPage={this.rowHandler} />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(CadastralTechnicianPendingDashboardList));