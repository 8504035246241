import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';

import Alert from '@material-ui/lab/Alert';

class SnackbarComponent extends React.Component {
    render() {
        return (
            <Snackbar
            open={this.props.open}
            autoHideDuration={3000}
            onClose={this.props.onClose}>

                <Alert 
                onClose={this.props.onClose}
                severity="success"
                elevation={6}
                variant="filled">
                    
                    Table deleted!

                </Alert>
            </Snackbar>
        )
    }
}

export default SnackbarComponent;