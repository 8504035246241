import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class LSStaffHome extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_data: [],
            section2_data: [],
            section3_data: [],
            section4_data: [],
            section5_data: [],

            backdrop: false,
            visible: false
        }
    }

    componentDidMount() {
        sessionStorage.clear()
        //Check if user is a ls staff
        if (localStorage.getItem('perm') !== "2") {
            this.props.history.push({ pathname: '/' })
        }
        else {
            this.setState({ backdrop: true })
            this.fetchData()
        }
    }

    fetchData = () => {
        axios.get(Config() + '/landing/surveyor/lsstaff/get',
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section2_data: response.data[2],
                        section3_data: response.data[3],
                        section4_data: response.data[4],
                        section5_data: response.data[5],

                        backdrop: false,
                        visible: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false,
                    visible: true
                })
            })
    }

    goSurveyJobList = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/LSCadastralSurveyJobPendingDashboardList'
            })
        }
        else if (route === "Engineering") {
            this.props.history.push({
                pathname: '/ui/LSEngineeringSurveyJobPendingDashboardList'
            })
        }
        else if (route === "Privatised") {
            this.props.history.push({
                pathname: '/ui/LSPrivatisedSurveyJobPendingDashboardList'
            })
        }
    }

    goSurveyJobClaimList = (route) => {
        //Determine what is the route and redirect accordingly
        // if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/LSCadastralSurveyJobPendingClaimDashboardList'
            })
        // }
        // else if (route === "Engineering") {
        //     this.props.history.push({
        //         pathname: '/ui/LSEngineeringSurveyJobPendingDashboardList'
        //     })
        // }
        // else if (route === "Privatised") {
        //     this.props.history.push({
        //         pathname: '/ui/LSPrivatisedSurveyJobPendingDashboardList'
        //     })
        // }
    }

    goTechnicianList = (route) => {
        //Determine what is the route and redirect accordingly
        if (route === "Cadastral") {
            this.props.history.push({
                pathname: '/ui/LSCadastralTechnicianPendingDashboardList'
            })
        }
        else if (route === "Engineering") {
            this.props.history.push({
                pathname: '/ui/LSEngineeringTechnicianPendingDashboardList'
            })
        }
    }

    goArticledGraduateList = () => {
        this.props.history.push({
            pathname: '/ui/LSArticledGraduatePendingDashboardList'
        })
    }

    goCPDList = () => {
        this.props.history.push({
            pathname: '/ui/LSCPDPendingDashboardList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent
                    visible={this.state.visible} />

                <Section1Component
                    visible={this.state.visible}
                    data={this.state.section1_data}
                    action={this.goSurveyJobList} />

                <Section5Component
                    visible={this.state.visible}
                    data={this.state.section5_data}
                    action={this.goSurveyJobClaimList} />

                <Section2Component
                    visible={this.state.visible}
                    data={this.state.section2_data}
                    action={this.goTechnicianList} />

                <Section3Component
                    visible={this.state.visible}
                    data={this.state.section3_data}
                    action={this.goArticledGraduateList} />

                <Section4Component
                    visible={this.state.visible}
                    data={this.state.section4_data}
                    action={this.goCPDList} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(LSStaffHome));