import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';
import ButtonComponent from './Component/ButtonComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class CadastralTableSummary extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false,
            loading: false,
            dialog: false,
            snackbar: false,

            sftable: [],
            input_sst: '',
            total_amount: (0).toFixed(2)
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        // if (sessionStorage.getItem('form_id') === '' || sessionStorage.getItem('form_id') === undefined ||
        //     sessionStorage.getItem('form_id') === null) {
        //     this.props.history.push({
        //         pathname: '/ui/SurveyorHome'
        //     })
        // }
        // else {
        this.setState({ backdrop: true })
        this.fetchData()
        // }
    }

    fetchData = () => {
        axios.put(Config() + '/surveyjob/cadastral/table/summarize/get',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        this.setState({
                            sftable: [],
                            backdrop: false
                        },
                            () => {
                                this.total_ChangeHandler()
                            })
                    }
                    else {
                        this.setState({
                            sftable: response.data[1]
                        }, () => {
                            this.fetchSST()
                        })
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    fetchSST = () => {
        axios.put(Config() + '/surveyjob/cadastral/table/summarize/get/sst',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        this.total_ChangeHandler()
                        this.setState({
                            backdrop: false
                        })
                    }
                    else {
                        this.setState({
                            input_sst: response.data[1][0]['sst_input'].toFixed(2)
                        }, () => {
                            let total = this.total_ChangeHandler()
                            this.setState({
                                total_amount: (Number(total) +
                                    Number(this.state.input_sst)).toFixed(2),
                                backdrop: false
                            })
                        })
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    total_ChangeHandler = () => {
        let temp_array = []
        for (var i = 0; i < this.state.sftable.length; i++) {
            temp_array.push(this.state.sftable[i]['total_amount'])
        }

        let total = temp_array.reduce((prev, next) =>
            (Number(prev) + Number(next)), 0).toFixed(2)
        this.setState({ total_amount: total })

        return total
    }

    redirectionTable = (table) => {
        this.props.history.push({
            pathname: '/ui/StaffCadastralTable' + table + 'View'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralSurveyJobReview'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <TableComponent
                    array={this.state.sftable}
                    value={this.state.input_sst}
                    value2={this.state.total_amount}
                    action={this.redirectionTable}
                    action2={this.openDialogHandler}
                    ref={this.ComponentUI} />

                <ButtonComponent
                    action={this.goBack} />

            </div>

        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralTableSummary));