import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

import Section1Component from './Section1Component';
import Section2Component from './Section2Component';
import Section3Component from './Section3Component';
import Section4Component from './Section4Component';
import Section5Component from './Section5Component';
import Section6Component from './Section6Component';
import SectionTotalComponent from './SectionTotalComponent';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    }
})

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="secondary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Back
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_input: '',
            section1_amount: (0).toFixed(2),
            section1_loading: false,

            section2_input: [],
            section2_array_amount: [],
            section2_array_loading: [],
            section2_amount: (0).toFixed(2),
            section2_loading: false,

            section3_input_1: [],
            section3_input_2: [],
            section3_select: [],
            section3_array_amount: [],
            section3_array_loading: [],
            section3_amount: (0).toFixed(2),
            section3_loading: false,

            section4_input: '',
            section4_amount: (0).toFixed(2),
            section4_loading: false,

            section5_select: '',
            section5_amount: (0).toFixed(2),
            section5_loading: false,

            section6_a_checkbox: false,
            section6_a_amount: (0).toFixed(2),
            section6_a_loading: false,

            section6_b_checkbox: false,
            section6_b_amount: (0).toFixed(2),
            section6_b_loading: false,

            section6_c_input: '',
            section6_c_amount: (0).toFixed(2),
            section6_c_loading: false,

            total_amount: (0).toFixed(2),

            error_msg: ''
        }
    }

    getData = (
        section1_input,
        section2_input,
        section3_input_1,
        section3_input_2,
        section3_select,
        section4_input,
        section5_select,
        section6_a_checkbox,
        section6_b_checkbox,
        section6_c_input,
        section1_amount,
        section2_array_amount,
        section2_amount,
        section3_array_amount,
        section3_amount,
        section4_amount,
        section5_amount,
        section6_a_amount,
        section6_b_amount,
        section6_c_amount ) => {

            this.setState({
                section1_input: section1_input,
                section2_input: section2_input,
                section3_input_1: section3_input_1,
                section3_input_2: section3_input_2,
                section3_select: section3_select,
                section4_input: section4_input,
                section5_select: section5_select,
                section6_a_checkbox: section6_a_checkbox,
                section6_b_checkbox: section6_b_checkbox,
                section6_c_input: section6_c_input,
                section1_amount: section1_amount,
                section2_array_amount: section2_array_amount,
                section2_amount: section2_amount,
                section3_array_amount: section3_array_amount,
                section3_amount: section3_amount,
                section4_amount: section4_amount,
                section5_amount: section5_amount,
                section6_a_amount: section6_a_amount,
                section6_b_amount: section6_b_amount,
                section6_c_amount: section6_c_amount
            }, () => {
                this.total_ChangeHandler()
            })
    }

    total_ChangeHandler = () => {
        var total = (Number(this.state.section1_amount) + Number(this.state.section2_amount) +
            Number(this.state.section3_amount) + Number(this.state.section4_amount) +
            Number(this.state.section5_amount) + Number(this.state.section6_a_amount) +
            Number(this.state.section6_b_amount) + Number(this.state.section6_c_amount))

        this.setState({
            total_amount: total.toFixed(2),
            loading: false
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3}
                    className={classes.paper}>

                    {this.state.alert ?
                        <div>
                            <Alert severity="error">
                                {this.state.error_msg}
                            </Alert>
                            <br />
                        </div>
                        :
                        null}

                    <Section1Component
                        value={this.state.section1_input}
                        valueTotal={this.state.section1_amount}
                        loading={this.state.section1_loading}
                    />

                    <Section2Component
                        array={this.state.section2_input}
                        action1={this.section2_addArray}
                        action2={this.section2_removeArray}
                        // action3={this.count}
                        value={this.state.section2_input}
                        arrayLoading={this.state.section2_array_loading}
                        arrayAmount={this.state.section2_array_amount}
                        loading={this.state.section2_loading}
                        valueTotal={this.state.section2_amount}
                    />

                    <Section3Component
                        array={this.state.section3_input_1}
                        action1={this.section3_addArray}
                        action2={this.section3_removeArray}
                        value1={this.state.section3_input_1}
                        value2={this.state.section3_input_2}
                        value3={this.state.section3_select}
                        arrayLoading={this.state.section3_array_loading}
                        arrayAmount={this.state.section3_array_amount}
                        loading={this.state.section3_loading}
                        valueTotal={this.state.section3_amount} />

                    <Section4Component
                        value={this.state.section4_input}
                        valueTotal={this.state.section4_amount}
                        loading={this.state.section4_loading}
                    />

                    <Section5Component
                        value={this.state.section5_select}
                        valueTotal={this.state.section5_amount}
                        loading={this.state.section5_loading}
                    />

                    <Section6Component
                        value1={this.state.section6_a_checkbox}
                        value2={this.state.section6_b_checkbox}
                        value3={this.state.section6_c_input}
                        loading1={this.state.section6_a_loading}
                        loading2={this.state.section6_b_loading}
                        loading3={this.state.section6_c_loading}
                        valueTotal1={this.state.section6_a_amount}
                        valueTotal2={this.state.section6_b_amount}
                        valueTotal3={this.state.section6_c_amount}
                    />

                    <SectionTotalComponent
                        value={this.state.total_amount}
                        loading={this.state.loading}
                    />

                </Paper>
            
            <Box pt={4} className={classes.box}>
                <BackIdleButton
                className={classes.button}
                onClick={this.props.action} />
                </Box>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);