import React from 'react'

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import BreakdownComponent1 from './Component/BreakdownComponent1';
import BreakdownComponent2 from './Component/BreakdownComponent2';
import Section1Component from './Component/Section1Component';
import Review1Component from './Component/Review1Component';
import File1Component from './Component/File1Component';
import ButtonComponent from './Component/ButtonComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class CadastralClaimView extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            section1_data: [],
            breakdown_data: [],
            refund_type: '',
            pb_cancellation_data: [],

            backdrop: false
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    fetchData = () => {
        axios.put(Config() + '/ls_claim/cadastral/view/get/cancellation',
            {
                'claim_id': sessionStorage.getItem('claim_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        section1_data: response.data[2],
                        breakdown_data: response.data[3],
                        refund_type: response.data[4],
                        pb_cancellation_data: response.data[5],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralClaimList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <BreakdownComponent1 data={this.state.breakdown_data} />
                    <BreakdownComponent2 data={this.state.breakdown_data}
                        refund_type={this.state.refund_type} />

                    <br />
                    <Divider />
                    <br />

                    <Section1Component
                        data={this.state.section1_data} />

                    <Review1Component
                        data={this.state.data} />

                    <File1Component
                        data={this.state.pb_cancellation_data} />

                </Paper>

                <ButtonComponent
                    action={this.goBack} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralClaimView));