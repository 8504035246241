import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <div>
                <Box pt={4}
                    className={classes.box}>

                    <BackIdleButton
                        className={classes.button}
                        onClick={this.props.action} />
                </Box>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);