import React from 'react';

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import ReviewComponent from './Component/ReviewComponent';
import ButtonComponent from './Component/ButtonComponent';
import DialogComponent from './Component/DialogComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class PrivatisedNewApplicationReview extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_data: [],
            section1_file_data: [],
            section2_data: [],

            charge_percent: [
                {
                    'key': '2',
                    'value': '2',
                    'text': '2'
                },
                {
                    'key': '5',
                    'value': '5',
                    'text': '5'
                }
            ],

            backdrop: false,

            dialog_1: false,
            dialog_2: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/surveyjob/privatised/new/view/get',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section1_file_data: response.data[2],
                        section2_data: response.data[3],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    updateHandler = (
        total_claim, actual_survey_fee, jtu_sedia_payment,
        admin_charge_percent, total_admin_charge, admin_charge_paid,
        transportation, disbursement
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/new/review/update/surveyfee',
        {
            'form_id': sessionStorage.getItem('form_id_review'),
            'total_claim': total_claim,
            'actual_survey_fee': actual_survey_fee,
            'jtu_sedia_payment': jtu_sedia_payment,
            'admin_charge_percent': admin_charge_percent,
            'total_admin_charge': total_admin_charge,
            'admin_charge_paid': admin_charge_paid,
            'transportation': transportation,
            'disbursement': disbursement
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ loading: false })
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/StaffPrivatisedNewDashboardList'
        })
    }

    openFirstCheckerDialog = () => {
        this.setState({ dialog_1: true })
    }

    closeFirstCheckerDialog = () => {
        this.setState({ dialog_1: false })
    }

    submitFirstChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/new/review/update/checker1',
        {
            'form_id': sessionStorage.getItem('form_id_review'),
            'comment': value
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    openSecondCheckerDialog = () => {
        this.setState({ dialog_2: true })
    }

    closeSecondCheckerDialog = () => {
        this.setState({ dialog_2: false })
    }

    approveSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/new/review/update/checker2/approve',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.props.history.push({
                        pathname: '/ui/StaffPrivatisedJobList'
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/new/review/update/checker2/reject',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.props.history.push({
                        pathname: '/ui/StaffPrivatisedJobList'
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    open1={this.state.dialog_1}
                    onClose1={this.closeFirstCheckerDialog}
                    action1={this.submitFirstChecker}
                    open2={this.state.dialog_2}
                    onClose2={this.closeSecondCheckerDialog}
                    action2={this.approveSecondChecker}
                    action3={this.rejectSecondChecker}
                />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Section1Component
                        data={this.state.section1_data}
                        file={this.state.section1_file_data}
                    />

                    <Section2Component
                        section1_data={this.state.section1_data}
                        data={this.state.section2_data}
                        charge_percent={this.state.charge_percent}
                        loading={this.state.loading}
                        action={this.updateHandler}
                    />

                    <br />
                    <br />
                    <br />

                    <ReviewComponent
                        data={this.state.section1_data}
                    />
                </Paper>

                <ButtonComponent
                    action={this.goBack}
                    action1={this.openFirstCheckerDialog}
                    action2={this.openSecondCheckerDialog}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedNewApplicationReview));