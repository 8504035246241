import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from "axios";
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import SearchPanelComponent from './Component/SearchPanelComponent';
import TableComponent from './Component/TableComponent';
import DialogComponent from './Component/DialogComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    contentMobile: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    contentDesktop: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar
        marginLeft: 240,
    },
})

class OldSTSTechnicianList extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [], 
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,
            technician_view: [],

            dialog: false,

            backdrop: false,
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,
        }, () => {
            this.fetchData(this.state.page, this.state.rowsPerPage)
        })
    }

    fetchData = (page, rowsPerPage) => {
        this.setState({ backdrop: true })

        axios.get(Config() + '/technician/oldsts/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    //handle page changes and set user defined page number and rows per page
    pageHandler = (newPage) => {
        this.setState({
            page: newPage,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(newPage, this.state.rowsPerPage)
        }
    }

    //handle changes in rows display per page
    rowHandler = (value) => {
        this.setState({ 
            rowsPerPage: value,
            page: 0,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(0, value)
        }
    }

    goView = (technician_id) => {
        axios.put(Config() + '/technician/oldsts/search/page=1/count=1', 
        {
            'technician_id': technician_id,
            'technician_name': '',
            'ls_name': '',
            'status': ''
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    technician_view: response.data[1],
                    dialog: true
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    errorHandler = (value) => {
        this.setState({ 
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.contentDesktop}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <SearchPanelComponent />
                <br />
                <TableComponent
                alert={this.state.alert}
                error_msg={this.state.error_msg} 
                data={this.state.data}
                action={this.goView}
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.pageHandler}
                onChangeRowsPerPage={this.rowHandler} />

                <DialogComponent
                open={this.state.dialog}
                onClose={this.closeDialog}
                onClick={this.closeDialog}
                data={this.state.technician_view}
                />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(OldSTSTechnicianList));