import React from 'react';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class GenericRequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

class GenericTextInput extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

class TelTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                type="tel"
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

class SelectTextInput extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                select
                label={this.props.label}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
                SelectProps={this.props.SelectProps}
            >
                {this.props.data.map(x => (
                    <MenuItem key={x.key}
                        value={x.value}
                        style={{ whiteSpace: 'normal' }}>
                        {x.text}
                    </MenuItem>
                ))}
            </TextField>
        )
    }
}

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Next
            </Button>
        )
    }
}

class CancelIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Cancel
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class CancelLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_job_type: '',
            input_district: '',

            shrink: true,

            error_msg: ''
        }
    }

    getDataHandler = (
        reference, job_type, job_desc, jtu_file,
        district, location, name, address, country,
        city, state, postcode, tel_no, email
    ) => {
        this.input_reference.value = reference
        this.input_job_desc.value = job_desc
        this.input_jtu_file.value = jtu_file
        this.input_location.value = location
        this.input_name.value = name
        this.input_address.value = address
        this.input_country.value = country
        this.input_city.value = city
        this.input_state.value = state
        this.input_postcode.value = postcode
        this.input_tel_no.value = tel_no
        this.input_email.value = email

        this.setState({
            input_job_type: job_type,
            input_district: district,
        })
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {

        this.setState({ loading: true })

        //eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.input_reference.value === '' ||
            this.input_job_desc.value === '' ||
            this.input_location.value === '' ||
            this.input_name.value === '' ||
            this.input_address.value === '' ||
            // this.input_country.value === '' ||
            // this.input_city.value === '' ||
            // this.input_state.value === '' ||
            // this.input_postcode.value === '' ||
            // this.input_tel_no.value === '' ||
            // this.input_email.value === '' ||

            this.state.input_job_type === '' ||
            this.state.input_district === '') {

            this.setState({
                error_reference: this.input_reference.value ? false : true,
                error_job_type: this.state.input_job_type ? false : true,
                error_job_desc: this.input_job_desc.value ? false : true,
                error_district: this.state.input_district ? false : true,
                error_location: this.input_location.value ? false : true,
                error_name: this.input_name.value ? false : true,
                error_address: this.input_address.value ? false : true,
                //-- requested by LJS 31/03/2022: Disable client's details field checking
                // error_country: this.input_country.value ? false : true,
                // error_city: this.input_city.value ? false : true,
                // error_state: this.input_state.value ? false : true,
                // error_postcode: this.input_postcode.value ? false : true,
                // error_tel_no: this.input_tel_no.value ? false : true,
                // error_email: this.input_email.value ? false : true,
            })
            this.errorHandler("Please fill in the required input/s.")
        }
        else {
            // if (!re.test(this.input_email.value)) {
            //     this.setState({
            //         error_reference: this.input_reference.value ? false : true,
            //         error_job_type: this.state.input_job_type ? false : true,
            //         error_job_desc: this.input_job_desc.value ? false : true,
            //         error_district: this.state.input_district ? false : true,
            //         error_location: this.input_location.value ? false : true,
            //         error_name: this.input_name.value ? false : true,
            //         error_address: this.input_address.value ? false : true,
            //         error_country: this.input_country.value ? false : true,
            //         error_city: this.input_city.value ? false : true,
            //         error_state: this.input_state.value ? false : true,
            //         error_postcode: this.input_postcode.value ? false : true,
            //         error_tel_no: this.input_tel_no.value ? false : true,

            //         error_email: true,
            //     })
            //     this.errorHandler("Invalid format for Email Address.")
            // }
            // else {
                this.props.action(
                    this.input_reference.value,
                    this.state.input_job_type,
                    this.input_job_desc.value,
                    this.input_jtu_file.value,
                    this.state.input_district,
                    this.input_location.value,
                    this.input_name.value,
                    this.input_address.value,
                    this.input_country.value,
                    this.input_city.value,
                    this.input_state.value,
                    this.input_postcode.value,
                    this.input_tel_no.value,
                    this.input_email.value
                )
            // }
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                <form noValidate>

                    {this.state.alert ?
                        <Alert severity="error">
                            {this.state.error_msg}
                        </Alert>
                        :
                        null}

                    <br />

                    <Typography variant="h6" gutterBottom>
                        Survey Job Details
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                error={this.state.error_reference}
                                label="Licensed Surveyor Reference"
                                name="input_reference"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_reference = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectTextInput
                                error={this.state.error_job_type}
                                label="Classification of Survey"
                                name="input_job_type"
                                value={this.state.input_job_type}
                                onChange={this.onChangeHandler}
                                // SelectProps={{ multiple: true }}
                                data={this.props.data2}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericRequiredTextInput
                                error={this.state.error_job_desc}
                                label="Job Description"
                                name="input_job_desc"
                                inputProps={{ maxLength: 1000 }}
                                inputRef={node => (this.input_job_desc = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericTextInput
                                label="ESP No. (If any)"
                                name="input_jtu_file"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_jtu_file = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectTextInput
                                error={this.state.error_district}
                                label="District"
                                name="input_district"
                                value={this.state.input_district}
                                onChange={this.onChangeHandler}
                                data={this.props.data}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                error={this.state.error_location}
                                label="Location"
                                name="input_location"
                                inputProps={{ maxLength: 200 }}
                                inputRef={node => (this.input_location = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Landowner / Representative Details</Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                error={this.state.error_name}
                                label="Name"
                                name="input_name"
                                inputProps={{ maxLength: 250 }}
                                inputRef={node => (this.input_name = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                error={this.state.error_address}
                                label="Address"
                                name="input_address"
                                inputProps={{ maxLength: 450 }}
                                inputRef={node => (this.input_address = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                label="Country"
                                name="input_country"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_country = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                label="City"
                                name="input_city"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_city = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                label="State"
                                name="input_state"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_state = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                label="Postcode"
                                name="input_postcode"
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_postcode = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TelTextInput
                                label="Telephone Number"
                                name="input_tel_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_tel_no = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GenericRequiredTextInput
                                label="Email Address"
                                name="input_email"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_email = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>
                    </Grid>

                    {this.state.loading ?

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextLoadButton className={classes.button} />
                            <CancelLoadButton className={classes.button} />
                        </Box>

                        :

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextIdleButton className={classes.button}
                                onClick={this.submitHandler} />
                            <CancelIdleButton className={classes.button}
                                onClick={this.props.action2} />
                        </Box>
                    }
                </form>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);