import React from 'react'

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    container: {
        maxHeight: 400
    },
    table: {
        minWidth: 500
    },
    tablecell: {
        fontWeight: 'bold',
        width: '50%'
    },
})

class TableComponent extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    clickHandler = (form_id, active) => {
        this.props.action(form_id, active)
    }

    render() {
        const { classes } = this.props

        const cellHeader = [
            {
                id: "percentage",
                label: "Claim Percentage"
            },
            {
                id: "active",
                label: "Latest Submission"
            }
        ]

        return (
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.tablecell}
                                        key={cellHeader.id}
                                        padding="default"
                                        align="center"
                                    >
                                        {cellHeader.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {!this.props.data.length ?
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center"
                                        colSpan={6}>
                                        No data to display.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow hover key={x.form_id} onClick={e => this.clickHandler(x.form_id, x.active)}>
                                        <TableCell align="center">{x.percentage}</TableCell>
                                        <TableCell align="center">{x.active}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);