import React from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class Review1Component extends React.Component {
    render() {
        const { classes } = this.props
        
        return (
            <div>
                <Divider />
                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    LJS Checkers Review Details for Application
                </Typography>

                {this.props.data.map(x => (
                    <div className={classes.padding}
                    key={x.first_reviewed_by}>
                    <p>First Checker Reviewed by: {x.first_reviewed_by}</p>
                    <p>First Checker Reviewed at: {x.first_reviewed_at}</p>
                    <p>First Checker Comment: {x.first_comment}</p>
                    <p>Second Checker Reviewed by: {x.second_reviewed_by}</p>
                    <p>Second Checker Reviewed at: {x.second_reviewed_at}</p>
                    <p>Second Checker Comment: {x.second_comment}</p>
                    </div>
                ))}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Review1Component);