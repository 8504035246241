import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class CadastralClaimSelector extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            percentage: 0,
            claim_percent: 0,
            claim_status: '',
            claim_id: 0,
            claim_ind: '',
            backdrop: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })

        axios.put(Config() + '/ls_claim/cadastral/application/initialize',
        {
            'form_id': sessionStorage.getItem('form_id_view'),
            'percentage': sessionStorage.getItem('claim_percent_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[5] === 0) {
                console.log(" New claim application")
                this.setState({ 
                    percentage: 0, 
                    claim_percent: 0, 
                    claim_status: '',
                    claim_id: 0, 
                    claim_ind: '',
                    curr_percentage: 0,
                    prev_percentage: 0,
                    initial_percentage:0
                })
            }else{
                if (response.data[1] === 'Draft'){
                    console.log(" Not New claim application - draft data")
                    console.log(" response_initialize - "+response.data)
                    if (response.data[6] > 0){
                        this.setState({ 
                            percentage: response.data[4], 
                            claim_percent: response.data[4], 
                            claim_status: response.data[1], 
                            claim_id: response.data[2],
                            claim_ind: response.data[3],
                            curr_percentage: response.data[4],
                            prev_percentage: response.data[5],
                            initial_percentage:response.data[6]
                        })
                    }else{
                        this.setState({ 
                            percentage: 0, 
                            claim_percent: 0, 
                            claim_status: response.data[1], 
                            claim_id: response.data[2],
                            claim_ind: response.data[3],
                            curr_percentage: response.data[4],
                            prev_percentage: response.data[5],
                            initial_percentage:response.data[6]
                        })
                    }
                    // this.getClaimData()

                }else{
                    console.log(" Not New claim application - submitted data")
                    console.log(" response_initialize - "+response.data)
                    this.setState({ 
                        percentage: response.data[0], 
                        claim_percent: response.data[0], 
                        claim_status: response.data[1], 
                        claim_id: response.data[2],
                        claim_ind: response.data[3],
                        curr_percentage: response.data[4],
                        prev_percentage: response.data[5],
                        initial_percentage:response.data[6]
                    })
                }
                console.log(" claim_percent = ", this.state.claim_percent)
                console.log(" claim_status = ", this.state.claim_status)
                console.log(" claim_ind = ", this.state.claim_ind)
            }
            this.setState({ backdrop: false })
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    // getClaimData = () => {
    //     this.setState({ backdrop: true })
    //     axios.put(Config() + '/ls_claim/cadastral/view/get',
    //         {
    //             'claim_id': sessionStorage.getItem('claim_id_view')
    //         },
    //         {
    //             withCredentials: true
    //         })
    //         .then(response => {
    //             if (response.data[0]['result'] === "Success") {
    //                 console.log(" claim pc = "+response.data[1][0]['percentage'])
    //                 console.log(" claim status = "+response.data[1][0]['status'])
    //                 sessionStorage.setItem('claim_percent_view', response.data[1][0]['percentage'])
    //                 if (response.data[1][0]['status'] === "Draft") {
    //                     this.setState({
    //                         percentage: 0,
    //                         claim_percent: 0,
    //                         backdrop: false
    //                     })
    //                 }else{
    //                     this.setState({
    //                         percentage: response.data[1][0]['percentage'],
    //                         claim_percent: response.data[1][0]['percentage'],
    //                         backdrop: false
    //                     })
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             if (!error.response) {
    //                 this.errorHandler("Code issue probably.")
    //             }
    //             else {
    //                 if (ResponseStatusCheck(error)) {
    //                     //If Session Expired
    //                     if (ResponseStatusCheck(error) === 401) {
    //                         console.log("Display some window here.")
    //                     }
    //                     else {
    //                         this.errorHandler(ResponseStatusCheck(error))
    //                     }
    //                 }
    //                 else {
    //                     this.errorHandler("Something went very wrong!")
    //                 }
    //             }
    //             this.setState({
    //                 backdrop: false
    //             })
    //         })
    // }

    goNext = () => {
        this.props.history.push({
            pathname: '/ui/CadastralClaimForm'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralJobView'
        })
    }

    render() {
        const { classes } = this.props
        
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <FormComponent 
                value={this.state.percentage}
                claimpc={this.state.claim_percent}
                claimstatus={this.state.claim_status}
                claimind={this.state.claim_ind}
                action={this.goNext}
                action2={this.goBack} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralClaimSelector));