import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    cardContent: {
        margin: theme.spacing(2)
    },
})

class Section1DialogComponent extends React.Component {
    render() {
        return (
            <Dialog
                onClose={this.props.onClose} open={this.props.open}>
                <DialogTitle>
                    Technician Details
                        <IconButton aria-label="close" className={this.props.classNameButton}
                        onClick={this.props.onClick}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {this.props.data.map(x => (
                        <Grid container spacing={1} key={x.technician_id}
                            className={this.props.classNameCard}>

                            <Grid item xs={12}>
                                Technician ID:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.technician_id}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Technician Name:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.technician_name}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Registered Under:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.ls_name}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                IC Number:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.ic_no}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Date of Birth:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.birth_date}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Birth Place:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.birth_place}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Practical Date:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.prac_date}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Qualification:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.qualification}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Previous Employer:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.prev_emp}</b>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                Status:
                                </Grid>
                            <Grid item xs={12}>
                                <b>{x.tech_status}</b>
                            </Grid>
                        </Grid>
                    ))}
                </DialogContent>
            </Dialog>
        )
    }
}

class DialogComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Section1DialogComponent
                onClose={this.props.onClose}
                open={this.props.open}
                classNameButton={classes.button}
                classNameCard={classes.card}
                onClick={this.props.onClick}
                data={this.props.data}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);