import React from 'react';

import TextField from '@material-ui/core/TextField';

class GenericTextInput extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                variant="outlined"
                margin="normal"
                fullWidth
                label={this.props.label}
                inputRef={this.props.inputRef}
                inputProps={this.props.inputProps}
            />
        )
    }
}

export default GenericTextInput;