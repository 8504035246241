import React from 'react';
import Button from '@material-ui/core/Button';

class GenericIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}>
                {this.props.label}
            </Button>
        )
    }
}

export default GenericIdleButton;