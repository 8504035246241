import React from 'react'

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Section3Component from './Component/Section3Component';
import Section4Component from './Component/Section4Component';
import Section5Component from './Component/Section5Component';
import Review1Component from './Component/Review1Component';
import File1Component from './Component/File1Component';
import ButtonComponent from './Component/ButtonComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class CadastralTechnicianView extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_data: [],
            section2_data: [],
            section3_data: [],
            section4_data: [],
            section5_data: [],
            sijil_data: [],

            backdrop: false
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.put(Config() + '/ls_technician/cadastral/view/get',
            {
                'technician_id': sessionStorage.getItem('technician_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                console.log(response.data[6])
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section2_data: response.data[2],
                        section3_data: response.data[3],
                        section4_data: response.data[4],
                        sijil_data: response.data[5],
                        section5_data: response.data[6],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    goTerminateForm = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTechnicianTerminateForm'
        })
    }

    submitHandler = () => {
        this.setState({ backdrop: true })
        axios.post(Config() + '/ls_technician/cadastral/view/add',
        {
            'technician_id': sessionStorage.getItem('technician_id_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({
                backdrop: false
            })
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTechnicianList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Typography variant="h4">
                        Technician Information
                    </Typography>
                    <br />

                    <Section1Component
                        data={this.state.section1_data}
                        image={this.state.section2_data} />

                    <Section2Component
                        data={this.state.section2_data} />

                    <Section3Component
                        data={this.state.section3_data} />

                    <Section4Component
                        data={this.state.section4_data} />

                    <Review1Component
                        data={this.state.section1_data} />
                    <br />

                    <File1Component
                        data={this.state.sijil_data} />

                    {/* If the status is Inactive then show this section, 
                    else display null 
                    */}

                    {!this.state.section1_data.length ?
                        null
                        :
                        this.state.section1_data[0]['status'] === "Inactive" ?
                            <Section5Component
                                data={this.state.section5_data}
                                data2={this.state.section1_data} />
                            :
                            null
                    }

                </Paper>

                <ButtonComponent
                    stage={this.state.section1_data}
                    action={this.goBack}
                    action2={this.goTerminateForm}
                    action3={this.submitHandler}
                />

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralTechnicianView));