import React from 'react';

import Grid from '@material-ui/core/Grid';

import GenericIdleTextField from '../../Util/GenericIdleTextField';

class SectionTotalComponent extends React.Component {
    render() {
        return (
            <Grid container
                direction="row"
                justify="flex-end"
                alignItems="center">
                <Grid item xs={12} sm={3}>
                    <GenericIdleTextField
                        label="Total"
                        name="total_amount"
                        value={this.props.value}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default SectionTotalComponent;