import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
// import { Config } from '../../Config';
// import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    }
})

class EngineeringJobStatement extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
    }

    // addData = (
    //     file_object, file_name
    // ) => {
    //     const data = new FormData()

    //     for (let i = 0; i < file_object.length; i++) {
    //         data.append('file_object', file_object[i])
    //         data.append('file_name', file_name[i])
    //     }

    //     axios.post(Config() + '/texttobank/file',
    //         data,
    //         {
    //             withCredentials: true
    //         })
    //         .then(response => {
    //             if (response.data[0]['result'] === "Success") {
    //                 this.openFile(response.data[1])
    //             }
    //         })
    //         .catch(error => {
    //             if (!error.response) {
    //                 this.ComponentUI.current.errorHandler("Code issue probably.")
    //             }
    //             else {
    //                 if (ResponseStatusCheck(error)) {
    //                     //If Session Expired
    //                     if (ResponseStatusCheck(error) === 401) {
    //                         console.log("Display some window here.")
    //                     }
    //                     else {
    //                         this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
    //                     }
    //                 }
    //                 else {
    //                     this.ComponentUI.current.errorHandler("Something went very wrong!")
    //                 }
    //             }
    //         })
    // }

    openFile = (value) => {
        window.open(value, "_blank")
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>

                <HeaderComponent />
                {/* <FormComponent
                    action={this.addData}
                    ref={this.ComponentUI} /> */}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(EngineeringJobStatement));