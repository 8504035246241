import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import axios from "axios";
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import DialogHistoryComponent from './Component/DialogHistoryComponent';
import HeaderComponent from './Component/HeaderComponent';
import SearchPanelComponent from './Component/SearchPanelComponent';
import TableComponent from './Component/TableComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },

    contentMobile: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    contentDesktop: {
        flexGrow: 1,
        padding: theme.spacing(3), 
        marginLeft: 240,
    },
})

class CadastralJobList extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            select_array: [],
            select_array2: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,

            history_data: [],
            job_no: '',
            h_dialog: false,

            backdrop: false,

        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,

            title_no: '',
            job_no: '',
            rsp_no: '',
            ls_name: '',
            curr_stage: '',
            status: '',
            start_date: '',
            end_date: '',
        }, () => {
            this.fetchData(this.state.page, this.state.rowsPerPage)
        })
    }

    fetchData = (page, rowsPerPage) => {
        this.setState({ backdrop: true })

        axios.get(Config() + '/surveyjob/cadastral/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],
                        select_array: response.data[4],
                        select_array2: response.data[5],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    //sorting function for table
    sortHandler = (clickedColumn) => {
        const { column, data, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    //handle page changes and set user defined page number and rows per page
    pageHandler = (newPage) => {
        this.setState({
            page: newPage,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(newPage, this.state.rowsPerPage)
        }
        else {
            this.searchHandler(newPage, this.state.rowsPerPage)
        }
    }

    //handle changes in rows display per page
    rowHandler = (value) => {
        this.setState({
            rowsPerPage: value,
            page: 0,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(0, value)
        }
        else {
            this.searchHandler(0, value)
        }
    }

    viewHistoryHandler = (form_id) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/cadastral/view/history/get',
            {
                'form_id': form_id
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        history_data: response.data[1],
                        job_no: response.data[2],
                        loading: false,
                        h_dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
        this.setState({
            loading: false
        })
    }

    closeHistoryDialog = () => {
        this.setState({ h_dialog: false })
    }

    submitHandler = (
        page, rowsPerPage, title_no, job_no, rsp_no, ls_name, curr_stage,
        status, start_date, end_date
    ) => {
        if (title_no === '' && job_no === '' && rsp_no === '' && ls_name === '' &&
            curr_stage === '' && status === '' && start_date === '' &&
            end_date === '') {
            this.errorHandler("Please provide some value to search.")
        }

        else {
            this.setState({
                title_no: title_no,
                job_no: job_no,
                rsp_no: rsp_no,
                ls_name: ls_name,
                curr_stage: curr_stage,
                status: status,
                start_date: start_date,
                end_date: end_date
            }, () => {
                this.searchHandler(page, rowsPerPage)
            })
        }
    }

    searchHandler = (page, rowsPerPage) => {
        this.setState({ backdrop: true, alert: false })
        axios.put(Config() + '/surveyjob/cadastral/search/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                'title_no': this.state.title_no,
                'job_no': this.state.job_no,
                'rsp_no': this.state.rsp_no,
                'ls_name': this.state.ls_name,
                'curr_stage': this.state.curr_stage,
                'status': this.state.status,
                'start_date': this.state.start_date,
                'end_date': this.state.end_date
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],

                        backdrop: false,
                        searchIndicator: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goView = (form_id) => {
        sessionStorage.setItem('form_id_review', form_id)
        this.props.history.push({
            pathname: '/ui/CadastralSurveyJobReview'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogHistoryComponent
                    action={this.goView}
                    open={this.state.h_dialog}
                    onClose={this.closeHistoryDialog}
                    data={this.state.history_data}
                    job_no={this.state.job_no} />

                <HeaderComponent />
                <SearchPanelComponent
                    action={this.submitHandler}
                    action2={this.init}
                    data={this.state.select_array}
                    data2={this.state.select_array2} />
                <br />
                <TableComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                    data={this.state.data}
                    action={this.goView}
                    action2={this.viewHistoryHandler}
                    actionSort={this.sortHandler}
                    column={this.state.column}
                    direction={this.state.direction}
                    count={this.state.count}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.pageHandler}
                    onChangeRowsPerPage={this.rowHandler} />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(CadastralJobList));