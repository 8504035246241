import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericLoadTextField from '../../Util/GenericLoadTextField';
import GenericIdleTextField from '../../Util/GenericIdleTextField';
import GenericNumberTextInput from '../../Util/GenericNumberTextInput';

import Section2PopoverComponent from './Section2PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section2Component extends React.Component {

    input_1_onChangeHandler = event => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.onChange_value1(event.target.value)
        }
    }

    input_2_onChangeHandler = event => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.onChange_value2(event.target.value)
        }
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 2: Computation and / or preparation of plans (no field work)
                        </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>

                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Computation/preparation of plans (no field works).
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section2PopoverComponent />
                        </Grid>
                    </Grid>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}>
                        <Grid item xs={12} sm={4}>
                            i) &nbsp;&nbsp;No. of draughtman hour:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericNumberTextInput
                                label="Hour/s"
                                value={this.props.value1}
                                inputProps={{ maxLength: 3 }}
                                onChange={this.input_1_onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            ii) &nbsp;&nbsp;No. of computer hour:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericNumberTextInput
                                label="Hour/s"
                                value={this.props.value2}
                                inputProps={{ maxLength: 3 }}
                                onChange={this.input_2_onChangeHandler}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={3}>
                            {this.props.loading ?
                                <GenericLoadTextField
                                    label="Amount"
                                    name="section2_amount"
                                    value={this.props.valueTotal}
                                />
                                :
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section2_amount"
                                    value={this.props.valueTotal}
                                />}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);