import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import PasswordTextInput from '../Util/PasswordTextInput';
import ButtonCancel from '../Util/ButtonCancel';
import ButtonEdit from '../Util/ButtonEdit';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 150
    },
})

class GridEditComponent extends React.Component {

    submitHandler = () => {
        this.props.action(
            this.input_user_name.value,
            this.input_password.value
        )
    }

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.ls_staff_name}>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Username:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                name="input_user_name"
                                inputProps={{ maxLength: 15 }}
                                inputRef={node => (this.input_user_name = node)}
                                defaultValue={x.user_name}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Password:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <PasswordTextInput
                                name="input_password"
                                inputProps={{ maxLength: 12 }}
                                inputRef={node => (this.input_password = node)}
                            />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4}
                    className={classes.box}>

                    <ButtonEdit
                        className={classes.button}
                        onClick={this.submitHandler}
                    />

                    <ButtonCancel
                        className={classes.button}
                        onClick={this.props.action2}
                    />
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(GridEditComponent);