import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import TextInputComponent from '../Util/TextInputComponent';
import SelectInputComponent from '../Util/SelectInputComponent';
import DateInputComponent from '../Util/DateInputComponent';
import SearchButtonComponent from '../Util/SearchButtonComponent';
import ResetButtonComponent from '../Util/ResetButtonComponent';

const styles = theme => ({
    paper: {
        maxWidth: 500,
        margin: `${theme.spacing(1)}px auto`,
    },
    headerText: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    form: {
        width: '100%', //Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        paddingBottom: 0
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
    margin: {
        marginBottom: 10
    },
    box: {
        textAlign: 'center'
    }
})

class SearchPanelComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            data_status: [
                {
                    'value': 'Pending',
                    'text': 'Pending'
                },
                {
                    'value': 'Approved',
                    'text': 'Approved'
                },
                {
                    'value': 'Rejected',
                    'text': 'Rejected'
                },
            ],

            input_category: '',
            input_status: '',
            start_date: null,
            end_date: null
        }
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onChangeHandler_StartDate = date => {
        this.setState({ start_date: date })
    }

    onChangeHandler_EndDate = date => {
        this.setState({ end_date: date })
    }

    submitHandler = () => {
        let page = 0
        let rowsPerPage = 50

        var formatted_start_date = ''
        var formatted_end_date = ''

        if (this.state.start_date === null) {
            formatted_start_date = ''
        } else {
            formatted_start_date = (this.state.start_date).getFullYear() + '-' + ((this.state.start_date).getMonth() + 1) + '-' + (this.state.start_date).getDate()
        }

        if (this.state.end_date === null) {
            formatted_end_date = ''
        } else {
            formatted_end_date = (this.state.end_date).getFullYear() + '-' + ((this.state.end_date).getMonth() + 1) + '-' + (this.state.end_date).getDate()
        }

        this.props.action(
            page, rowsPerPage,
            this.state.input_category,
            this.input_description.value,
            this.state.input_status,
            formatted_start_date,
            formatted_end_date
        )
    }

    resetHandler = () => {
        this.input_description.value = ''
        this.setState({
            input_category: '',
            input_status: '',
            start_date: null,
            end_date: null
        })

        this.props.action2()
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.headerText}>Search Panel</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <form className={classes.form}>
                            <SelectInputComponent className={classes.margin}
                            name="input_category"
                            label="Filter by Category"
                            value={this.state.input_category}
                            data={this.props.data}
                            onChange={this.onChangeHandler}
                            />

                            <TextInputComponent className={classes.margin}
                            label="Filter by Description"
                            inputRef={node => (this.input_description = node)}
                            inputProps={{ maxLength: 200 }}
                            />
{/* 
                            <SelectInputComponent className={classes.margin}
                            name="input_status"
                            label="Filter by Status"
                            value={this.state.input_status}
                            data={this.state.data_status}
                            onChange={this.onChangeHandler}
                            /> */}

                            <Grid container spacing={2} className={classes.margin}>
                                <Grid item xs={12} md={5}>
                                    <DateInputComponent
                                    label="Start Date (From)"
                                    value={this.state.start_date}
                                    onChange={this.onChangeHandler_StartDate}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>

                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <DateInputComponent
                                    label="Start Date (To)"
                                    value={this.state.end_date}
                                    onChange={this.onChangeHandler_EndDate}
                                    />
                                </Grid>
                            </Grid>

                            <Box pt={4} className={classes.box}>
                                <SearchButtonComponent className={classes.button}
                                onClick={this.submitHandler} />
                                <ResetButtonComponent className={classes.button}
                                onClick={this.resetHandler} />
                            </Box>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Paper >
        )
    }
}

export default withStyles(styles, { withTheme: true })(SearchPanelComponent);