import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    }
})

class LSStaffView extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],

            backdrop: false,
            viewState: true,
            viewState_2: true
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.put(Config() + '/lsstaff/view/get',
            {
                'user_id': sessionStorage.getItem('ls_staff_no_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState,
            alert: false,
            error_msg: ''
        })
    }

    viewState_2Handler = () => {
        this.setState({
            viewState_2: !this.state.viewState_2,
            alert: false,
            error_msg: ''
        })
    }

    goBack = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/LSStaffList'
        })
    }

    editHandler = (
        lsstaff_status, ls_staff_name, email_1, email_2, contact_no, designation
    ) => {
        axios.put(Config() + '/lsstaff/update/edit',
            {
                'user_id': sessionStorage.getItem('ls_staff_no_view'),
                'lsstaff_status': lsstaff_status,
                'full_name': ls_staff_name,
                'email_1': email_1,
                'email_2': email_2,
                'contact_no': contact_no,
                'designation': designation,
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    editHandler_2 = (
        user_name, password
    ) => {
        if (user_name === '' || password === '') {
            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            axios.put(Config() + '/lsstaff/update/edit/account',
                {
                    'user_id': sessionStorage.getItem('ls_staff_no_view'),
                    'user_name': user_name,
                    'password': password,
                },
                {
                    withCredentials: true
                })
                .then(response => {
                    if (response.data['result'] === "Success") {
                        this.init()
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        this.errorHandler("Code issue probably.")
                    }
                    else {
                        if (ResponseStatusCheck(error)) {
                            //If Session Expired
                            if (ResponseStatusCheck(error) === 401) {
                                console.log("Display some window here.")
                            }
                            else {
                                this.errorHandler(ResponseStatusCheck(error))
                            }
                        }
                        else {
                            this.errorHandler("Something went very wrong!")
                        }
                    }
                })
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <FormComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                    viewState={this.state.viewState}
                    viewState_2={this.state.viewState_2}
                    data={this.state.data}
                    action={this.viewStateHandler}
                    action2={this.goBack}
                    action3={this.editHandler}
                    action4={this.viewStateHandler}
                    action5={this.viewState_2Handler}
                    action6={this.editHandler_2}
                    action7={this.viewState_2Handler} />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(LSStaffView));