import React from 'react';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

class DateInputComponent extends React.Component {
    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    inputVariant="outlined"
                    margin="normal"
                    fullWidth
                    label={this.props.label}
                    format="dd/MM/yyyy"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        )
    }
}

export default DateInputComponent;