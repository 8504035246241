import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../../Util/GenericTextInput';
import SelectInputComponent from '../../../Util/SelectInputComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <React.Fragment>
                        <br />
                        <br />
                        <Grid container
                            direction="row"
                            spacing={3}
                            className={this.props.className}
                            key={x.claim_id}>

                            <Grid item xs={12} sm={4}>
                                Client Name:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.name}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                Client Address 1:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.addr1}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                Client Address 2:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.addr2}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                Client Address 3:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.addr3}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                Client Address 4:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.addr4}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                Client Bank:
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.client_bank}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                Client Bank Account No. (If Any):
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <b>{x.client_acct_no}</b>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>

                        </Grid>

                        < Box pt={4} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                className={this.props.classNameButton}
                                onClick={this.props.onClick}
                            >
                                Edit
                            </Button>
                        </Box >
                    </React.Fragment>
                ))}
            </div>
        )
    }
}

class Grid1ComponentEdit extends React.Component {
    constructor() {
        super()
        this.state = {
            input_client_bank: ''
        }
    }

    componentDidMount() {
        this.setState({
            input_client_bank: this.props.data[0]['client_bank']
        })
    }

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {
        this.props.action(
            this.input_name.value,
            this.input_addr1.value,
            this.input_addr2.value,
            this.input_addr3.value,
            this.input_addr4.value,
            this.state.input_client_bank,
            this.input_client_acct_no.value
        )
    }

    errorHandler = (value) => {
        this.setState({
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.claim_id}>

                        <Grid item xs={12} sm={4}>
                            Client Name:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_name"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_name = node)}
                                defaultValue={x.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Client Address Line 1:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_addr1"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr1 = node)}
                                defaultValue={x.addr1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Client Address Line 2:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_addr2"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr2 = node)}
                                defaultValue={x.addr2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Client Address Line 3:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_addr3"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr3 = node)}
                                defaultValue={x.addr3}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Client Address Line 4:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_addr4"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr4 = node)}
                                defaultValue={x.addr4}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Client Bank:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectInputComponent
                                variant="outlined"
                                name="input_client_bank"
                                value={this.state.input_client_bank}
                                onChange={this.onChangeHandler}
                                data={this.props.swift_code_data}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Client Bank Account No. (If Any):
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_client_acct_no"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_client_acct_no = node)}
                                defaultValue={x.client_acct_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonLoadComponent
                            color="primary"
                            className={this.props.classNameButton}
                            name="Updating..."
                        />

                        <ButtonLoadComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            name="..."
                        />
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonIdleComponent
                            color="primary"
                            className={this.props.classNameButton}
                            onClick={this.submitHandler}
                            name="Update"
                        />
                        <ButtonIdleComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}
                            name="Cancel"
                        />
                    </Box>
                }
            </div>
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color={this.props.color}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.name}
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                {this.props.name}
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class Section2Component extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        name, addr1, addr2, addr3, addr4, client_bank, client_acct_no
    ) => {

        this.props.action(
            name, addr1, addr2, addr3, addr4, client_bank, client_acct_no
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Refund to Client (Fill in by LJS Staff)
                    </Typography>
                </AccordionSummary>
                <Divider />

                {this.state.viewState === true ?
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            data={this.props.data}
                            classNameButton={classes.button}
                            onClick={this.viewStateHandler} />
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1ComponentEdit
                        className={classes.padding}
                        data={this.props.data}
                        swift_code_data={this.props.swift_code_data}
                        classNameButton={classes.button}
                        loading={this.props.loading}
                        action={this.submitHandler}
                        onClick={this.viewStateHandler}
                        />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);