import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../Util/GenericTextInput';
import DateInputComponent from '../../Util/DateInputComponent';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class ESPDialogComponent extends React.Component {
    render() {
        return (
            <Dialog 
            onClose={this.props.onClose}
            open={this.props.open}>
                
                <DialogTitle>
                    Add ESP No.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type in ESP No. and the related date for the selected Survey Job Application.
                    </DialogContentText>
                    
                    <GenericTextInput
                    label="ESP Number"
                    //ESP Number has 11 digits
                    inputProps={{ maxLength: 11 }}
                    inputRef={this.props.inputRef}
                    />

                    <DateInputComponent
                    label="ESP Date"
                    name="input_esp_date"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    error={this.props.error}
                    />

                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={this.props.onClick}
                    className={this.props.className}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class DialogComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_esp_date: null,

            input_error: false
        }
    }

    onChangeHandler = date => {
        this.setState({ input_esp_date: date })
    }

    submitHandler = () => {
        if (this.input_esp_no.value === '' ||
            this.state.input_esp_date === null) {

                this.setState({ input_error: true })
            }

        else {
            var formatted_esp_date = (this.state.input_esp_date).getDate() + '/' + ((this.state.input_esp_date).getMonth() + 1) + '/' + (this.state.input_esp_date).getFullYear()
            this.props.action(
                this.input_esp_no.value,
                formatted_esp_date
            )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <ESPDialogComponent
                onClose={this.props.onClose}
                open={this.props.open}
                onChange={this.onChangeHandler}
                inputRef={node => (this.input_esp_no = node)}
                value={this.state.input_esp_date}
                onClick={this.submitHandler}
                error={this.state.input_error}
                className={classes.button}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);