import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

import debounce from 'lodash.debounce';
import axios from 'axios';

import { Config } from '../../../../../../Config';

import AlertComponent from './AlertComponent';
import Section1Component from './Section1Component';
import Section2Component from './Section2Component';
import Section3Component from './Section3Component';
import Section4Component from './Section4Component';
import SectionTotalComponent from './SectionTotalComponent';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="primary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Next
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary"
                fullWidth type="submit"
                disabled
                className={this.props.className}
            >
                Loading... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="secondary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Back
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="secondary"
                fullWidth type="submit"
                disabled
                className={this.props.className}
            >
                Loading... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            section1_checkbox: false,
            section1_amount: (0).toFixed(2),
            section1_loading: false,

            section2_input_1: [],
            section2_input_2: [],
            section2_select: [],
            section2_array_amount: [],
            section2_array_loading: [],
            section2_amount: (0).toFixed(2),
            section2_loading: false,

            section3_select: '',
            section3_amount: (0).toFixed(2),
            section3_loading: false,

            section4_a_checkbox: false,
            section4_a_amount: (0).toFixed(2),
            section4_a_loading: false,

            section4_b_checkbox: false,
            section4_b_amount: (0).toFixed(2),
            section4_b_loading: false,

            section4_c_input: '',
            section4_c_amount: (0).toFixed(2),
            section4_c_loading: false,
        }
        this.section1_checkbox_onChangeDebounced = debounce(this.section1_checkbox_onChangeDebounced, 1500)
        this.section2_input_2_onChangeDebounced = debounce(this.section2_input_2_onChangeDebounced, 1500)
        this.section2_select_onChangeDebounced = debounce(this.section2_select_onChangeDebounced, 1500)
        this.section3_select_onChangeDebounced = debounce(this.section3_select_onChangeDebounced, 1500)
        this.section4_a_checkbox_onChangeDebounced = debounce(this.section4_a_checkbox_onChangeDebounced, 1500)
        this.section4_b_checkbox_onChangeDebounced = debounce(this.section4_b_checkbox_onChangeDebounced, 1500)
        this.section4_c_input_onChangeDebounced = debounce(this.section4_c_input_onChangeDebounced, 1500)
    }

    section1_checkbox_onChangeHandler = (value) => {
        this.setState({
            section1_checkbox: value,
            section1_loading: true
        })

        this.section1_checkbox_onChangeDebounced(value)
    }

    section1_checkbox_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': 1,
                'value': value
            })
            .then(response => (
                this.setState({
                    section1_amount: response.data.toFixed(2),
                    section1_loading: false
                }, () => {
                    this.section4_a_checkbox_UpdateHandler()
                })
            ))
    }

    section2_addArray = () => {
        this.setState({
            section2_input_1: [...this.state.section2_input_1, ''],
            section2_input_2: [...this.state.section2_input_2, ''],
            section2_select: [...this.state.section2_select, ''],
            section2_array_loading: [...this.state.section2_array_loading, false],
            section2_array_amount: [...this.state.section2_array_amount, '0.00']
        })
    }

    section2_removeArray = (i) => {
        let section2_input_1 = [...this.state.section2_input_1]
        let section2_input_2 = [...this.state.section2_input_2]
        let section2_select = [...this.state.section2_select]
        let section2_array_loading = [...this.state.section2_array_loading]
        let section2_array_amount = [...this.state.section2_array_amount]
        section2_input_1.splice(i, 1)
        section2_input_2.splice(i, 1)
        section2_select.splice(i, 1)
        section2_array_loading.splice(i, 1)
        section2_array_amount.splice(i, 1)
        this.setState({
            section2_input_1, section2_input_2, section2_select,
            section2_array_loading, section2_array_amount
        })

        let total = section2_array_amount.reduce((prev, next) =>
            (Number(prev) + Number(next)).toFixed(2), 0)
        this.setState({ section2_amount: total }, () => {
            this.section3_select_UpdateHandler()
        })
    }

    section2_input_1_onChangeHandler = (value, i) => {
        let section2_input_1 = [...this.state.section2_input_1]
        section2_input_1[i] = value
        this.setState({ section2_input_1 })
    }

    section2_input_2_onChangeHandler = (value, i) => {
        let section2_input_2 = [...this.state.section2_input_2]
        let section2_array_loading = [...this.state.section2_array_loading]

        section2_input_2[i] = value
        section2_array_loading[i] = true
        this.setState({
            section2_input_2, section2_array_loading,
            section2_loading: true
        })

        this.section2_input_2_onChangeDebounced(value, i)
    }

    section2_input_2_onChangeDebounced = (value, i) => {
        let section2_array_loading = [...this.state.section2_array_loading]
        let section2_array_amount = [...this.state.section2_array_amount]

        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': 2,
                'value_1': value,
                'value_2': this.state.section2_select[i]
            })
            .then(response => {
                section2_array_amount[i] = response.data.toFixed(2)
                section2_array_loading[i] = false
                this.setState({ section2_array_amount, section2_array_loading })

                let total = this.state.section2_array_amount.reduce((prev, next) =>
                    (Number(prev) + Number(next)).toFixed(2))
                this.setState({
                    section2_amount: total,
                    section2_loading: false
                }, () => {
                    this.section3_select_UpdateHandler()
                })
            })
    }

    section2_select_onChangeHandler = (value, i) => {
        let section2_select = [...this.state.section2_select]
        let section2_array_loading = [...this.state.section2_array_loading]

        section2_select[i] = value
        section2_array_loading[i] = true
        this.setState({
            section2_select, section2_array_loading,
            section2_loading: true
        })

        this.section2_select_onChangeDebounced(value, i)
    }

    section2_select_onChangeDebounced = (value, i) => {
        let section2_array_loading = [...this.state.section2_array_loading]
        let section2_array_amount = [...this.state.section2_array_amount]

        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': 2,
                'value_1': this.state.section2_input_2[i],
                'value_2': value
            })
            .then(response => {
                section2_array_amount[i] = response.data.toFixed(2)
                section2_array_loading[i] = false
                this.setState({ section2_array_amount, section2_array_loading })

                let total = this.state.section2_array_amount.reduce((prev, next) =>
                    (Number(prev) + Number(next)).toFixed(2))
                this.setState({
                    section2_amount: total,
                    section2_loading: false
                }, () => {
                    this.section3_select_UpdateHandler()
                })
            })
    }

    section3_select_onChangeHandler = (value) => {
        this.setState({
            section3_select: value,
            section3_loading: true
        })

        this.section3_select_onChangeDebounced(value)
    }

    section3_select_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': 3,
                'value': value,
                'count_1': this.state.section2_amount
            })
            .then(response => (
                this.setState({
                    section3_amount: response.data.toFixed(2),
                    section3_loading: false
                }, () => {
                    this.section4_a_checkbox_UpdateHandler()
                })
            ))
    }

    section4_a_checkbox_onChangeHandler = (value) => {
        this.setState({
            section4_a_checkbox: value,
            section4_a_loading: true
        })

        this.section4_a_checkbox_onChangeDebounced(value)
    }

    section4_a_checkbox_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': '4_a',
                'value': value,
                'count_1': this.state.section1_amount,
                'count_2': this.state.section2_amount,
                'count_3': this.state.section3_amount
            })
            .then(response => (
                this.setState({
                    section4_a_amount: response.data.toFixed(2),
                    section4_a_loading: false
                }, () => {
                    this.total_ChangeHandler()
                })
            ))
    }

    section4_b_checkbox_onChangeHandler = (value) => {
        this.setState({
            section4_b_checkbox: value,
            section4_b_loading: true
        })

        this.section4_b_checkbox_onChangeDebounced(value)
    }

    section4_b_checkbox_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': '4_b',
                'value': value,
                'count_1': this.state.section1_amount,
                'count_2': this.state.section2_amount,
                'count_3': this.state.section3_amount
            })
            .then(response => (
                this.setState({
                    section4_b_amount: response.data.toFixed(2),
                    section4_b_loading: false
                }, () => {
                    this.total_ChangeHandler()
                })
            ))
    }

    section4_c_input_onChangeHandler = (value) => {
        this.setState({
            section4_c_input: value,
            section4_c_loading: true
        })

        this.section4_c_input_onChangeDebounced(value)
    }

    section4_c_input_onChangeDebounced = (value) => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': '4_c',
                'value': value
            })
            .then(response => {
                this.setState({
                    section4_c_amount: response.data.toFixed(2),
                    section4_c_loading: false
                }, () => {
                    this.total_ChangeHandler()
                })
            })
    }

    //for updating value when the required field has changed
    section3_select_UpdateHandler = () => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': 3,
                'value': this.state.section3_select,
                'count_1': this.state.section2_amount
            })
            .then(response => {
                this.setState({
                    section3_amount: response.data.toFixed(2)
                }, () => {
                    this.total_ChangeHandler()
                    this.section4_a_checkbox_UpdateHandler()
                })
            })
    }

    section4_a_checkbox_UpdateHandler = () => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': '4_a',
                'value': this.state.section4_a_checkbox,
                'count_1': this.state.section1_amount,
                'count_2': this.state.section2_amount,
                'count_3': this.state.section3_amount
            })
            .then(response => {
                this.setState({
                    section4_a_amount: response.data.toFixed(2)
                }, () => {
                    this.total_ChangeHandler()
                    this.section4_b_checkbox_UpdateHandler()
                })
            })
    }

    section4_b_checkbox_UpdateHandler = event => {
        axios.put(Config() + '/calculator',
            {
                'table': 6,
                'section': '4_b',
                'value': this.state.section4_b_checkbox,
                'count_1': this.state.section1_amount,
                'count_2': this.state.section2_amount,
                'count_3': this.state.section3_amount
            })
            .then(response => {
                this.setState({
                    section4_b_amount: response.data.toFixed(2)
                }, () => {
                    this.total_ChangeHandler()
                })
            })
    }

    total_ChangeHandler = () => {
        var total = (Number(this.state.section1_amount) + Number(this.state.section2_amount) +
            Number(this.state.section3_amount) + Number(this.state.section4_a_amount) +
            Number(this.state.section4_b_amount) + Number(this.state.section4_c_amount))

        this.setState({
            total_amount: total.toFixed(2),
            loading: false
        })
    }

    nextButtonHandler = () => {
        this.setState({ loading: true })
        this.props.action(
            this.state.section1_checkbox,
            this.state.section2_input_1,
            this.state.section2_input_2,
            this.state.section2_select,
            this.state.section3_select,
            this.state.section4_a_checkbox,
            this.state.section4_b_checkbox,
            this.state.section4_c_input,
            this.state.total_amount
        )
    }

    getData = (
        section1_checkbox,
        section2_input_1,
        section2_input_2,
        section2_select,
        section3_select,
        section4_a_checkbox,
        section4_b_checkbox,
        section4_c_input,
        section1_amount,
        section2_array_amount,
        section2_amount,
        section3_amount,
        section4_a_amount,
        section4_b_amount,
        section4_c_amount) => {

        this.setState({
            section1_checkbox: section1_checkbox,
            section2_input_1: section2_input_1,
            section2_input_2: section2_input_2,
            section2_select: section2_select,
            section3_select: section3_select,
            section4_a_checkbox: section4_a_checkbox,
            section4_b_checkbox: section4_b_checkbox,
            section4_c_input: section4_c_input,
            section1_amount: section1_amount,
            section2_array_amount: section2_array_amount,
            section2_amount: section2_amount,
            section3_amount: section3_amount,
            section4_a_amount: section4_a_amount,
            section4_b_amount: section4_b_amount,
            section4_c_amount: section4_c_amount
        }, () => {
            this.total_ChangeHandler()
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Section1Component
                        value={this.state.section1_checkbox}
                        onChange={this.section1_checkbox_onChangeHandler}
                        valueTotal={this.state.section1_amount}
                        loading={this.state.section1_loading}
                    />

                    <Section2Component
                        array={this.state.section2_input_1}
                        onChange_value1={this.section2_input_1_onChangeHandler}
                        onChange_value2={this.section2_input_2_onChangeHandler}
                        onChange_value3={this.section2_select_onChangeHandler}
                        action1={this.section2_addArray}
                        action2={this.section2_removeArray}
                        value1={this.state.section2_input_1}
                        value2={this.state.section2_input_2}
                        value3={this.state.section2_select}
                        arrayLoading={this.state.section2_array_loading}
                        arrayAmount={this.state.section2_array_amount}
                        loading={this.state.section2_loading}
                        valueTotal={this.state.section2_amount}
                    />

                    <Section3Component
                        value={this.state.section3_select}
                        onChange={this.section3_select_onChangeHandler}
                        valueTotal={this.state.section3_amount}
                        loading={this.state.section3_loading}
                    />

                    <Section4Component
                        onChange_value1={this.section4_a_checkbox_onChangeHandler}
                        onChange_value2={this.section4_b_checkbox_onChangeHandler}
                        onChange_value3={this.section4_c_input_onChangeHandler}
                        value1={this.state.section4_a_checkbox}
                        value2={this.state.section4_b_checkbox}
                        value3={this.state.section4_c_input}
                        loading1={this.state.section4_a_loading}
                        loading2={this.state.section4_b_loading}
                        loading3={this.state.section4_c_loading}
                        valueTotal1={this.state.section4_a_amount}
                        valueTotal2={this.state.section4_b_amount}
                        valueTotal3={this.state.section4_c_amount}
                    />

                    <SectionTotalComponent
                        value={this.state.total_amount}
                        loading={this.state.loading}
                    />

                </Paper>

                {this.state.loading ?

                    <Box pt={4} className={classes.box}>
                        <NextLoadButton className={classes.button} />
                        <BackLoadButton className={classes.button} />
                    </Box>
                    :

                    <Box pt={4} className={classes.box}>
                        <NextIdleButton
                            className={classes.button}
                            onClick={this.nextButtonHandler} />
                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action2} />
                    </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);