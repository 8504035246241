import React from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';

import { withStyles } from '@material-ui/core/styles';

import AlertComponent from './AlertComponent';
import Section1Component from './Section1Component';
import SectionTotalComponent from './SectionTotalComponent';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="primary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Next
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary"
                fullWidth type="submit"
                disabled
                className={this.props.className}
            >
                Loading... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button variant="contained"
                color="secondary"
                onClick={this.props.onClick}
                className={this.props.className}>
                Back
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="secondary"
                fullWidth type="submit"
                disabled
                className={this.props.className}
            >
                Loading... &nbsp;&nbsp;&nbsp;
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            checkbox: false,
            input_1: [],
            input_2: [],
            input_3: [],
            input_4: [],
            input_5: [],

            total_amount: (0).toFixed(2),

            error_msg: ''
        }
    }

    checkbox_onChangeHandler = (value) => {
        if (value === true) {
            this.setState({
                checkbox: true,
                loading: true
            })
            this.setState((prevState) => ({
                input_1: ['Basic Charge', ...prevState.input_1],
                input_2: ['-', ...prevState.input_2],
                input_3: ['-', ...prevState.input_3],
                input_4: ['-', ...prevState.input_4],
                input_5: ['1300.00', ...prevState.input_5]
            }), () => {
                this.total_ChangeHandler()
            })
        }
        else {
            this.setState({ checkbox: false })
            this.removeArray(0)
        }
    }

    addArray = () => {
        this.setState({
            input_1: [...this.state.input_1, ''],
            input_2: [...this.state.input_2, ''],
            input_3: [...this.state.input_3, ''],
            input_4: [...this.state.input_4, ''],
            input_5: [...this.state.input_5, '']
        })
    }

    removeArray = (i) => {
        let input_1 = [...this.state.input_1]
        let input_2 = [...this.state.input_2]
        let input_3 = [...this.state.input_3]
        let input_4 = [...this.state.input_4]
        let input_5 = [...this.state.input_5]
        input_1.splice(i, 1)
        input_2.splice(i, 1)
        input_3.splice(i, 1)
        input_4.splice(i, 1)
        input_5.splice(i, 1)
        this.setState({
            input_1, input_2, input_3, input_4, input_5
        }, () => {
            this.total_ChangeHandler()
        })
    }

    input_1_onChangeHandler = (value, i) => {
        let input_1 = [...this.state.input_1]
        input_1[i] = value
        this.setState({ input_1 })
    }

    input_2_onChangeHandler = (value, i) => {
        let input_2 = [...this.state.input_2]
        input_2[i] = value
        this.setState({ input_2 })
    }

    input_3_onChangeHandler = (value, i) => {
        let input_3 = [...this.state.input_3]
        input_3[i] = value
        this.setState({ input_3 })
    }

    input_4_onChangeHandler = (value, i) => {
        let input_4 = [...this.state.input_4]
        input_4[i] = value
        this.setState({ input_4 })
    }

    input_5_onChangeHandler = (value, i) => {
        let input_5 = [...this.state.input_5]
        input_5[i] = value
        this.setState({ input_5 }, () => {
            this.total_ChangeHandler()
        })
    }

    total_ChangeHandler = () => {
        var total = this.state.input_5.reduce((prev, next) =>
            (Number(prev) + Number(next)), 0)

        this.setState({
            total_amount: total.toFixed(2),
            loading: false
        })
    }

    nextButtonHandler = () => {
        this.setState({ loading: true })
        if (!this.state.input_1.length || !this.state.input_2.length ||
            !this.state.input_3.length || !this.state.input_4.length ||
            !this.state.input_5.length) {

            this.setState({
                loading: false,
                alert: true,
                error_msg: 'Please submit some data!'
            })
        }

        else {
            this.props.action(
                this.state.input_1,
                this.state.input_2,
                this.state.input_3,
                this.state.input_4,
                this.state.input_5,
                this.state.total_amount
            )
        }
    }

    getData = (
        input_1,
        input_2,
        input_3,
        input_4,
        input_5) => {

        this.setState({
            input_1: input_1,
            input_2: input_2,
            input_3: input_3,
            input_4: input_4,
            input_5: input_5
        }, () => {
            this.total_ChangeHandler()
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <Section1Component
                        checkbox={this.state.checkbox}
                        input_1={this.state.input_1}
                        input_2={this.state.input_2}
                        input_3={this.state.input_3}
                        input_4={this.state.input_4}
                        input_5={this.state.input_5}
                        action1={this.removeArray}
                        onChange_checkbox={this.checkbox_onChangeHandler}
                        onChange_value1={this.input_1_onChangeHandler}
                        onChange_value2={this.input_2_onChangeHandler}
                        onChange_value3={this.input_3_onChangeHandler}
                        onChange_value4={this.input_4_onChangeHandler}
                        onChange_value5={this.input_5_onChangeHandler} />

                    <IconButton variant="contained" color="primary" className={classes.roundedButton}
                        onClick={this.addArray}
                        disabled={this.state.view_state === "ViewOnly" ? true : false}
                    >
                        <AddIcon />
                    </IconButton>

                    <SectionTotalComponent
                        value={this.state.total_amount}
                        loading={this.state.loading}
                    />

                </Paper>

                {this.state.loading ?

                    <Box pt={4} className={classes.box}>
                        <NextLoadButton className={classes.button} />
                        <BackLoadButton className={classes.button} />
                    </Box>
                    :

                    <Box pt={4} className={classes.box}>
                        <NextIdleButton
                            className={classes.button}
                            onClick={this.nextButtonHandler} />
                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action2} />
                    </Box>
                }

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);