import React from 'react';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import Section1Component from './Section1Component';
import Section2Component from './Section2Component';
import Review1Component from './Review1Component';
import File1Component from './File1Component';
import Section3Component from './Section3Component';
import Review2Component from './Review2Component';
import File2Component from './File2Component';

const styles = theme => ({
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class DataComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <br />
                <Divider />
                <br />

                <Paper elevation={3}
                    className={classes.paper}>

                    <Section1Component
                        data={this.props.section1_data}
                        file={this.props.section1_file_data}
                    />

                    <Section2Component
                        section1_data={this.props.section1_data}
                        data={this.props.section2_data}
                        charge_percent={this.props.charge_percent}
                        loading={this.props.loading}
                        action={this.props.action}
                    />

                    <br />
                    <br />
                    <br />

                    <Review1Component
                        data={this.props.section1_data}
                    />

                    <File1Component
                        data={this.props.invoice_file_data}
                        loading={this.props.loading}
                        action={this.props.action2} />

                    {this.props.section3_data.length ?
                        <Section3Component
                            data={this.props.section3_data}
                            file={this.props.section3_file_data}
                        />
                        :
                        null
                    }

                    {this.props.section3_data.length ?
                        <Review2Component
                            data={this.props.section3_data}
                        />
                        :
                        null
                    }

                    {this.props.section3_data.length ?
                        <File2Component
                            data={this.props.receipt_file_data}
                            loading={this.props.loading}
                            action={this.props.action3} />
                        :
                        null
                    }
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DataComponent);