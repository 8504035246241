import React from 'react';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    }
})

class GenericRequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

class PasswordRequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                type="password"
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
            />
        )
    }
}

class SelectTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                select
                label={this.props.label}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
            >

                {this.props.data.map(x => (
                    <MenuItem key={x.key}
                        value={x.value}
                        style={{ witeSpace: 'normal' }}>
                        {x.text}
                    </MenuItem>
                ))}
            </TextField>
        )
    }
}

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Register
            </Button>
        )
    }
}

class CancelIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Cancel
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class CancelLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_ls_name: '',
        }
    }

    onChangeHandler = event => {
        this.setState({
            input_ls_name: event.target.value
        })
        this.props.action2(event.target.value)
    }

    submitHandler = () => {
        this.props.action(
            this.state.input_ls_name,
            this.input_login_name.value,
            this.input_password.value
        )
    }

    selecterrorHandler = (response) => {
        this.setState({
            error_ls_name: response
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }


    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                <form noValidate>

                    {this.state.alert ?
                        <Alert severity="error">
                            {this.state.error_msg}
                        </Alert>
                        :
                        null}

                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectTextInput
                                error={this.state.error_ls_name}
                                helperText={this.state.error_ls_name ? "The selected licensed surveyor already has a registered account." : null}
                                label="Please choose an option:"
                                name="input_ls_name"
                                value={this.state.input_ls_name}
                                onChange={this.onChangeHandler}
                                data={this.props.data}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericRequiredTextInput
                                error={this.state.error_login_name}
                                label="Login Name"
                                inputProps={{ maxLength: 15 }}
                                inputRef={node => (this.input_login_name = node)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PasswordRequiredTextInput
                                error={this.state.error_password}
                                label="Login Password"
                                inputProps={{ maxLength: 12 }}
                                inputRef={node => (this.input_password = node)}
                            />
                        </Grid>
                    </Grid>

                    {this.state.loading ?

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextLoadButton className={classes.button} />
                            <CancelLoadButton className={classes.button} />
                        </Box>

                        :

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextIdleButton className={classes.button}
                                onClick={this.submitHandler} />
                            <CancelIdleButton className={classes.button} />
                        </Box>
                    }
                </form>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);