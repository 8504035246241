import React from 'react';

import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const styles = theme => ({
    paper: {
        maxWidth: 1200,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    tableButton: {
        fontWeight: 'bold',
        color: localStorage.getItem('theme') === 'dark' ?
            theme.palette.getContrastText("#bb86fc") :
            theme.palette.getContrastText(colors.purple[500]),

        backgroundColor: localStorage.getItem('theme') === 'dark' ?
            "#bb86fc" :
            colors.purple[500],
        '&:hover': localStorage.getItem('theme') === 'dark' ?
            {
                backgroundColor: "#9370db"
            } :
            {
                backgroundColor: colors.purple[700]
            },
        minWidth: 80
    }
})

class TableComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            cellContent: [
                {
                    id: '13A',
                    name: "TABLE 13A",
                    label: "Site Survey"
                },
                {
                    id: '13B',
                    name: "TABLE 13B",
                    label: "Road, Railway and Pipeline Survey"
                },
                {
                    id: '13C',
                    name: "TABLE 13C",
                    label: "Waterways, Drainage and Irrigation Survey"
                },
                {
                    id: '13D',
                    name: "TABLE 13D",
                    label: "Transmission Line Survey"
                },
                {
                    id: '13E',
                    name: "TABLE 13E",
                    label: "Hydrographic Survey for Territorial Waters"
                },
                {
                    id: '13F',
                    name: "TABLE 13F",
                    label: "Aerial / LIDAR Mapping"
                },
                {
                    id: '13G',
                    name: "TABLE 13G",
                    label: "Underground Utility Mapping"
                },
                {
                    id: '14',
                    name: "TABLE 14",
                    label: "Disbursement"
                },
                {
                    id: '15',
                    name: "ADJUSTMENT TABLE",
                    label: "For Survey Fees Adjustment"
                }
            ]
        }
    }
    //sorting function for table
    sortHandler = (clickedColumn) => () => {
        const { column, cellContent, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                contentCell: _.sortBy(cellContent, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            contentCell: cellContent.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    render() {
        const { classes } = this.props
        const { column, direction, cellContent } = this.state

        const cellHeader = [
            {
                id: "table_name",
                label: "Table Name",
            },
            {
                id: "table_desc",
                label: "Table Description"
            },
        ]

        return (
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table>

                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell
                                    key={cellHeader.id}
                                    padding="default"
                                    align="center"
                                    sortDirection={column === cellHeader.id ? direction : null}
                                    >
                                        <TableSortLabel
                                            active={column === cellHeader.id}
                                            direction={direction}
                                            onClick={this.sortHandler(cellHeader.id)}
                                        >
                                            {cellHeader.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                
                                <TableCell
                                padding="default"
                                align="center"
                                >
                                    Action
                                </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {cellContent.map(cellContent => (
                                <TableRow key={cellContent.id}>
                                    <TableCell align="center" style={{ width: '30%'}}>
                                        {cellContent.name}
                                    </TableCell>
                                    <TableCell align="center" style={{ width: '50%'}}>
                                        {cellContent.label}
                                    </TableCell>
                                    <TableCell align="center" style={{ width: '20%'}}>
                                        <Button className={classes.tableButton}
                                        onClick={() => this.props.action(cellContent.id)}
                                        >
                                            Select
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);