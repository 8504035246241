import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            className={this.props.className}
            onClick={this.props.onClick}
            >
                Next
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="secondary"
            className={this.props.className}
            onClick={this.props.onClick}
            >
                {this.props.label}
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            disabled
            className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="secondary"
            disabled
            className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {this.props.loading ?
                <Box pt={4} className={classes.box}>
                    <NextLoadButton className={classes.button} />
                    <BackLoadButton className={classes.button}/>
                    <BackLoadButton className={classes.button} />
                </Box>
                :
                <Box pt={4} className={classes.box}>
                    <NextIdleButton 
                    className={classes.button}
                    onClick={this.props.action} />
                    <BackIdleButton
                    className={classes.button}
                    onClick={this.props.action2}
                    label="Back to Survey Fee Table List" />
                    <BackIdleButton
                    className={classes.button}
                    onClick={this.props.action3}
                    label="Go to Final Summary" />
                </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);