import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    }
})

class CPDForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            category_array: [],

            //dialog
            dialog: false,

            backdrop: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        axios.get(Config() + '/ls_cpd/application/initialize',
            {
                withCredentials: true
            })
            .then(response => {
                this.setState({
                    category_array: response.data
                })

                //check if this is a new form or existing form
                if (sessionStorage.getItem('cpd_id') !== 'new') {
                    this.getFormData()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    getFormData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_cpd/application/get',
        {
            'cpd_id': sessionStorage.getItem('cpd_id')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.ComponentUI.current.initializeHandler(response.data[1])
            }
            this.setState({ backdrop: false })
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    addData = (
        category, description, organizer, venue,
        start_date, end_date, points,
        file_object, file_name
    ) => {
        //LS Staff route added on 13/10/2021
        if (localStorage.getItem('perm') === "2") {
            var url = Config() + '/ls_cpd/application/ls_staff/add'
        }
        else {
            url = Config() + '/ls_cpd/application/add'
        }
        axios.post(url,
            {
                'cpd_id': sessionStorage.getItem('cpd_id'),
                'category': category,
                'description': description,
                'organizer': organizer,
                'venue': venue,
                'start_date': start_date,
                'end_date': end_date,
                'points': points
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.addFile(response.data[1],
                        file_object, file_name)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    addFile = (
        cpd_id,
        file_object, file_name
    ) => {
        const data = new FormData()

        for (let i = 0; i < file_object.length; i++) {
            data.append('file_object', file_object[i])
            data.append('file_name', file_name[i])
        }

        data.append('cpd_id', cpd_id)

        axios.post(Config() + '/ls_cpd/application/add/file',
            data,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    goNext = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/CPDList'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                onClose={this.closeDialog}
                open={this.state.dialog}
                onClick={this.goNext} />

                <HeaderComponent />
                <FormComponent
                    data={this.state.category_array}
                    action={this.addData}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CPDForm));