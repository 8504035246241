import React from 'react'

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core'

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000
    },
    tablecell: {
        fontWeight: 'bold',
        width: '20%'
    },
    colorButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        color: localStorage.getItem('theme') === 'dark' ?
            theme.palette.getContrastText("#bb86fc") :
            theme.palette.getContrastText(colors.purple[500]),

        backgroundColor: localStorage.getItem('theme') === 'dark' ?
            "#bb86fc" :
            colors.purple[500],
        '&:hover': localStorage.getItem('theme') === 'dark' ?
            {
                backgroundColor: "#9370db"
            } :
            {
                backgroundColor: colors.purple[700]
            },
        minWidth: 80
    }
})

class TableComponent extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    sortHandler = (clickedColumn) => () => {
        this.props.actionSort(clickedColumn)
    }

    onChangePage = (event, newPage) => {
        this.props.onChangePage(newPage)
    }

    onChangeRowsPerPage = (event) => {
        this.props.onChangeRowsPerPage(event.target.value)
    }

    render() {
        const { classes } = this.props

        const cellHeader = [
            {
                id: "land_title",
                label: "P.T./Title No.",
            },
            {
                id: "job_no",
                label: "LJS No.",
            },
            {
                id: "rsp_no",
                label: "RSP No.",
            },
            {
                id: "stage_desc",
                label: "Stage"
            },
            {
                id: "status_desc",
                label: "Status"
            },
            {
                id: "ls_name",
                label: "LS Name"
            },
            {
                id: "updated_at",
                label: "Last Submitted On"
            }
        ]

        return (
            <Paper className={classes.paper}>
                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null}

                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.tablecell}
                                        key={cellHeader.id}
                                        padding="default"
                                        align="center"
                                        sortDirection={this.props.column === cellHeader.id ? this.props.direction : null}
                                    >
                                        <TableSortLabel
                                            active={this.props.column === cellHeader.id}
                                            direction={this.props.direction}
                                            onClick={this.sortHandler(cellHeader.id)}
                                        >
                                            {cellHeader.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell className={classes.tablecell}
                                    padding="default"
                                    align="center"
                                >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        {!this.props.data.length ?
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center"
                                        colSpan={6}>
                                        No data to display.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :

                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.form_id}>
                                        <TableCell align="center"
                                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.land_title}</TableCell>
                                        <TableCell align="center">{x.job_no}</TableCell>
                                        <TableCell align="center">{x.rsp_no}</TableCell>
                                        <TableCell align="center">{x.stage_desc}</TableCell>
                                        {x.curr_stage === 'APP' && x.status === 'Approved' ?
                                            <TableCell align="center"><b>Accepted</b></TableCell>
                                            :
                                        x.curr_stage === 'RSP' && x.status === 'Approved' ?
                                            <TableCell align="center"><b>Issued</b></TableCell>
                                            :
                                            x.curr_stage === 'CLM' && x.status === 'Approved' ?
                                            x.curr_claimpc === 10 ? 
                                                <TableCell align="center"><b>{x.curr_claimpc}% Adv Paid</b></TableCell> 
                                                :
                                                <TableCell align="center"><b>{x.curr_claimpc}% Paid</b></TableCell>
                                            :
                                            <TableCell align="center"><b>{x.status}</b></TableCell>
                                            }
                                        <TableCell align="center">{x.ls_name}</TableCell>
                                        <TableCell align="center">{x.updated_at}</TableCell>
                                        <TableCell align="center">
                                            <Button className={classes.colorButton}
                                                onClick={() => this.props.action(x.form_id)}>
                                                View
                                            </Button>
                                            <br />
                                            <br />
                                            <Button className={classes.colorButton}
                                                onClick={() => this.props.action2(x.form_id)}>
                                                History
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={this.props.count}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);