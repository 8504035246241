import React from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class PrivatisedForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            district_array: [],
            backdrop: false,
            dialog: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        axios.get(Config() + '/ls_surveyjob/privatised/application/initialize',
            {
                withCredentials: true
            })
            .then(response => {
                this.setState({
                    district_array: response.data,
                })

                //check if this is a new form or existing form
                if (sessionStorage.getItem('form_id') !== 'new') {
                    this.getFormData()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    getFormData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_surveyjob/privatised/application/get',
            {
                'form_id': sessionStorage.getItem('form_id')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.ComponentUI.current.initializeHandler(response.data[1])
                }
                this.setState({ backdrop: false })
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    submitHandler = (
        reference, project_name, year, package_no, pt_no, district,
        contract_sum, rsp_no, percentage,
        file_object, file_name, file_size
    ) => {
        if (reference === '' ||
            project_name === '' ||
            year === '' ||
            package_no === '' ||
            district === '' ||
            contract_sum === '' ||
            rsp_no === '' ||
            percentage === '') {

            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            if (!file_name.length) {
                this.errorHandler("Please upload the relevant documents for the Privatised Job Application.")
            }

            else {
                if (
                    Number(file_size.reduce((prev, next) =>
                        (Number(prev) + Number(next)), 0)) >= 50000000
                ) {
                    this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
                }

                else {
                    this.addData(
                        reference, project_name,
                        year, package_no, pt_no, district,
                        contract_sum, rsp_no,
                        percentage,
                        file_object, file_name
                    )
                }
            }
        }
    }

    addData = (
        reference, project_name,
        year, package_no, pt_no, district,
        contract_sum, rsp_no, percentage,
        file_object, file_name
    ) => {
        this.setState({ loading: true })
        if (localStorage.getItem('perm') === "2") {
            var url = Config() + '/ls_surveyjob/privatised/application/ls_staff/add'
        }
        else {
            url = Config() + '/ls_surveyjob/privatised/application/add'
        }
        axios.post(url,
            {
                'form_id': sessionStorage.getItem('form_id'),
                'reference': reference,
                'project_name': project_name,
                'year': year,
                'package_no': package_no,
                'pt_no': pt_no,
                'district': district,
                'contract_sum': contract_sum,
                'rsp_no': rsp_no,
                'percentage': percentage
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.addFile(response.data[1],
                        file_object, file_name)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ loading: false })
            })
    }

    addFile = (
        form_id,
        file_object, file_name
    ) => {
        const data = new FormData()

        for (let i = 0; i < file_object.length; i++) {
            data.append('file_object', file_object[i])
            data.append('file_name', file_name[i])
        }

        data.append('form_id', form_id)

        axios.post(Config() + '/ls_surveyjob/privatised/application/add/file',
            data,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        dialog: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ loading: false })
            })
    }

    closeDialog = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/PrivatisedJobList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    onClose={this.closeDialog}
                    open={this.state.dialog}
                    onClick={this.closeDialog} />

                <HeaderComponent />
                <FormComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                    data={this.state.district_array}
                    loading={this.state.loading}
                    action={this.submitHandler}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedForm));