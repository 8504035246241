import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import axios from "axios";
import { Config } from '../../../Config';
import { ResponseStatusCheck } from '../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';
import TableComponent from './Component/TableComponent';

import BackdropComponent from '../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class PenyataBayaranSearch extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],

            backdrop: false
        }
    }

    submitHandler = (job_no) => {
        if (job_no === '') {
            this.errorHandler("Please input some value to search.")
        }
        else {
            this.setState({ alert: false, error_msg: '' })
            this.fetchData(job_no)
        }
    }

    fetchData = (job_no) => {
        this.setState({ loading: true, backdrop: true })
        axios.put(Config() + '/surveyjob/file/penyatabayaran/get',
        {
            'job_no': job_no,
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    data: response.data[1],
                })
            }
            else {
                this.errorHandler(response.data)
            }
            this.setState({ backdrop: false, loading: false })
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false, loading: false })
        })
    }

    openFile = (value) => {
        window.open(value, "_blank")
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <br />
                <FormComponent
                action={this.submitHandler} />
                <br />
                <TableComponent
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                data={this.state.data}
                action={this.openFile} />
            </div>
        )
    }
}

export default withStyles(styles)(PenyataBayaranSearch);