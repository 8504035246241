import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    }
})

class CadastralCancellationForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            data: [],
            refund_type: '',
            
            dialog: false,
            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_claim/cadastral/application/calculate/cancellation',
            {
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        refund_type: response.data[2],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    addData = (
        file_object, file_name
    ) => {
        axios.post(Config() + '/ls_claim/cadastral/application/add/cancellation',
        {
            'form_id': sessionStorage.getItem('form_id_view'),
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.addFile(response.data[1],
                    file_object, file_name)
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    addFile = (
        claim_id, 
        file_object, file_name
    ) => {
        const data = new FormData()

        for (let i = 0; i < file_object.length; i++) {
            data.append('file_object', file_object[i])
            data.append('file_name', file_name[i])
        }

        data.append('claim_id', claim_id)
        data.append('form_id', sessionStorage.getItem('form_id_view'))

        axios.post(Config() + '/ls_claim/cadastral/application/add/file/cancellation',
        data,
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.setState({
                    dialog: true
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    closeDialog = () => {
        this.setState({ dialog: false })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralJobView'
        })
    }

    goNext = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/CadastralJobList'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                onClose={this.closeDialog}
                open={this.state.dialog}
                onClick={this.goNext} />

                <HeaderComponent />

                <FormComponent
                    ref={this.ComponentUI}
                    data={this.state.data}
                    refund_type={this.state.refund_type}
                    action={this.addData}
                    action2={this.goBack} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralCancellationForm));