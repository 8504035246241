import React from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import StepperComponent from '../../CadastralTableList/Component/StepperComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class Table13 extends React.Component {
    componentDidMount() {
        //if there is no form id value in session storage
        if (sessionStorage.getItem('form_id') === '' || sessionStorage.getItem('form_id') === undefined ||
            sessionStorage.getItem('form_id') === null) {
            this.props.history.push({
                pathname: '/ui/SurveyorHome'
            })
        }
    }

    goTable = (table) => {
        this.props.history.push({
            pathname: '/ui/CadastralTable' + table
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTableList'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <StepperComponent />
                <br />
                <HeaderComponent />
                <br />
                <FormComponent
                    action={this.goTable}
                    action2={this.goBack}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Table13));