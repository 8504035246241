import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericNumberTextInput from '../../Util/GenericNumberTextInput';
import GenericLoadTextField from '../../Util/GenericLoadTextField';
import GenericIdleTextField from '../../Util/GenericIdleTextField';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section4Component extends React.Component {
    checkbox_1_onChangeHandler = event => {
        this.props.onChange_value1(event.target.checked)
    }

    checkbox_2_onChangeHandler = event => {
        this.props.onChange_value2(event.target.checked)
    }

    input_3_onChangeHandler = event => {
        const re = /^\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            if (event.target.value <= 7) {
                this.props.onChange_value3(event.target.value)
            }
        }
    }
    
    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 4: Charge for security areas
                    </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.table}>Please fill in the necessary security area options.</TableCell>
                                <TableCell className={classes.table} style={{ textAlign: "right" }}>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>

                <AccordionDetails>
                    <Grid container 
                    direction="row" 
                    spacing={3} 
                    className={classes.padding}
                    >
                        <Grid item xs={12} sm={6}>
                            Where a security pass or permit is required from the relevant authority, an additional charge of twenty five percentum (25%) of the survey fee is applicable.
                        </Grid>

                        <Grid item xs={12} sm={1}></Grid>

                        <Grid item xs={12} sm={1}>
                            <Checkbox name="section4_a_checkbox"
                                checked={this.props.value1}
                                onChange={this.checkbox_1_onChangeHandler}
                                />
                        </Grid>

                        <Grid item xs={12} sm={1}></Grid>

                        <Grid item xs={12} sm={3}>
                            {this.props.loading1 ?
                                <GenericLoadTextField
                                    label="Amount"
                                    name="section4a_amount"
                                    value={this.props.valueTotal1}
                                />
                                :
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section4a_amount"
                                    value={this.props.valueTotal1}
                                />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <Grid container direction="row" spacing={3} className={classes.padding}>
                        <Grid item xs={12} sm={6}>
                            Where an armed escort is required and working hours are dictated by the relevant authority, an additional charge of fifty percentum (50%) of the survey fee is applicable.
                        </Grid>

                        <Grid item xs={12} sm={1}></Grid>

                        <Grid item xs={12} sm={1}>
                            <Checkbox name="section4_b_checkbox"
                                checked={this.props.value2}
                                onChange={this.checkbox_2_onChangeHandler}
                                />
                        </Grid>

                        <Grid item xs={12} sm={1}></Grid>

                        <Grid item xs={12} sm={3}>
                            {this.props.loading2 ?
                                <GenericLoadTextField
                                    label="Amount"
                                    name="section4b_amount"
                                    value={this.props.valueTotal2}
                                />
                                :
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section4b_amount"
                                    value={this.props.valueTotal2}
                                />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                    >

                        <Grid item xs={12} sm={6}>
                            Where there is a waiting time after mobilization due to circumstances beyond the control of the Licensed Surveyor, an additional charge of RM 1,200.00 per day shall be applicable up to a maximum of 7 days.
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Day/s"
                                value={this.props.value3}
                                inputProps={{ maxLength: 1 }}
                                onChange={this.input_3_onChangeHandler}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}></Grid>

                        <Grid item xs={12} sm={3}>
                            {this.props.loading3 ?
                                <GenericLoadTextField
                                    label="Amount"
                                    name="section4c_amount"
                                    value={this.props.valueTotal3}
                                />
                                :
                                <GenericIdleTextField
                                    label="Amount"
                                    name="section4c_amount"
                                    value={this.props.valueTotal3}
                                />
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section4Component);