import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

import RadioGroupComponent from './RadioGroupComponent';

import NextLoadButton from '../../../Util/NextLoadButton';
import NextIdleButton from '../../../Util/NextIdleButton';
import BackLoadButton from '../../../Util/BackLoadButton';
import BackIdleButton from '../../../Util/BackIdleButton';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
    input: {
        display: 'none',
    },
    radiogroup: {
        margin: 20
    }
})

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            percentage: '',

            file_object: [],
            file_name: [],
            file_size: [],

        }
    }

    onChangeHandler_radio = (event) => {
        this.setState({
            percentage: event.target.value
        })
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object: [...prevState.file_object, file],
                file_name: [...prevState.file_name, file.name],
                file_size: [...prevState.file_size, file.size]
            }))
        })
    }

    removeHandler = (i) => {
        let file_object = [...this.state.file_object]
        let file_name = [...this.state.file_name]
        let file_size = [...this.state.file_size]
        file_object.splice(i, 1)
        file_name.splice(i, 1)
        file_size.splice(i, 1)
        this.setState({ file_object, file_name, file_size })
    }

    submitHandler = () => {
        this.props.action(
            this.state.percentage,
            this.state.file_object,
            this.state.file_name,
            this.state.file_size
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null
                }
                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    Survey Job Details
                </Typography>
                <br />

                {this.props.form_data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.ls_reference}>

                        <Grid item xs={12} sm={4}>
                            Licensed Surveyor Reference:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ls_reference}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Year:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.year}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Project Name:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.project_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Package No.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ls_reference}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            PT No. (if any):
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.pt_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            District:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.dist_code}&nbsp;{x.dist_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Contract Sum:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.contract_sum.toFixed(2)}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            RSP No.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.rsp_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <br />
                <RadioGroupComponent className={classes.radiogroup}
                    value={this.props.value}
                    percentage={this.state.percentage}
                    onChange={this.onChangeHandler_radio} />

                <br />
                <Divider />
                <br />

                <Typography variant="h6">Upload Documents</Typography>
                <Typography variant="caption">
                    File References:<br />
                    - Claim Details<br />
                    - JTU/SEDIA Voucher (if available)
                </Typography>

                <br />

                <input
                    accept="image/*,application/pdf"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={this.uploadHandler}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span"
                        className={classes.button}>
                        Upload
                    </Button>
                </label>

                <Table>
                    <TableBody>
                        {this.state.file_name.length === 0 ?
                            <TableRow>
                                <TableCell>No files uploaded</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            :
                            this.state.file_name.map((x, i) => (
                                <TableRow key={x}>
                                    <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                    <TableCell style={{ width: '50%' }}>
                                        <IconButton color="secondary"
                                            onClick={() => this.removeHandler(i)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

                <Divider />

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextLoadButton className={classes.button} />
                        <BackLoadButton className={classes.button} />
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextIdleButton className={classes.button}
                            onClick={this.submitHandler} />
                        <BackIdleButton className={classes.button}
                        onClick={this.props.action2} />
                    </Box>
                }
            </Paper >
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);