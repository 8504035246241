import React from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
})

class StepperComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Stepper alternativeLabel>
                    <Step key={1} active>
                        <StepLabel>Enter Survey Job Details</StepLabel>
                    </Step>

                    <Step key={2}>
                        <StepLabel>Calculate the Amount</StepLabel>
                    </Step>

                    <Step key={3}>
                        <StepLabel>Upload Supporting Documents</StepLabel>
                    </Step>

                    <Step key={4}>
                        <StepLabel>Review the Application</StepLabel>
                    </Step>
                </Stepper>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(StepperComponent);