import React from 'react'

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000
    },
    tablecell: {
        fontWeight: 'bold',
        width: '15%'
    }
})

class TableComponent extends React.Component {
    render() {
        const { classes } = this.props

        const cellHeader = [
            {
                id: "pv_id",
                label: "Payment Voucher No."
            },
            {
                id: "pb_type",
                label: "Voucher Type"
            },
            {
                id: "job_no",
                label: "Job No."
            },
            {
                id: "ls_name",
                label: "LS Name"
            },
            {
                id: "created_by",
                label: "Generated By"
            },
            {
                id: "created_at",
                label: "Generated At"
            },
            {
                id: "action",
                label: "Action"
            }
        ]

        return (
            <Paper className={classes.paper}>
                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null}

                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.tablecell}
                                        key={cellHeader.id}
                                        padding="default"
                                        align="center"
                                    >
                                        {cellHeader.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {!this.props.data.length ?
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center"
                                        colSpan={8}>
                                        No data to display.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.pv_id}>
                                        <TableCell align="center">{x.pv_id}</TableCell>
                                        <TableCell align="center">{x.pb_type}</TableCell>
                                        <TableCell align="center">{x.job_no}</TableCell>
                                        <TableCell align="center">{x.ls_name}</TableCell>
                                        <TableCell align="center">{x.created_by}</TableCell>
                                        <TableCell align="center">{x.created_at}</TableCell>
                                        <TableCell align="center">
                                            <Link href="#"
                                                onClick={() => this.props.action(x.url)}>
                                                <b>View</b>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);