import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class ButtonComponent extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color={this.props.color}
            className={this.props.className}
            onClick={this.props.onClick}>
                {this.props.label}
            </Button>
        )
    }
}

class DialogComponent extends React.Component {
    render() {
        const { classes } = this.props
        
        return (
            <Dialog 
            onClose={this.props.onClose}
            open={this.props.open}>
                
                <DialogTitle>
                    Confirm Resubmission.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to resubmit the selected engineering survey job application?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent
                    color="primary"
                    className={classes.button}
                    onClick={this.props.action}
                    label="Yes"
                    />

                    <ButtonComponent
                    color="secondary"
                    className={classes.button}
                    onClick={this.props.action2}
                    label="No"
                    />
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);