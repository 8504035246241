import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class GridComponent extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                    direction="row"
                    spacing={3}
                    className={this.props.className}
                    key={x.category}>

                        <Grid item xs={12} sm={4}>
                            Category:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.category}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Description:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.description}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Organizer:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.organizer}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Venue:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.venue}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            From:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.start_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            To:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.end_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Credit Points Applied:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.points}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
    }
}

class Section1Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1 - CPD Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        CPD Details
                    </Typography>
                </AccordionDetails>

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <GridComponent
                            className={classes.padding}
                            data={this.props.data}
                        />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);