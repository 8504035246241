import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import AdornmentNumberTextInput from '../../Util/AdornmentNumberTextInput';

import Section2PopoverComponent from './Section2PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section2Component extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 2: Preparation of pre-computation / proposed subdivision plans
                        </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>

                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                        Please fill in the amount for the preparation of pre-computation or proposed subdivision plan.
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section2PopoverComponent />
                        </Grid>
                    </Grid>

                </AccordionDetails>

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}>
                        <Grid item xs={12} sm={4}>
                            <AdornmentNumberTextInput
                                label="Amount"
                                name="section2_input"
                                value={this.props.value}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);