import React from 'react';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { withStyles } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns';

const styles = theme => ({
    paper: {
        maxWidth: 500,
        margin: `${theme.spacing(1)}px auto`,
    },
    headerText: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    form: {
        width: '100%', //Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        paddingBottom: 0
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
    margin: {
        marginBottom: 10
    },
    box: {
        textAlign: 'center'
    }
})

class TextInputComponent extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                variant="outlined"
                margin="normal"
                fullWidth
                label={this.props.label}
                inputRef={this.props.inputRef}
                inputProps={this.props.inputProps}
            />
        )
    }
}

class SelectInputComponent extends React.Component {
    render() {
        return (
            <TextField
                error={this.props.error}
                variant="outlined"
                margin="normal"
                fullWidth
                select
                name={this.props.name}
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
            >
                {this.props.data.map(x => (
                    <MenuItem key={x.value}
                        value={x.value}>
                        {x.text}
                    </MenuItem>
                ))}
            </TextField>
        )
    }
}

class DateInputComponent extends React.Component {
    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    inputVariant="outlined"
                    margin="normal"
                    fullWidth
                    label={this.props.label}
                    format="dd/MM/yyyy"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        )
    }
}

class SearchButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                startIcon={<SearchIcon />}
                onClick={this.props.onClick}
            >
                Search
            </Button>
        )
    }
}

class ResetButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                startIcon={<RefreshIcon />}
                onClick={this.props.onClick}
            >
                Reset
            </Button>
        )
    }
}

class SearchPanelComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            data_status: [
                {
                    'value': 'Pending',
                    'text': 'Pending'
                },
                {
                    'value': 'Submitted',
                    'text': 'Submitted'
                },
                {
                    'value': 'Approved',
                    'text': 'Approved'
                },
                {
                    'value': 'Completed',
                    'text': 'Completed'
                },
                {
                    'value': 'Rejected',
                    'text': 'Rejected'
                },
            ],

            input_ls_name: '',
            input_stage: '',
            input_status: '',
            start_date: new Date(),
            end_date: new Date()
        }
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onChangeHandler_StartDate = date => {
        this.setState({ start_date: date })
    }

    onChangeHandler_EndDate = date => {
        this.setState({ end_date: date })
    }

    submitHandler = () => {
        let page = 0
        let rowsPerPage = 50

        var formatted_start_date = ''
        var formatted_end_date = ''

        if (this.state.start_date === null) {
            formatted_start_date = ''
        } else {
            formatted_start_date = (this.state.start_date).getFullYear() + '-' + ((this.state.start_date).getMonth() + 1) + '-' + (this.state.start_date).getDate()
        }

        if (this.state.end_date === null) {
            formatted_end_date = ''
        } else {
            formatted_end_date = (this.state.end_date).getFullYear() + '-' + ((this.state.end_date).getMonth() + 1) + '-' + (this.state.end_date).getDate()
        }

        this.props.action(
            page, rowsPerPage,
            this.input_titleNumber.value,
            this.input_jobNo.value,
            this.input_rspNo.value,
            this.state.input_ls_name,
            this.state.input_stage,
            this.state.input_status,
            formatted_start_date,
            formatted_end_date
        )
    }

    resetHandler = () => {
        this.input_titleNumber.value = ''
        this.input_jobNo.value = ''
        this.input_rspNo.value = ''
        this.setState({
            input_ls_name: '',
            input_stage: '',
            input_status: '',
            start_date: new Date(),
            end_date: new Date()
        })

        this.props.action2()
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3} className={classes.paper}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.headerText}>Search Panel</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <form className={classes.form}>
                                <TextInputComponent className={classes.margin}
                                    error={false}
                                    label="Filter by P.T./Title No."
                                    inputRef={node => (this.input_titleNumber = node)}
                                    inputProps={{ maxLength: 100 }}
                                />

                                <TextInputComponent className={classes.margin}
                                    error={false}
                                    label="Filter by Job No."
                                    inputRef={node => (this.input_jobNo = node)}
                                    inputProps={{ maxLength: 10 }}
                                />

                                <TextInputComponent className={classes.margin}
                                    error={false}
                                    label="Filter by RSP No."
                                    inputRef={node => (this.input_rspNo = node)}
                                    inputProps={{ maxLength: 10 }}
                                />

                                <SelectInputComponent className={classes.margin}
                                    error={false}
                                    name="input_ls_name"
                                    label="Filter by LS Name"
                                    value={this.state.input_ls_name}
                                    data={this.props.data}
                                    onChange={this.onChangeHandler}
                                />

                                <SelectInputComponent className={classes.margin}
                                    error={false}
                                    name="input_stage"
                                    label="Filter by Stage"
                                    value={this.state.input_stage}
                                    data={this.props.data2}
                                    onChange={this.onChangeHandler}
                                />

                                <SelectInputComponent className={classes.margin}
                                    error={false}
                                    name="input_status"
                                    label="Filter by Status"
                                    value={this.state.input_status}
                                    data={this.state.data_status}
                                    onChange={this.onChangeHandler}
                                />

                                <Grid container spacing={2} className={classes.margin}>
                                    <Grid item xs={12} md={5}>
                                        <DateInputComponent
                                            label="Start Date"
                                            value={this.state.start_date}
                                            onChange={this.onChangeHandler_StartDate}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={2}>

                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <DateInputComponent
                                            label="End Date"
                                            value={this.state.end_date}
                                            onChange={this.onChangeHandler_EndDate}
                                        />
                                    </Grid>
                                </Grid>

                                <Box pt={4} className={classes.box}>
                                    <SearchButtonComponent className={classes.button}
                                        onClick={this.submitHandler} />
                                    <ResetButtonComponent className={classes.button}
                                        onClick={this.resetHandler} />
                                </Box>
                            </form>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(SearchPanelComponent);