import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    },
    input: {
        display: 'none',
    },
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Next
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            file_object: [],
            file_name: [],
            file_size: [],
        }
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object: [...prevState.file_object, file],
                file_name: [...prevState.file_name, file.name],
                file_size: [...prevState.file_size, file.size]
            }))
        })
    }

    removeHandler = (i) => {
        let file_object = [...this.state.file_object]
        let file_name = [...this.state.file_name]
        let file_size = [...this.state.file_size]
        file_object.splice(i, 1)
        file_name.splice(i, 1)
        file_size.splice(i, 1)
        this.setState({ file_object, file_name, file_size })
    }

    submitHandler = () => {
        if (this.state.file_name.length === 0){
            // if (sessionStorage.getItem("10K") === "") {
                console.log("No file")
                this.errorHandler("Please upload at least one supporting document.")
            // }
        }
        //if the total upload file size is greater than 5 (or 50?) MB
        // else if (Number(this.state.file_size) >= 5000000) {
        // file size updated to 50MB #-- 22/06/2023 Judith
        else if (Number(this.state.file_size) >= 50000000) {
            console.log("Too big")
            this.errorHandler("The maximum size allowed for uploaded files should not exceeds 50MB.")
        }
        else {
            this.props.action(
                this.state.file_object,
                this.state.file_name)
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                {this.state.alert ?
                    <div>
                        <Alert severity="error">
                            {this.state.error_msg}
                        </Alert>
                        <br />
                    </div>
                    :
                    null}

                <form className={classes.form} noValidate>
                    <div>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>Supporting Document</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Please upload the required supporting document/s.</Typography>
                                <Typography variant="subtitle2">(Note: Please re-upload the files when editing this page)</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    File References:<br />
                                - Appointment Letter<br />
                                - Survey Fee Quotation to client<br />
                                - Details of Contract<br />
                                - Others
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <input
                                    accept="image/*,application/pdf"
                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={this.uploadHandler}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span"
                                        className={classes.button}>
                                        Upload
        </Button>
                                </label>
                            </Grid>

                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '50%' }}>File Name</TableCell>
                                            <TableCell style={{ width: '50%' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.file_name.length === 0 ?
                                            <TableRow>
                                                <TableCell>No files uploaded.</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            :
                                            this.state.file_name.map((x, i) => (
                                                <TableRow key={x}>
                                                    <TableCell>{x}</TableCell>
                                                    <TableCell>
                                                        <IconButton color="secondary"
                                                            onClick={() => this.removeHandler(i)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>

                    {this.props.loading ?

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextLoadButton className={classes.button} />
                            <BackLoadButton className={classes.button} />
                        </Box>

                        :

                        <Box pt={4} style={{ textAlign: 'center' }}>
                            <NextIdleButton className={classes.button}
                                onClick={this.submitHandler} />
                            <BackIdleButton className={classes.button}
                                onClick={this.props.action2} />
                        </Box>
                    }
                </form>
            </Paper >
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);