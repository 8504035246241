import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 600,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
    input: {
        display: 'none',
    },
    progressbar: {
        height: 10,
        borderRadius: 5
    },
    radiogroup: {
        margin: 20
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Next
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class ProgressBarComponent extends React.Component {
    render() {
        return (
            <Box display="flex" alignItems="center">
                <Box minWidth={35} style={{ textAlign: 'center' }}>
                    Current claim status
                </Box>

                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" className={this.props.className}
                        value={this.props.value} />
                </Box>

                <Box minWidth={35}>
                    <Typography variant="body2" color="primary">{this.props.value}%</Typography>
                </Box>
            </Box>
        )
    }
}

class RadioGroupComponent extends React.Component {
    render() {
        return (
            <FormControl component="fieldset" className={this.props.className}>
                <FormLabel component="legend">Percentage</FormLabel>
                <RadioGroup value={this.props.percentage} onChange={this.props.onChange}>
                    {/* <FormControlLabel value="10" control={<Radio />} label="Advance 10%"
                    disabled={parseInt(this.props.value) < 10 ? false : true} /> */}
                    {/* <FormControlLabel value="60" control={<Radio />} label="60%"
                    disabled={parseInt(this.props.value) < 60 ? false: true} /> */}
                    {/* <FormControlLabel value="65" control={<Radio />} label="55% / 65%"
                    disabled={parseInt(this.props.value) < 65 ? false : true} />
                    <FormControlLabel value="85" control={<Radio />} label="85% (Pre 2017 jobs)"
                    disabled={parseInt(this.props.value) < 85 ? false : true} /> */}
                    {/* <FormControlLabel value="95" control={<Radio />} label="10% / 30% / 95%"
                    disabled={parseInt(this.props.value) < 95 ? false : true} /> */}
                    <FormControlLabel value="95" control={<Radio />} label="95%"
                    disabled={parseInt(this.props.value) < 95 ? false : true} />
                </RadioGroup>
            </FormControl>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            percentage: ''
        }
    }

    onChangeHandler = (event) => {
        this.setState({
            percentage: event.target.value
        })
    }

    submitHandler = () => {
        sessionStorage.setItem('claim_percent', this.state.percentage)
        this.props.action()
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Paper elevation={3} className={classes.paper}>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                <br />

                <ProgressBarComponent className={classes.progressbar}
                    value={this.props.value} />

                <br />

                <RadioGroupComponent className={classes.radiogroup}
                    percentage={this.state.percentage}
                    value={this.props.value}
                    onChange={this.onChangeHandler} />

                <Divider />

                <br />

                <Typography variant="body2">
                    *The breakdown of claim details will be displayed on the next page.
                </Typography>

                <br />

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <NextIdleButton className={classes.button}
                    onClick={this.submitHandler} />
                    <BackIdleButton className={classes.button}
                    onClick={this.props.action2} />
                </Box>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);