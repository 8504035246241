import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from "axios";
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';
import TableComponent from './Component/TableComponent';
import DialogComponent from './Component/DialogComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class CPDLogbook extends React.Component {
    constructor() {
        super()
        this.state = {
            //table data
            data: [],
            ls_data: [],
            input_ls_name: '',

            //dialog data
            dialog: false,
            log_year_data: [],
            input_log_year: '',
            input_exemption_points: '',
            input_mandatory_points: '',

            backdrop: false,
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({ backdrop: true })

        axios.get(Config() + '/cpd/logbook/initialize',
        {
            withCredentials: true
        })
        .then(response => {
            this.setState({
                ls_data: response.data,
                backdrop: false
            })
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    fetchData = (ls_no) => {
        this.setState({ backdrop: true, input_ls_name: ls_no })

        axios.put(Config() + '/cpd/logbook/get',
        {
            'ls_no': ls_no
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    data: response.data[1],
                    backdrop: false
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    generatePDF = (log_year, ls_no) => {
        axios.put(Config() + '/cpd/logbook/generate',
        {
            'log_year': log_year,
            'ls_no': ls_no
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.openFile(response.data[1])
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    openFile = (value) => {
        window.open(value, "_blank")
    }

    
    openDialog = (ls_no) => {
        this.setState({ backdrop: true })
        //Get the available log year for the selected LS
        axios.put(Config() + '/cpd/logbook/query/logyear',
        {
            'ls_no': ls_no
        },
        {
            withCredentials: true
        })
        .then(response => {
            this.setState({ 
                backdrop: false,
                log_year_data: response.data,
                dialog: true
            })
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ backdrop: false })
        })
    }

    closeDialog = () => {
        this.setState({ 
            dialog: false,
            input_log_year: '',
            input_exemption_points: '',
            input_mandatory_points: ''
        })
    }

    getPointsData = (log_year) => {
        this.setState({ input_log_year: log_year })
        //Get the points data for the selected log year
        axios.put(Config() + '/cpd/logbook/query/points',
        {
            'ls_no': this.state.input_ls_name,
            'log_year': log_year
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    input_exemption_points: response.data[1],
                    input_mandatory_points: response.data[2],
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.closeDialog()
        })
    }

    onChangeExemption = (value) => {
        this.setState({ input_exemption_points: value })
    }

    onChangeMandatory = (value) => {
        this.setState({ input_mandatory_points: value })
    }

    submitHandler = () => {
        axios.put(Config() + '/cpd/logbook/update',
        {
            'ls_no': this.state.input_ls_name,
            'log_year': this.state.input_log_year,
            'exemption_point': this.state.input_exemption_points,
            'mandatory_point': this.state.input_mandatory_points
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.closeDialog()
                this.fetchData(this.state.input_ls_name)
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.closeDialog()
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <br />
                <FormComponent 
                data={this.state.ls_data}
                value={this.state.input_ls_name}
                action={this.fetchData}
                action2={this.openDialog} />
                <br />
                <TableComponent 
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                data={this.state.data}
                action={this.generatePDF} />

                <DialogComponent
                open={this.state.dialog}
                onClose={this.closeDialog}
                ls_name={this.state.input_ls_name}
                data={this.state.log_year_data}
                value1={this.state.input_log_year}
                action={this.getPointsData}
                value2={this.state.input_exemption_points}
                action2={this.onChangeExemption}
                value3={this.state.input_mandatory_points}
                action3={this.onChangeMandatory}
                action4={this.submitHandler}
                action5={this.closeDialog} />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(CPDLogbook));