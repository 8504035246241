import React from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class PrivatisedFormExist extends React.Component {
    constructor() {
        super()
        this.state = {
            district_array: [],
            form_data: [],
            percentage: '',

            backdrop: false,
            dialog: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_surveyjob/privatised/application/exist/initialize',
            {
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                this.setState({
                    district_array: response.data[0],
                    form_data: response.data[1],
                    percentage: response.data[2],

                    backdrop: false
                })

            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    submitHandler = (
        percentage,
        file_object, file_name, file_size
    ) => {
        if (percentage === '') {

            this.errorHandler("Please select the claim percentage.")
        }

        else {
            if (!file_name.length) {
                this.errorHandler("Please upload the relevant documents for the Privatised Job Application.")
            }

            else {
                if (
                    Number(file_size.reduce((prev, next) =>
                        (Number(prev) + Number(next)), 0)) >= 50000000
                ) {
                    this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
                }

                else {
                    this.addData(
                        percentage,
                        file_object, file_name
                    )
                }
            }
        }
    }

    addData = (
        percentage,
        file_object, file_name
    ) => {
        this.setState({ loading: true })
        axios.post(Config() + '/ls_surveyjob/privatised/application/exist/add',
        {
            'form_id': sessionStorage.getItem('form_id_view'),
            'percentage': percentage
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.addFile(response.data[1], percentage,
                    file_object, file_name)
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ loading: false })
        })
    }

    addFile = (
        form_id, percentage,
        file_object, file_name
    ) => {
        const data = new FormData()

        for (let i = 0; i < file_object.length; i++) {
            data.append('file_object', file_object[i])
            data.append('file_name', file_name[i])
        }

        data.append('form_id', form_id)
        data.append('percentage', percentage)

        axios.post(Config() + '/ls_surveyjob/privatised/application/exist/add/file',
        data,
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.setState({
                    dialog: true
                })
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({ loading: false })
        })
    }

    closeDialog = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/PrivatisedJobList'
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/PrivatisedJobView'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                onClose={this.closeDialog}
                open={this.state.dialog}
                onClick={this.closeDialog} />

                <HeaderComponent />
                <FormComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                    data={this.state.district_array}
                    form_data={this.state.form_data}
                    value={this.state.percentage}
                    loading={this.state.loading}
                    action={this.submitHandler}
                    action2={this.goBack} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedFormExist));