import React from 'react';

import { withRouter } from 'react-router-dom';

// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';
import DataComponent from './Component/DataComponent';
import ButtonComponent from './Component/ButtonComponent';
import DialogComponent from './Component/DialogComponent';
import DialogPaymentComponent from './Component/DialogPaymentComponent';
import TimelineComponent from './Component/TimelineComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class PrivatisedSurveyJobReview extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],

            section1_data: [],
            section1_file_data: [],
            section2_data: [],
            invoice_file_data: [],
            section3_data: [],
            section3_file_data: [],
            receipt_file_data: [],

            stage_data: [],

            charge_percent: [
                {
                    'key': '2',
                    'value': '2',
                    'text': '2'
                },
                {
                    'key': '5',
                    'value': '5',
                    'text': '5'
                }
            ],

            //State for displaying the data component
            visible: false,

            //State for displaying the button component
            active: false,

            //State for displaying the timeline component
            timeline: false,

            //State for displaying the checker dialogs
            dialog_1: false,
            dialog_2: false,
            p_dialog_1: false,
            p_dialog_2: false,

            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/surveyjob/privatised/view/get',
            {
                'job_no': sessionStorage.getItem('job_no_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        stage_data: response.data[1],
                        data: response.data[2],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    fetchTimeline = () => {
        this.setState({ backdrop: true, timeline: false, visible: false })
        axios.put(Config() + '/surveyjob/privatised/view/get/timeline',
            {
                'job_no': sessionStorage.getItem('job_no_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        timeline_data: response.data[1],

                        timeline: true,
                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    getFormData = (form_id, active) => {
        this.setState({ backdrop: true, timeline: false, visible: false })
        axios.put(Config() + '/surveyjob/privatised/view/get/form',
            {
                'form_id': form_id
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section1_file_data: response.data[2],
                        section2_data: response.data[3],
                        invoice_file_data: response.data[4],
                        section3_data: response.data[5],
                        section3_file_data: response.data[6],
                        receipt_file_data: response.data[7],

                        visible: true,
                        active: active,
                        backdrop: false
                    })
                    //Set the session value form id
                    sessionStorage.setItem('form_id_review', form_id)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    //Function to update the survey fee
    updateHandler = (
        total_claim, actual_survey_fee, jtu_sedia_payment,
        admin_charge_percent, total_admin_charge, admin_charge_paid,
        transportation, disbursement
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/new/review/update/surveyfee',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'total_claim': total_claim,
                'actual_survey_fee': actual_survey_fee,
                'jtu_sedia_payment': jtu_sedia_payment,
                'admin_charge_percent': admin_charge_percent,
                'total_admin_charge': total_admin_charge,
                'admin_charge_paid': admin_charge_paid,
                'transportation': transportation,
                'disbursement': disbursement
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ loading: false })
            })
    }

    generateInvoice = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/invoice/add',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
                else if (response.data === "No data") {
                    this.errorHandler("Please fill in the survey job details before generating the invoice.")
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ loading: false })
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/StaffPrivatisedJobList'
        })
    }

    openFirstCheckerDialog = () => {
        this.setState({ dialog_1: true })
    }

    closeFirstCheckerDialog = () => {
        this.setState({ dialog_1: false })
    }

    submitFirstChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/update/checker1',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openSecondCheckerDialog = () => {
        this.setState({ dialog_2: true })
    }

    closeSecondCheckerDialog = () => {
        this.setState({ dialog_2: false })
    }

    approveSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/update/checker2/approve',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/update/checker2/reject',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openFirstCheckerPaymentDialog = () => {
        this.setState({ p_dialog_1: true })
    }

    closeFirstCheckerPaymentDialog = () => {
        this.setState({ p_dialog_1: false })
    }

    submitFirstCheckerPayment = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/update/payment/checker1',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openSecondCheckerPaymentDialog = () => {
        this.setState({ p_dialog_2: true })
    }

    closeSecondCheckerPaymentDialog = () => {
        this.setState({ p_dialog_2: false })
    }

    approveSecondCheckerPayment = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/update/payment/checker2/approve',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
                else if (response.data['result'] === "No data") {
                    this.setState({ p_dialog_2: false })
                    this.errorHandler("Please generate the receipt before approving the application.")
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectSecondCheckerPayment = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/update/payment/checker2/reject',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generateAC = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/surveyjob/privatised/review/ac/add',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    open1={this.state.dialog_1}
                    onClose1={this.closeFirstCheckerDialog}
                    action1={this.submitFirstChecker}
                    open2={this.state.dialog_2}
                    onClose2={this.closeSecondCheckerDialog}
                    action2={this.approveSecondChecker}
                    action3={this.rejectSecondChecker}
                />

                <DialogPaymentComponent
                    open1={this.state.p_dialog_1}
                    onClose1={this.closeFirstCheckerPaymentDialog}
                    action1={this.submitFirstCheckerPayment}
                    open2={this.state.p_dialog_2}
                    onClose2={this.closeSecondCheckerPaymentDialog}
                    action2={this.approveSecondCheckerPayment}
                    action3={this.rejectSecondCheckerPayment}
                />

                <HeaderComponent
                    action={this.fetchTimeline} />

                <TableComponent
                    data={this.state.data}
                    action={this.getFormData}
                />

                <AlertComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                />

                {this.state.timeline ?
                    <TimelineComponent
                        data={this.state.timeline_data} />
                    :
                    null}

                {this.state.visible ?
                    <DataComponent
                        section1_data={this.state.section1_data}
                        section1_file_data={this.state.section1_file_data}
                        section2_data={this.state.section2_data}
                        loading={this.state.loading}
                        charge_percent={this.state.charge_percent}
                        action={this.updateHandler}
                        invoice_file_data={this.state.invoice_file_data}
                        action2={this.generateInvoice}
                        section3_data={this.state.section3_data}
                        section3_file_data={this.state.section3_file_data}
                        receipt_file_data={this.state.receipt_file_data}
                        action3={this.generateAC}
                    />
                    :
                    null
                }

                <ButtonComponent
                    active={this.state.active}
                    stage_data={this.state.stage_data}
                    action={this.goBack}
                    action1={this.openFirstCheckerDialog}
                    action2={this.openSecondCheckerDialog}
                    action3={this.openFirstCheckerPaymentDialog}
                    action4={this.openSecondCheckerPaymentDialog}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedSurveyJobReview));