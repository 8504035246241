import React from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../../Config';
import { ResponseStatusCheck } from '../../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class Table6 extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        if (sessionStorage.getItem('form_id_review') === '' || sessionStorage.getItem('form_id_review') === undefined ||
            sessionStorage.getItem('form_id_review') === null) {
            this.props.history.push({
                pathname: '/ui/StaffHome'
            })
        }
        else {
            this.setState({ backdrop: true })
            this.fetchData()
        }
    }

    fetchData = () => {
        axios.put(Config() + '/surveyjob/cadastral/sftable_6/get',
            {
                'form_id': sessionStorage.getItem('form_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        console.log("New calculator.")
                        this.setState({ backdrop: false })
                    }
                    else {
                        let section1_checkbox = response.data[1][0]['section1_checkbox'] === "True" ? true : false
                        let section2_input_1 = response.data[1][0]['section2_input_1']
                        let section2_input_2 = response.data[1][0]['section2_input_2']
                        let section2_select = response.data[1][0]['section2_select']
                        let section3_select = response.data[1][0]['section3_select']
                        let section4_a_checkbox = response.data[1][0]['section4_a_checkbox'] === "True" ? true : false
                        let section4_b_checkbox = response.data[1][0]['section4_b_checkbox'] === "True" ? true : false
                        let section4_c_input = response.data[1][0]['section4_c_input']

                        let section1_amount = response.data[1][0]['section1_amount'].toFixed(2)
                        let section2_array_amount = response.data[1][0]['section2_array_amount']
                        let section2_amount = response.data[1][0]['section2_amount'].toFixed(2)
                        let section3_amount = response.data[1][0]['section3_amount'].toFixed(2)
                        let section4_a_amount = response.data[1][0]['section4_a_amount'].toFixed(2)
                        let section4_b_amount = response.data[1][0]['section4_b_amount'].toFixed(2)
                        let section4_c_amount = response.data[1][0]['section4_c_amount'].toFixed(2)

                        this.setState({ backdrop: false })

                        this.ComponentUI.current.getData(
                            section1_checkbox, section2_input_1, section2_input_2, section2_select,
                            section3_select, section4_a_checkbox, section4_b_checkbox,
                            section4_c_input, section1_amount, section2_array_amount,
                            section2_amount, section3_amount, section4_a_amount,
                            section4_b_amount, section4_c_amount
                        )
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/StaffCadastralTableSummaryView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <br />
                <FormComponent
                    action={this.goBack}
                    ref={this.ComponentUI} />
            </div>

        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Table6));