import React from 'react';

import { withRouter } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import DialogComponent from './Component/DialogComponent';
import HeaderComponent from './Component/HeaderComponent';
import BreakdownComponent1 from './Component/BreakdownComponent1';
import BreakdownComponent2 from './Component/BreakdownComponent2';
import Section1Component from './Component/Section1Component';
import Section2Component from './Component/Section2Component';
import Review1Component from './Component/Review1Component';
import File1Component from './Component/File1Component';
import File2Component from './Component/File2Component';
import File3Component from './Component/File3Component';
import File4Component from './Component/File4Component';
import File5Component from './Component/File5Component';
import ButtonComponent from './Component/ButtonComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class CadastralClaimReview extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            section1_data: [],
            section2_data: [],
            breakdown_data: [],
            pv_data: [],
            ac_data: [],
            pb_data: [],
            pv_refund_data: [],
            pb_refund_data: [],

            dialog_1: false,
            dialog_2: false,

            backdrop: false,
            viewState: true,
            viewState_2: true
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.put(Config() + '/claim/cadastral/view/get',
            {
                'claim_id': sessionStorage.getItem('claim_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    sessionStorage.setItem('claim_percent_review', response.data[1][0]['percentage'])
                    this.setState({
                        data: response.data[1],
                        section1_data: response.data[2],
                        breakdown_data: response.data[3],
                        pv_data: response.data[4],
                        ac_data: response.data[5],
                        pb_data: response.data[6],
                        pv_refund_data: response.data[7],
                        pb_refund_data: response.data[8],
                        section2_data: response.data[9],
                        swift_code_data: response.data[10],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    openFirstCheckerDialog = () => {
        this.setState({ dialog_1: true })
    }

    closeFirstCheckerDialog = () => {
        this.setState({ dialog_1: false })
    }

    submitFirstChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/update/checker1',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'claim_id': sessionStorage.getItem('claim_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    openSecondCheckerDialog = () => {
        this.setState({ dialog_2: true })
    }

    closeSecondCheckerDialog = () => {
        this.setState({ dialog_2: false })
    }

    approveSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/update/checker2/approve',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'claim_id': sessionStorage.getItem('claim_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    rejectSecondChecker = (value) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/update/checker2/reject',
            {
                'form_id': sessionStorage.getItem('form_id_review'),
                'claim_id': sessionStorage.getItem('claim_id_review'),
                'comment': value
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    // generatePV = () => {
    //     this.setState({ loading: true })
    //     axios.put(Config() + '/claim/cadastral/review/pv/add',
    //         {
    //             'claim_id': sessionStorage.getItem('claim_id_review')
    //         },
    //         {
    //             withCredentials: true
    //         })
    //         .then(response => {
    //             if (response.data === "Success") {
    //                 this.init()
    //             }
    //         })
    //         .catch(error => {
    //             if (!error.response) {
    //                 this.errorHandler("Code issue probably.")
    //             }
    //             else {
    //                 if (ResponseStatusCheck(error)) {
    //                     //If Session Expired
    //                     if (ResponseStatusCheck(error) === 401) {
    //                         console.log("Display some window here.")
    //                     }
    //                     else {
    //                         this.errorHandler(ResponseStatusCheck(error))
    //                     }
    //                 }
    //                 else {
    //                     this.errorHandler("Something went very wrong!")
    //                 }
    //             }
    //         })
    // }

    generatePV = (
        remark, payment_type, cheque_no
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/pv/add',
            {
                'claim_id': sessionStorage.getItem('claim_id_review'),
                'remark': remark,
                'payment_type': payment_type,
                'cheque_no': cheque_no
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generateAC = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/ac/add',
            {
                'claim_id': sessionStorage.getItem('claim_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generatePB = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/pb/add',
            {
                'claim_id': sessionStorage.getItem('claim_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
                else if (response.data === "Invalid ID") {
                    this.errorHandler("Please generate the Payment Voucher first.")
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generatePVRefund = (
        remark, payment_type, cheque_no
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/pv/refund/add',
            {
                'claim_id': sessionStorage.getItem('claim_id_review'),
                'remark': remark,
                'payment_type': payment_type,
                'cheque_no': cheque_no
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    generatePBRefund = () => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/pb/refund/add',
            {
                'claim_id': sessionStorage.getItem('claim_id_review')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data === "Success") {
                    this.init()
                }
                else if (response.data === "Invalid ID") {
                    this.errorHandler("Please generate the Payment Voucher (Refund) first.")
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    updateHandler = (
        name, addr1, addr2, addr3, addr4, client_bank, client_acct_no
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/update/client',
        {
            'claim_id': sessionStorage.getItem('claim_id_review'),
            'name': name,
            'addr1': addr1,
            'addr2': addr2,
            'addr3': addr3,
            'addr4': addr4,
            'client_bank': client_bank,
            'client_acct_no': client_acct_no
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    editClaimHandler = (
        input_claim_survey_fee, input_claim_levy, input_claim_disbursement, input_claim_sst
    ) => {
        this.setState({ loading: true })
        axios.put(Config() + '/claim/cadastral/review/update/claim',
        {
            'form_id': sessionStorage.getItem('form_id_review'),
            'claim_id': sessionStorage.getItem('claim_id_review'),
            'claim_survey_fee': input_claim_survey_fee,
            'claim_levy': input_claim_levy,
            'claim_disbursement': input_claim_disbursement,
            'claim_sst': input_claim_sst
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState,
            alert: false,
            error_msg: ''
        })
    }

    viewState_2Handler = () => {
        this.setState({
            viewState_2: !this.state.viewState_2,
            alert: false,
            error_msg: ''
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/StaffCadastralClaimList'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <DialogComponent
                    open1={this.state.dialog_1}
                    onClose1={this.closeFirstCheckerDialog}
                    action1={this.submitFirstChecker}
                    open2={this.state.dialog_2}
                    onClose2={this.closeSecondCheckerDialog}
                    action2={this.approveSecondChecker}
                    action3={this.rejectSecondChecker}
                />

                <HeaderComponent />

                <Paper elevation={3}
                    className={classes.paper}>

                    <AlertComponent
                        alert={this.state.alert}
                        error_msg={this.state.error_msg} />

                    <BreakdownComponent1 data={this.state.breakdown_data} />
                    <BreakdownComponent2 
                        data={this.state.breakdown_data}
                        viewState={this.state.viewState}
                        viewState_2={this.state.viewState_2}
                        loading={this.state.loading}
                        action={this.editClaimHandler}
                    />

                    <br />
                    <Divider />
                    <br />

                    <Section1Component
                        data={this.state.section1_data} />
                    <br />
                    <Divider />
                    <br />

                    <Section2Component
                        data={this.state.section2_data}
                        swift_code_data={this.state.swift_code_data}
                        loading={this.state.loading}
                        action={this.updateHandler} />

                    <Review1Component
                        data={this.state.data} />

                    <File1Component
                        data={this.state.pv_data}
                        action={this.generatePV}
                        loading={this.state.loading} />

                    <File2Component
                        data={this.state.ac_data}
                        action={this.generateAC}
                        loading={this.state.loading} />

                    <File3Component
                        data={this.state.pb_data}
                        action={this.generatePB}
                        loading={this.state.loading} />

                    <File4Component
                        data={this.state.pv_refund_data}
                        action={this.generatePVRefund}
                        loading={this.state.loading} />

                    <File5Component
                        data={this.state.pb_refund_data}
                        action={this.generatePBRefund}
                        loading={this.state.loading} />

                </Paper>

                <ButtonComponent
                    data={this.state.data}
                    action={this.goBack}
                    action1={this.openFirstCheckerDialog}
                    action2={this.openSecondCheckerDialog} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralClaimReview));