import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import RequiredTooltipTextInput from '../Util/RequiredTooltipTextInput';
import GenericTextInput from '../Util/GenericTextInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    {this.props.image.map(x => (
                        <img src={x.url} key={x.id} alt=""
                            style={{ width: '250px', height: '250px' }} />
                    ))}
                </Grid>
            </React.Fragment>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.graduate_name}>

                        <Grid item xs={12} sm={4}>
                            Articled Graduate Name:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.graduate_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Identity Card Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ic_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Home Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.home_address}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Office Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.office_address}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.qualification}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Practical Experience:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.prac_exp}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Remarks:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.remark}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonIdleComponent
                        color="primary"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                        name="Edit"
                    />
                </Box>
            </div>
        )
    }
}

class Grid2ComponentEdit extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    submitHandler = () => {
        this.setState({
            error_graduate_name: this.input_graduate_name.value ? false : true,
            error_ic_no: this.input_ic_no.value ? false : true,
            error_qualification: this.input_qualification.value ? false : true,
            error_prac_exp: this.input_prac_exp.value ? false : true,
        })

        if (this.input_graduate_name.value === '' ||
            this.input_ic_no.value === '' ||
            this.input_qualification.value === '' ||
            this.input_prac_exp.value === '') {

            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            this.props.action(
                this.input_graduate_name.value,
                this.input_ic_no.value,
                this.input_home_address.value,
                this.input_office_address.value,
                this.input_qualification.value,
                this.input_prac_exp.value,
                this.input_remark.value,
            )
        }
    }
    errorHandler = (value) => {
        this.setState({
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.graduate_name}>

                        <Grid item xs={12} sm={4}>
                            Articled Graduate Name:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTextInput
                                error={this.state.error_graduate_name}
                                name="input_graduate_name"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_graduate_name = node)}
                                defaultValue={x.graduate_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Identity Card Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTextInput
                                error={this.state.error_ic_no}
                                name="input_ic_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ic_no = node)}
                                defaultValue={x.ic_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Home Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_home_address"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_home_address = node)}
                                defaultValue={x.home_address}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Office Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_office_address"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_office_address = node)}
                                defaultValue={x.office_address}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTooltipTextInput
                                error={this.state.error_qualification}
                                name="input_qualification"
                                inputProps={{ maxLength: 100 }}
                                title="Please type in the details of educational and professional qualification."
                                inputRef={node => (this.input_qualification = node)}
                                defaultValue={x.qualification}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Practical Experience:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTooltipTextInput
                                error={this.state.error_prac_exp}
                                name="input_prac_exp"
                                inputProps={{ maxLength: 100 }}
                                title="Please type in the details of practical experience with dates."
                                inputRef={node => (this.input_prac_exp = node)}
                                defaultValue={x.prac_exp}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Remarks:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_remark"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_remark = node)}
                                defaultValue={x.remark}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonLoadComponent
                            color="primary"
                            className={this.props.classNameButton}
                            name="Updating..."
                        />

                        <ButtonLoadComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            name="..."
                        />
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonIdleComponent
                            color="primary"
                            className={this.props.classNameButton}
                            onClick={this.submitHandler}
                            name="Update"
                        />
                        <ButtonIdleComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}
                            name="Cancel"
                        />
                    </Box>
                }
            </div>
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color={this.props.color}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.name}
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                {this.props.name}
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class Section1Component extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        graduate_name, ic_no, home_address, office_address,
        qualification, prac_exp, remark) => {

        this.props.action(
            graduate_name, ic_no, home_address, office_address,
            qualification, prac_exp, remark
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1 - Articled Graduate Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Articled Graduate Details
                    </Typography>
                </AccordionDetails>

                {!this.props.image.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            image={this.props.image}
                        />
                    </AccordionDetails>
                }

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        {this.state.viewState === true ?
                            <React.Fragment>
                                <Grid2Component
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    onClick={this.viewStateHandler}
                                />

                            </React.Fragment>

                            :
                            <React.Fragment>
                                <Grid2ComponentEdit
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    loading={this.props.loading}
                                    action={this.submitHandler}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>
                        }
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);