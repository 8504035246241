import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../../Util/GenericTextInput';
import DateInputComponent from '../../Util/DateInputComponent';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    }
})

class RSPDialogComponent extends React.Component {
    render() {
        return (
            <Dialog
                onClose={this.props.onClose}
                open={this.props.open}>

                <DialogTitle>
                    Add RSP No.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill in the RSP No. and the related date for the selected Survey Job Application.
                    </DialogContentText>

                    <GenericTextInput
                        label="RSP Number"
                        inputProps={{ maxLength: 10 }}
                        inputRef={this.props.inputRef}
                    />

                    <DateInputComponent
                        label="RSP Date"
                        name="input_rsp_date"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        error={this.props.error}
                    />

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.onClick}
                        className={this.props.className}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class DialogComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_rsp_date: null,

            input_error: false
        }
    }

    onChangeHandler = date => {
        this.setState({ input_rsp_date: date })
    }

    submitHandler = () => {
        if (this.input_rsp_no.value === '' ||
            this.state.input_rsp_date === null) {

                this.setState({ input_error: true })
            }

        else {
            var formatted_rsp_date = (this.state.input_rsp_date).getDate() + '/' + ((this.state.input_rsp_date).getMonth() + 1) + '/' + (this.state.input_rsp_date).getFullYear()
            this.props.action(
                this.input_rsp_no.value,
                formatted_rsp_date
            )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <RSPDialogComponent
                onClose={this.props.onClose}
                open={this.props.open}
                onChange={this.onChangeHandler}
                inputRef={node => (this.input_rsp_no = node)}
                value={this.state.input_rsp_date}
                onClick={this.submitHandler}
                error={this.state.input_error}
                className={classes.button}
                />
        )
    }
}

export default withStyles(styles, { withTheme: true })(DialogComponent);