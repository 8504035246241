import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import StepperComponent from './Component/StepperComponent';
import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';
import ButtonComponent from './Component/ButtonComponent';
import DialogComponent from './Component/DialogComponent';
import SnackbarComponent from './Component/SnackbarComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class CadastralTableSummary extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false,
            loading: false,
            dialog: false,
            snackbar: false,

            sftable: [],
            input_sst: '',
            total_amount: (0).toFixed(2)
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        if (sessionStorage.getItem('form_id') === '' || sessionStorage.getItem('form_id') === undefined ||
            sessionStorage.getItem('form_id') === null) {
            this.props.history.push({
                pathname: '/ui/SurveyorHome'
            })
        }
        else {
            this.setState({ backdrop: true })
            this.fetchData()
        }
    }

    fetchData = () => {
        axios.put(Config() + '/ls_surveyjob/cadastral/application/table/summarize/get',
            {
                'form_id': sessionStorage.getItem('form_id')
            },
            {
                withCredentials: true
            })
            .then(response => {
                console.log(response.data)
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        this.setState({
                            sftable: [],
                            backdrop: false
                        },
                            () => {
                                this.total_ChangeHandler()
                            })
                    }
                    else {
                        this.setState({
                            sftable: response.data[1]
                        }, () => {
                            this.fetchSST()
                        })
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    fetchSST = () => {
        axios.put(Config() + '/ls_surveyjob/cadastral/application/table/summarize/get/sst',
            {
                'form_id': sessionStorage.getItem('form_id')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        this.total_ChangeHandler()
                        this.setState({
                            backdrop: false
                        })
                    }
                    else {
                        this.setState({
                            input_sst: response.data[1][0]['sst_input'].toFixed(2)
                        }, () => {
                            let total = this.total_ChangeHandler()
                            this.setState({
                                total_amount: (Number(total) +
                                    Number(this.state.input_sst)).toFixed(2),
                                backdrop: false
                            })
                        })
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    onChangeHandler = (value) => {
        this.setState({
            input_sst: value
        }, () => {
            let total = this.total_ChangeHandler()
            this.setState({
                total_amount: (Number(total) +
                    Number(this.state.input_sst)).toFixed(2)
            })
        })
    }

    total_ChangeHandler = () => {
        let temp_array = []
        for (var i = 0; i < this.state.sftable.length; i++) {
            temp_array.push(this.state.sftable[i]['total_amount'])
        }

        let total = temp_array.reduce((prev, next) =>
            (Number(prev) + Number(next)), 0).toFixed(2)
        this.setState({ total_amount: total })

        return total
    }

    redirectionTable = (table) => {
        this.props.history.push({
            pathname: '/ui/CadastralTable' + table
        })
    }

    openDialogHandler = (value, value2) => {
        sessionStorage.setItem('table_id', value)
        sessionStorage.setItem('table_summary_id', value2)
        this.setState({ dialog: true })
    }

    //close delete dialog
    closeDialogHandler = () => {
        sessionStorage.removeItem('table_id')
        sessionStorage.removeItem('table_summary_id')
        this.setState({ dialog: false })
    }

    deleteData = () => {
        this.setState({
            snackbar: false,
            loading: true
        })

        axios.put(Config() + '/ls_surveyjob/cadastral/application/table/summarize/delete',
            {
                'form_id': sessionStorage.getItem('form_id'),
                'delete_table_id': sessionStorage.getItem('table_id'),
                'delete_summary_id': sessionStorage.getItem('table_summary_id')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.setState({
                        snackbar: true,
                        loading: false,
                        dialog: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    closeSnackbarHandler = () => {
        this.setState({ snackbar: false })
    }

    goNext = () => {
        this.setState({ loading: true })
        axios.post(Config() + '/ls_surveyjob/cadastral/application/table/summarize/add/sst',
            {
                'form_id': sessionStorage.getItem('form_id'),
                'sst_input': this.state.input_sst,
                'total_amount': this.state.total_amount
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.props.history.push({
                        pathname: '/ui/CadastralSupportingDocumentForm'
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    goTableList = () => {
        this.props.history.push({
            pathname: '/ui/CadastralTableList'
        })
    }

    goSummary = () => {
        this.setState({ loading: true })
        axios.post(Config() + '/ls_surveyjob/cadastral/application/table/summarize/add/sst',
            {
                'form_id': sessionStorage.getItem('form_id'),
                'sst_input': this.state.input_sst,
                'total_amount': this.state.total_amount
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data['result'] === "Success") {
                    this.props.history.push({
                        pathname: '/ui/CadastralApplicationSummary'
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <StepperComponent />
                <br />
                <HeaderComponent />
                <TableComponent
                    array={this.state.sftable}
                    onChange={this.onChangeHandler}
                    value={this.state.input_sst}
                    value2={this.state.total_amount}
                    action={this.redirectionTable}
                    action2={this.openDialogHandler}
                    ref={this.ComponentUI} />

                <ButtonComponent
                    loading={this.state.loading}
                    action={this.goNext}
                    action2={this.goTableList}
                    action3={this.goSummary}
                />

                <DialogComponent
                    open={this.state.dialog}
                    loading={this.state.loading}
                    action={this.deleteData}
                    action2={this.closeDialogHandler} />

                {this.state.snackbar ?
                    <SnackbarComponent
                        open={this.state.snackbar}
                        onClose={this.closeSnackbarHandler}
                    />
                    :
                    null
                }
            </div>

        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CadastralTableSummary));