import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import { colors } from '@material-ui/core'

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
    buttonView: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        color: colors.green[500]
    }
})

class Table1Component extends React.Component {
    render() {
        return (
            <Table>
                {this.props.data.map(x => (
                    <TableBody key={x.id}>
                        <TableRow>
                            <TableCell style={{ width: '50%' }}>
                                <b>Receipt PDF File {x.filename}</b>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <Button variant="contained"
                                    className={this.props.className}
                                    onClick={() => this.props.onClick2(x.url)}>
                                    Download
                                </Button>
                            </TableCell>
                            <TableCell style={{ width: '30%' }}>
                                {this.props.loading ?
                                    <GenerateLoadButton className={this.props.classNameButton} />
                                    :
                                    <GenerateIdleButton className={this.props.classNameButton}
                                        onClick={this.props.onClick}
                                        text="Generate" />
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"
                                colSpan={3}>
                                Issued By: {x.created_by} <br />
                                Issued At: {x.created_at}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </Table>
        )
    }
}

class GenerateIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}>
                {this.props.text}
            </Button>
        )
    }
}

class GenerateLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}>
                Generating...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class File1Component extends React.Component {

    openFile = (value) => {
        window.open(value, "_blank")
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Receipt
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            Click on the button to generate an Receipt PDF File.
                                        </Typography>
                                        {this.props.loading ?
                                            <GenerateLoadButton className={classes.button} />
                                            :
                                            <GenerateIdleButton className={classes.button}
                                                onClick={this.props.action}
                                                text="Generate Receipt" />
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Table1Component
                            className={classes.buttonView}
                            classNameButton={classes.button}
                            data={this.props.data}
                            onClick={this.props.action}
                            onClick2={this.openFile}
                            loading={this.props.loading} />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(File1Component);