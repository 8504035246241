import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.form_id}>

                        <Grid item xs={12} sm={4}>
                            Total Claim:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.total_claim === '' ?
                                <b>{x.total_claim}</b>
                                :
                                <b>{x.total_claim.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Actual Survey Fee:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.actual_survey_fee === '' ?
                                <b>{x.actual_survey_fee}</b>
                                :
                                <b>{x.actual_survey_fee.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Transportation:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.transportation === '' ?
                                <b>{x.transportation}</b>
                                :
                                <b>{x.transportation.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Disbursement:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.disbursement === '' ?
                                <b>{x.disbursement}</b>
                                :
                                <b>{x.disbursement.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            JTU/SEDIA payment amount:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.jtu_sedia_payment === '' ?
                                <b>{x.jtu_sedia_payment}</b>
                                :
                                <b>{x.jtu_sedia_payment.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Charge %:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.admin_charge_percent}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Total Admin Charge:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.total_admin_charge === '' ?
                                <b>{x.total_admin_charge}</b>
                                :
                                <b>{x.total_admin_charge.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Admin Charge Paid:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.admin_charge_paid === '' ?
                                <b>{x.admin_charge_paid}</b>
                                :
                                <b>{x.admin_charge_paid.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <br />
                        <Grid item xs={6} sm={3}>
                            Last Updated By:
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            Last Updated At:
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <b>{x.updated_at}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
    }
}

class Section2Component extends React.Component {

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    Survey Fee Details (Managed by LJS)
                </Typography>

                <Grid1Component
                    className={classes.padding}
                    data={this.props.data}
                    classNameButton={classes.button}
                    onClick={this.viewStateHandler}
                />
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);