import React from 'react';

import { withRouter } from 'react-router-dom';

// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import AlertComponent from './Component/AlertComponent';
import HeaderComponent from './Component/HeaderComponent';
import TableComponent from './Component/TableComponent';
import DataComponent from './Component/DataComponent';
import ButtonComponent from './Component/ButtonComponent';
import TimelineComponent from './Component/TimelineComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
    paper: {
        maxWidth: 1000,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
    }
})

class PrivatisedJobView extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],

            section1_data: [],
            section1_file_data: [],
            section2_data: [],
            invoice_file_data: [],
            section3_data: [],
            section3_file_data: [],
            receipt_file_data: [],

            stage_data: [],

            //State for displaying the data component
            visible: false,

            //State for displaying the button component
            active: false,

            //State for displaying the timeline component
            timeline: false,

            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_surveyjob/privatised/view/get',
            {
                'job_no': sessionStorage.getItem('job_no_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        stage_data: response.data[1],
                        data: response.data[2],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    fetchTimeline = () => {
        this.setState({ backdrop: true, timeline: false, visible: false })
        axios.put(Config() + '/ls_surveyjob/privatised/view/get/timeline',
            {
                'job_no': sessionStorage.getItem('job_no_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        timeline_data: response.data[1],

                        timeline: true,
                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    getFormData = (form_id, active) => {
        this.setState({ backdrop: true, timeline: false, visible: false })
        axios.put(Config() + '/ls_surveyjob/privatised/view/get/form',
            {
                'form_id': form_id
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        section1_data: response.data[1],
                        section1_file_data: response.data[2],
                        section2_data: response.data[3],
                        invoice_file_data: response.data[4],
                        section3_data: response.data[5],
                        section3_file_data: response.data[6],
                        receipt_file_data: response.data[7],

                        visible: true,
                        active: active,
                        backdrop: false
                    })
                    //Set the session value form id
                    sessionStorage.setItem('form_id_view', form_id)
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({
                    backdrop: false
                })
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/PrivatisedJobList'
        })
    }

    goApplicationForm = () => {
        this.props.history.push({
            pathname: '/ui/PrivatisedFormExist'
        })
    }

    goPaymentForm = () => {
        this.props.history.push({
            pathname: '/ui/PrivatisedPaymentForm'
        })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent
                    action={this.fetchTimeline} />

                <TableComponent
                    data={this.state.data}
                    action={this.getFormData}
                />

                <AlertComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                />

                {this.state.timeline ?
                    <TimelineComponent
                        data={this.state.timeline_data} />
                    :
                    null}

                {this.state.visible ?
                    <DataComponent
                        section1_data={this.state.section1_data}
                        section1_file_data={this.state.section1_file_data}
                        section2_data={this.state.section2_data}
                        invoice_file_data={this.state.invoice_file_data}
                        section3_data={this.state.section3_data}
                        section3_file_data={this.state.section3_file_data}
                        receipt_file_data={this.state.receipt_file_data}
                    />
                    :
                    null
                }

                <ButtonComponent
                    active={this.state.active}
                    stage_data={this.state.stage_data}
                    action={this.goBack}
                    action1={this.goApplicationForm}
                    action2={this.goPaymentForm}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(PrivatisedJobView));