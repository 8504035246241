import React from 'react';

import TextField from '@material-ui/core/TextField';

class RequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="standard"
                margin="normal"
                fullWidth
                multiline
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                inputRef={this.props.inputRef}
                InputLabelProps={this.props.InputLabelProps}
                defaultValue={this.props.defaultValue}
            />
        )
    }
}

export default RequiredTextInput;