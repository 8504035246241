import React from 'react'

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    table: {
        borderBottom: "none"
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section2PopoverComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            anchor: null
        }
    }

    toggleHandler = e => {
        this.setState({
            open: !this.state.open,
            anchor: e.currentTarget
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.toggleHandler}
                >
                    Reference
                </Button>

                <Popover
                    open={this.state.open}
                    onClose={this.toggleHandler}
                    anchorEl={this.state.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.padding}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Charge for each lot as per rates shown below:</TableCell>
                                    <TableCell align="right">RM</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>a) Lot of less than 0.5 hectare</TableCell>
                                    <TableCell className={classes.table} align="right">1,110.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>b) Lot of 0.5 hectare but less than 2.5 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">2,490.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>c) Lot of 2.5 hectares but less than 5.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">3,525.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>d) Lot of 5.0 hectares but less than 10.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">4,980.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>e) Lot of 10.0 hectares but less than 15.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">6,090.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>f) Lot of 15.0 hectares but less than 20.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">7,035.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>g) Lot of 20.0 hectares but less than 25.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">7,875.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>h) Lot of 25.0 hectares but less than 30.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">8,625.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>i) Lot of 30.0 hectares but less than 35.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">9,315.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>j) Lot of 35.0 hectares but less than 40.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">9,960.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>k) Lot of 40.0 hectares but less than 45.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">10,560.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>l) Lot of 45.0 hectares but less than 50.0 hectares</TableCell>
                                    <TableCell className={classes.table} align="right">11,130.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>m) For each additional 1 hectare or part thereof above the first 50 hectares</TableCell>
                                    <TableCell align="right">150.00</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell className={classes.table}>In calculating the above charges, any additional part of a hectare will be reckoned as one hectare.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2PopoverComponent);