import React from 'react';

import TextField from '@material-ui/core/TextField';

class GenericNumberTextInput extends React.Component {
    render() {
        return (
            <TextField
                variant="outlined"
                margin="normal"
                label={this.props.label}
                name={this.props.name}
                type="tel"
                value={this.props.value}
                inputProps={this.props.inputProps}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
            />
        )
    }
}

export default GenericNumberTextInput;