import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

class GenericSelectTextInput extends React.Component {
    render() {
        return (
            <TextField
            disabled
            variant="outlined"
            margin="normal"
            label={this.props.label}
            name={this.props.name}
            select
            fullWidth
            value={this.props.value}
            >
                {this.props.data.map(x => (
                    <MenuItem key={x.id}
                        value={x.value}
                        style={{ whiteSpace: 'normal' }}>
                        {x.text}
                    </MenuItem>
                ))}
            </TextField>
        )
    }
}

export default GenericSelectTextInput;