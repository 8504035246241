import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../Util/RequiredTextInput';
import RequiredSelectTextInput from '../Util/RequiredSelectTextInput';
import ButtonCancel from '../Util/ButtonCancel';
import ButtonEdit from '../Util/ButtonEdit';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class GridEditComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            status_data: [
                {
                    'key': 'A',
                    'value': 'A',
                    'text': 'Active'
                },
                {
                    'key': 'I',
                    'value': 'I',
                    'text': 'Inactive'
                }
            ],
            payee_ind_data: [
                {
                    'key': 'S',
                    'value': 'LS',
                    'text': 'Licensed Surveyor'
                },
                {
                    'key': 'C',
                    'value': 'CO',
                    'text': 'Company'
                }
            ],
            swift_code_data: [],
            input_ls_status: '',
            input_ls_payee_ind: '',
            input_ls_bank: '',
        }
    }

    componentDidMount() {
        this.setState({
            swift_code_data: this.props.swift_code_data,
            input_ls_status: this.props.data[0]['ls_status'],
            input_ls_payee_ind: this.props.data[0]['payee_ind'],
            input_ls_bank: this.props.data[0]['ls_bank']
        })
    }

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {
        this.props.action3(
            this.input_ls_title.value,
            this.input_ls_name.value,
            this.input_ic_no.value,
            this.input_co_name.value,
            this.state.input_ls_status,
            this.input_email.value,
            this.input_phone.value,
            this.input_mobile.value,
            this.input_fax.value,
            this.input_addr1.value,
            this.input_addr2.value,
            this.input_addr3.value,
            this.input_addr4.value,
            this.input_paddr1.value,
            this.input_paddr2.value,
            this.input_paddr3.value,
            this.input_paddr4.value,
            this.input_lic_no.value,
            this.input_svc_tax.value,
            this.input_gl_acctno.value,
            this.input_ls_acct_no.value,
            this.state.input_ls_bank,
            this.input_ls_bank_branch.value,
            this.state.input_ls_payee_ind
        )
    }

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.ls_no}>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Licensed Surveyor No:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <b>{x.ls_no}</b>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Title:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_ls_title}
                                name="input_ls_title"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_ls_title = node)}
                                defaultValue={x.ls_title}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Full Name: *
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_ls_name}
                                name="input_ls_name"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ls_name = node)}
                                defaultValue={x.ls_name}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            IC No:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_ic_no}
                                name="input_ic_no"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_ic_no = node)}
                                defaultValue={x.ic_no}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Company Name:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_co_name}
                                name="input_co_name"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_co_name = node)}
                                defaultValue={x.co_name}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Status: *
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredSelectTextInput
                                error={this.state.error_ls_status}
                                name="input_ls_status"
                                value={this.state.input_ls_status}
                                onChange={this.onChangeHandler}
                                data={this.state.status_data}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Email Address:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_email}
                                name="input_email"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_email = node)}
                                defaultValue={x.email}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Phone Number:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_phone}
                                name="input_phone"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_phone = node)}
                                defaultValue={x.phone}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Mobile Number:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_mobile}
                                name="input_mobile"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_mobile = node)}
                                defaultValue={x.mobile}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Fax:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_fax}
                                name="input_fax"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_fax = node)}
                                defaultValue={x.fax}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 1:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_addr1}
                                name="input_addr1"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr1 = node)}
                                defaultValue={x.addr1}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 2:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_addr2}
                                name="input_addr2"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr2 = node)}
                                defaultValue={x.addr2}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 3:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_addr3}
                                name="input_addr3"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr3 = node)}
                                defaultValue={x.addr3}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Address Line 4:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_addr4}
                                name="input_addr4"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_addr4 = node)}
                                defaultValue={x.addr4}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 1:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_paddr1}
                                name="input_paddr1"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr1 = node)}
                                defaultValue={x.paddr1}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 2:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_paddr2}
                                name="input_paddr2"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr2 = node)}
                                defaultValue={x.paddr2}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 3:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_paddr3}
                                name="input_paddr3"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr3 = node)}
                                defaultValue={x.paddr3}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Postal Address Line 4:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_paddr4}
                                name="input_paddr4"
                                inputProps={{ maxLength: 40 }}
                                inputRef={node => (this.input_paddr4 = node)}
                                defaultValue={x.paddr4}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            License Number:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_lic_no}
                                name="input_lic_no"
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_lic_no = node)}
                                defaultValue={x.lic_no}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Service Tax:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_svc_tax}
                                name="input_svc_tax"
                                inputProps={{ maxLength: 10 }}
                                inputRef={node => (this.input_svc_tax = node)}
                                defaultValue={x.svc_tax}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            GL Account No.:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_gl_acctno}
                                name="input_gl_acctno"
                                inputProps={{ maxLength: 12 }}
                                inputRef={node => (this.input_gl_acctno = node)}
                                defaultValue={x.gl_acctno}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            Default Payee Name:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredSelectTextInput
                                name="input_ls_payee_ind"
                                value={this.state.input_ls_payee_ind}
                                onChange={this.onChangeHandler}
                                data={this.state.payee_ind_data}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            LS Account No.:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_ls_acct_no}
                                name="input_ls_acct_no"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_ls_acct_no = node)}
                                defaultValue={x.ls_acct_no}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            LS Bank:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredSelectTextInput
                                error={this.state.error_ls_bank}
                                name="input_ls_bank"
                                value={this.state.input_ls_bank}
                                onChange={this.onChangeHandler}
                                data={this.state.swift_code_data}
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            LS Bank Branch:
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RequiredTextInput
                                error={this.state.error_ls_bank_branch}
                                name="input_ls_bank_branch"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ls_bank_branch = node)}
                                defaultValue={x.ls_bank_branch}
                            />
                        </Grid>
                        <br />
                        <br />
                    </Grid>
                ))}

                <Box pt={4}
                    className={classes.box}>

                    <ButtonEdit
                        className={classes.button}
                        onClick={this.submitHandler}
                    />

                    <ButtonCancel
                        className={classes.button}
                        onClick={this.props.action4}
                    />
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(GridEditComponent);