import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';

import ControlledTextInput from '../../../Util/ControlledTextInput';
import SelectInputComponent from '../../../Util/SelectInputComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.form_id}>

                        <Grid item xs={12} sm={4}>
                            Total Claim:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.total_claim === '' ?
                                <b>{x.total_claim}</b>
                                :
                                <b>{x.total_claim.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Actual Survey Fee:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.actual_survey_fee === '' ?
                                <b>{x.actual_survey_fee}</b>
                                :
                                <b>{x.actual_survey_fee.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Transportation:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.transportation === '' ?
                                <b>{x.transportation}</b>
                                :
                                <b>{x.transportation.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Disbursement:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.disbursement === '' ?
                                <b>{x.disbursement}</b>
                                :
                                <b>{x.disbursement.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            JTU/SEDIA payment amount:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.jtu_sedia_payment === '' ?
                                <b>{x.jtu_sedia_payment}</b>
                                :
                                <b>{x.jtu_sedia_payment.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Charge %:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.admin_charge_percent}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Total Admin Charge:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.total_admin_charge === '' ?
                                <b>{x.total_admin_charge}</b>
                                :
                                <b>{x.total_admin_charge.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Admin Charge:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {x.admin_charge_paid === '' ?
                                <b>{x.admin_charge_paid}</b>
                                :
                                <b>{x.admin_charge_paid.toFixed(2)}</b>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <br />
                        <Grid item xs={6} sm={3}>
                            Last Updated By:
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            Last Updated At:
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <b>{x.updated_at}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                    >
                        Edit
                    </Button>
                </Box>
            </div>
        )
    }
}

class Grid2EditComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            percentage: '',

            //Text Fields state
            input_total_claim: '',
            input_actual_survey_fee: '',
            input_jtu_sedia_payment: '',
            input_charge_percent: '',
            input_total_admin_charge: '',
            input_admin_charge_paid: '',
            input_transportation: '',
            input_disbursement: ''
        }
    }

    componentDidMount() {
        this.setState({
            percentage: this.props.section1_data[0]['percentage'],

            input_total_claim: this.props.data[0]['total_claim'] === '' ? '' : this.props.data[0]['total_claim'].toFixed(2),
            input_actual_survey_fee: this.props.data[0]['actual_survey_fee'] === '' ? '' : this.props.data[0]['actual_survey_fee'].toFixed(2),
            input_jtu_sedia_payment: this.props.data[0]['jtu_sedia_payment'] === '' ? '' : this.props.data[0]['jtu_sedia_payment'].toFixed(2),
            input_charge_percent: this.props.data[0]['admin_charge_percent'],
            input_total_admin_charge: this.props.data[0]['total_admin_charge'] === '' ? '' : this.props.data[0]['total_admin_charge'].toFixed(2),
            input_admin_charge_paid: this.props.data[0]['admin_charge_paid'] === '' ? '' : this.props.data[0]['admin_charge_paid'].toFixed(2),
            input_transportation: this.props.data[0]['transportation'] === '' ? '' : this.props.data[0]['transportation'].toFixed(2),
            input_disbursement: this.props.data[0]['disbursement'] === '' ? '' : this.props.data[0]['disbursement'].toFixed(2),
        })
    }

    // onChangeHandler = (event) => {
    //     if (this.state.input_actual_survey_fee === '') {
    //         var actual_survey_fee = 0
    //     }
    //     else {
    //         actual_survey_fee = this.state.input_actual_survey_fee
    //     }
    //     let amount = (actual_survey_fee * (this.state.percentage / 100)) * (event.target.value / 100)
    //     this.setState({
    //         input_charge_percent: event.target.value,
    //         input_total_admin_charge: amount.toFixed(2)
    //     })
    // }

    // onChangeHandler_calculation = (event) => {
    //     if (this.state.input_charge_percent === '') {
    //         var charge_percent = 0
    //     }
    //     else {
    //         charge_percent = this.state.input_charge_percent / 100
    //     }
    //     let amount = (event.target.value * (this.state.percentage / 100)) * charge_percent
    //     this.setState({
    //         input_actual_survey_fee: event.target.value,
    //         input_total_admin_charge: amount.toFixed(2)
    //     })
    // }

    onChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onChangeHandler_decimal = (event) => {
        const re = /^\d*[.]?\d*$/

        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ [event.target.name]: event.target.value })
        }
    }

    submitHandler = () => {
        this.props.action(
            this.state.input_total_claim,
            this.state.input_actual_survey_fee,
            this.state.input_jtu_sedia_payment,
            this.state.input_charge_percent,
            this.state.input_total_admin_charge,
            this.state.input_admin_charge_paid,
            this.state.input_transportation,
            this.state.input_disbursement
        )
    }

    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.form_id}>

                        <Grid item xs={12} sm={4}>
                            Total Claim:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_total_claim"
                                value={this.state.input_total_claim}
                                inputProps={{ maxLength: 10 }}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            Actual Survey Fee:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_actual_survey_fee"
                                value={this.state.input_actual_survey_fee}
                                inputProps={{ maxLength: 10 }}
                                // onChange={this.onChangeHandler_calculation}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            Transportation:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_transportation"
                                value={this.state.input_transportation}
                                inputProps={{ maxLength: 10 }}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            Disbursement:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_disbursement"
                                value={this.state.input_disbursement}
                                inputProps={{ maxLength: 10 }}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            JTU/SEDIA Payment Amount:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_jtu_sedia_payment"
                                value={this.state.input_jtu_sedia_payment}
                                inputProps={{ maxLength: 10 }}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            Charge %:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectInputComponent
                                variant="outlined"
                                name="input_charge_percent"
                                value={this.state.input_charge_percent}
                                onChange={this.onChangeHandler}
                                data={this.props.charge_percent}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            Total Admin Charge:
                            {/* <br />
                            <br />
                            (**This field will be calculated automatically) */}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_total_admin_charge"
                                value={this.state.input_total_admin_charge}
                                inputProps={{ maxLength: 10 }}
                                onChange={this.onChangeHandler_decimal}
                                // disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            Admin Charge:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ControlledTextInput
                                name="input_admin_charge_paid"
                                value={this.state.input_admin_charge_paid}
                                inputProps={{ maxLength: 10 }}
                                onChange={this.onChangeHandler_decimal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>

                        <br />
                        <Grid item xs={6} sm={3}>
                            Last Updated By:
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <b>{x.updated_by}</b>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            Last Updated At:
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <b>{x.updated_at}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled
                            className={this.props.classNameButton}
                        >
                            Updating...
                            <CircularProgress size={24} />
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled
                            className={this.props.classNameButton}
                        >
                            ...
                            <CircularProgress size={24} />
                        </Button>
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            className={this.props.classNameButton}
                            onClick={this.submitHandler}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </div>
        )
    }
}

class Section2Component extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        total_claim, actual_survey_fee, jtu_sedia_payment,
        charge_percent, total_admin_charge, admin_charge_paid,
        transportation, disbursement
    ) => {
        this.props.action(
            total_claim,
            actual_survey_fee,
            jtu_sedia_payment,
            charge_percent,
            total_admin_charge,
            admin_charge_paid,
            transportation,
            disbursement
        )
    }

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    Survey Fee Details
                </Typography>

                {this.state.viewState === true ?
                    <React.Fragment>
                        <Grid1Component
                            className={classes.padding}
                            data={this.props.data}
                            classNameButton={classes.button}
                            onClick={this.viewStateHandler}
                        />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Grid2EditComponent
                            className={classes.padding}
                            section1_data={this.props.section1_data}
                            data={this.props.data}
                            charge_percent={this.props.charge_percent}
                            classNameButton={classes.button}
                            loading={this.props.loading}
                            action={this.submitHandler}
                            onClick={this.viewStateHandler}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);