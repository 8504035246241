import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back to Supporting Document Form
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Box pt={4}
                    className={classes.box}>

                    {localStorage.getItem('perm') === "2" ?
                        <div>
                            <BackIdleButton className={classes.button}
                                onClick={this.props.action2} />
                        </div>
                        :

                        <React.Fragment>
                            <Typography variant="subtitle1">
                                If everything is entered correctly, click "Submit" to submit the application.
                            </Typography>
                            <br />

                            {this.props.loading ?
                                <div>
                                    <NextLoadButton className={classes.button} />
                                    <BackLoadButton className={classes.button} />
                                </div>
                                :
                                <div>
                                    <NextIdleButton className={classes.button}
                                        onClick={this.props.action} />
                                    <BackIdleButton className={classes.button}
                                        onClick={this.props.action2} />
                                </div>
                            }
                        </React.Fragment>
                    }
                </Box>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);