import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericIdleTextField from '../../Util/GenericIdleTextField';
import GenericNumberTextInput from '../../Util/GenericNumberTextInput';

import Section2PopoverComponent from './Section2PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section2Component extends React.Component {

    render() {
        const { classes } = this.props

        const arrayUI = (
            <div style={{ flexGrow: 1 }}>
                {this.props.array.map((e1, i) => (
                    <Grid container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className={classes.padding}
                        key={i}
                        style={{ border: '1px solid' }}>

                        <Grid item xs={12} sm={1}>
                            <p>{i + 1}</p>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericNumberTextInput
                                label="Floor Area Per Unit"
                                value={this.props.value1[i]}
                                inputProps={{ maxLength: 7 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericNumberTextInput
                                label="Number of Lots"
                                value={this.props.value2[i]}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericIdleTextField
                                label="Amount"
                                name="section2_array_amount"
                                value={this.props.arrayAmount[i]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>
                    </Grid>
                ))}
            </div>
        )

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 2: Charge for preparation of proposed index and storey plan
                    </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Please fill in the floor area for each unit.
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section2PopoverComponent />
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    {arrayUI}
                </AccordionDetails>

                <AccordionDetails>

                </AccordionDetails>
                <Grid container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.padding}
                >
                    <Grid item xs={12} sm={3}>
                        <GenericIdleTextField
                            label="Amount"
                            name="section2_amount"
                            value={this.props.valueTotal}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);