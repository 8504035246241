import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import { colors } from '@material-ui/core'

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        color: colors.green[500]
    }
})

class Section1Component extends React.Component {

    openFile = (value) => {
        window.open(value, "_blank")
    }

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Typography variant="h6"
                    gutterBottom
                    className={classes.padding}>
                    Survey Job Details
                </Typography>

                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={classes.padding}
                        key={x.ls_reference}>

                        <Grid item xs={12} sm={4}>
                            Licensed Surveyor Reference:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ls_reference}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Year:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.year}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Project Name:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.project_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Package No.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.package_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            PT No. (if any):
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.pt_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            District:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.dist_code}&nbsp;{x.dist_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Contract Sum:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.contract_sum.toFixed(2)}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            RSP No.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.rsp_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Percentage:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.percentage}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <br />

                <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Supporting Documents
                    </Typography>

                <TableContainer>
                    <Table>
                        <TableBody>
                            {this.props.file.map(x => (
                                <TableRow key={x.id}>
                                    <TableCell>
                                        <b>{x.filename}</b>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained"
                                            className={classes.button}
                                            onClick={() => this.openFile(x.url)}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);