import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class Grid1Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    {this.props.image.map(x => (
                        <img src={x.url} key={x.id} alt=""
                            style={{ width: '250px', height: '250px' }} />
                    ))}
                </Grid>
            </React.Fragment>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.graduate_name}>

                        <Grid item xs={12} sm={4}>
                            Articled Graduate Name:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.graduate_name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Identity Card Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ic_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Home Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.home_address}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Office Address:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.office_address}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.qualification}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Practical Experience:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.prac_exp}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Remarks:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.remark}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </div>
        )
    }
}

class Section1Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1 - Articled Graduate Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Articled Graduate Details
                    </Typography>
                </AccordionDetails>

                {!this.props.image.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            image={this.props.image}
                        />
                    </AccordionDetails>
                }

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid2Component
                            className={classes.padding}
                            data={this.props.data}
                        />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);