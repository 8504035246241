import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    button: {
        fontWeight: 'bold'
    }
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Divider />
                <br />
                <Grid item xs={12} className={classes.root}>
                    <Typography variant="h4">
                        JTU Privatised Survey Job Details
                    </Typography>
                    <br />
                    <Typography variant="subtitle1">
                        Please select a record to view the details of the survey job.
                    </Typography>

                    <br />
                    <Button className={classes.button}
                        variant="contained" color="primary"
                        onClick={this.props.action}>
                        View Timeline
                    </Button>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HeaderComponent);