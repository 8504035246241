import React from 'react';

import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh';

class ResetButtonComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                startIcon={<RefreshIcon />}
                onClick={this.props.onClick}
            >
                Reset
            </Button>
        )
    }
}

export default ResetButtonComponent;