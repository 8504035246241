import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: 'center'
    }
})

class HeaderComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Divider />
                <br />
                <Grid item xs={12} className={classes.root}>
                    <Typography variant="h3">
                        Table 12
                    </Typography>
                    <Typography variant="h6">
                        Survey of Land for Mining
                    </Typography>
                    <Typography variant="subtitle1">
                        The survey fees for the survey of land for the purpose of mining lease shall be as follows:
                    </Typography>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(HeaderComponent);