import React from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../../Config';
import { ResponseStatusCheck } from '../../../../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class Table14 extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            backdrop: false
        }
    }

    componentDidMount() {
        //if there is no form id value in session storage
        if (sessionStorage.getItem('form_id_view') === '' || sessionStorage.getItem('form_id_view') === undefined ||
            sessionStorage.getItem('form_id_view') === null) {
            this.props.history.push({
                pathname: '/ui/SurveyorHome'
            })
        }
        else {
            this.setState({ backdrop: true })
            this.fetchData()
        }
    }

    fetchData = () => {
        axios.put(Config() + '/ls_surveyjob/engineering/application/sftable_14/get',
            {
                'form_id': sessionStorage.getItem('form_id_view')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    if (!response.data[1].length) {
                        console.log("New calculator.")
                        this.setState({ backdrop: false })
                    }
                    else {
                        let input_1 = response.data[1][0]['input_1']
                        let input_2 = response.data[1][0]['input_2']

                        this.setState({ backdrop: false })

                        this.ComponentUI.current.getData(
                            input_1, input_2
                        )
                    }
                }
            })
            .catch(error => {
                this.setState({ backdrop: false })
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/EngineeringTableSummaryView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <br />
                <FormComponent
                    action={this.goBack}
                    ref={this.ComponentUI} />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Table14));