import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 350
    },
})

class FirstReviewButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                First Checker Review
            </Button>
        )
    }
}

class SecondReviewButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Second Checker Review
            </Button>
        )
    }
}

class FirstPaymentReviewButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            className={this.props.className}
            onClick={this.props.onClick}
            >
                First Checker Payment Review
            </Button>
        )
    }
}

class SecondPaymentReviewButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            className={this.props.className}
            onClick={this.props.onClick}
            >
                Second Checker Payment Review
            </Button>
        )
    }
}

class ESPReviewButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            className={this.props.className}
            onClick={this.props.onClick}
            >
                Add ESP No.
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                {!this.props.stage_data.length ?
                <Box pt={4}
                className={classes.box}>

                    <BackIdleButton
                    className={classes.button}
                    onClick={this.props.action} 
                    />
                </Box>
                :
                (this.props.stage_data[0]['curr_stage'] === "RES" || 
                this.props.stage_data[0]['curr_stage'] === "APP")
                && this.props.stage_data[0]['status'] === "Pending" ?

                    <Box pt={4}
                        className={classes.box}>

                        <FirstReviewButton
                            className={classes.button}
                            onClick={this.props.action1}
                        />

                        <SecondReviewButton
                            className={classes.button}
                            onClick={this.props.action2}
                        />

                        <BackIdleButton
                            className={classes.button}
                            onClick={this.props.action} 
                            />
                    </Box>

                    :
                    
                (this.props.stage_data[0]['curr_stage'] === "DEP" ||
                this.props.stage_data[0]['curr_stage'] === "RED") 
                && this.props.stage_data[0]['status'] === "Pending" ?

                <Box pt={4} 
                className={classes.box}>

                    <FirstPaymentReviewButton
                    className={classes.button}
                    onClick={this.props.action3}
                    />

                    <SecondPaymentReviewButton
                    className={classes.button}
                    onClick={this.props.action4}
                    />

                    <BackIdleButton
                    className={classes.button}
                    onClick={this.props.action} 
                    />
                </Box>

                :

                this.props.stage_data[0]['curr_stage'] === "DEP" && this.props.stage_data[0]['status'] === "Approved" ?

                <Box pt={4}
                className={classes.box}>

                    <ESPReviewButton
                    className={classes.button}
                    onClick={this.props.action5}
                    />

                    <BackIdleButton
                    className={classes.button}
                    onClick={this.props.action} 
                    />
                </Box>

                :

                <Box pt={4}
                className={classes.box}>

                    <BackIdleButton
                    className={classes.button}
                    onClick={this.props.action} 
                    />
                </Box>
                    }
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);