import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import { colors } from '@material-ui/core'

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        color: colors.green[500]
    }
})

class Table1Component extends React.Component {
    render() {
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        {this.props.data.map(x => (
                            <TableRow key={x.id}>
                                <TableCell>
                                    <b>{x.filename}</b>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained"
                                    className={this.props.className}
                                    onClick={() => this.props.onClick(x.url)}>
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

class Section5Component extends React.Component {

    openFile = (value) => {
        window.open(value, "_blank")
    }
    
    render() {
        const { classes } = this.props
        
        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 5 - Payment Documents
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                <AccordionDetails>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    <Typography
                                    variant="subtitle1">
                                        No data here...
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>
                :
                <AccordionDetails>
                    <Table1Component
                    className={classes.button}
                    data={this.props.data}
                    onClick={this.openFile}
                    />
                    </AccordionDetails>
                    }
                </Accordion>
        )
    }
}

export default withStyles(styles, {withTheme: true})(Section5Component);