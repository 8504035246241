import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericIdleTextField from '../../Util/GenericIdleTextField';
import GenericTextInput from '../../Util/GenericTextInput';
import GenericSelectTextInput from '../../Util/GenericSelectTextInput';
import GenericNumberTextInput from '../../Util/GenericNumberTextInput';

import Section3PopoverComponent from './Section3PopoverComponent';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
})

class Section3Component extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [
                {
                    id: '1',
                    value: '1',
                    text: 'Flat to undulating below 15 degree of slope'
                },
                {
                    id: '2',
                    value: '2',
                    text: 'Hilly above 15 degree of slope'
                },
                {
                    id: '3',
                    value: '3',
                    text: 'Fresh water swamp'
                },
                {
                    id: '4',
                    value: '4',
                    text: 'Tidal swamp'
                },
            ]
        }
    }

    render() {
        const { classes } = this.props

        const arrayUI = (
            <div style={{ flexGrow: 1 }}>
                {this.props.array.map((e1, i) => (
                    <Grid container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className={classes.padding}
                        key={i}
                        style={{ border: '1px solid' }}>

                        <Grid item xs={12} sm={1}>
                            <p>{i + 1}</p>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericTextInput
                                label="Description"
                                value={this.props.value1[i]}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GenericNumberTextInput
                                label="Metre/s"
                                value={this.props.value2[i]}
                                inputProps={{ maxLength: 7 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericSelectTextInput
                                label="Type of Land"
                                name="section3_select"
                                value={this.props.value3[i]}
                                data={this.state.data}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <GenericIdleTextField
                                label="Amount"
                                name="section3_array_amount"
                                value={Number.parseFloat(this.props.arrayAmount[i]).toFixed(2)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>
                    </Grid>
                ))}
            </div>
        )

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 3: Charge for traverse lines
                    </Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>
                    <Grid container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        className={classes.padding}>
                        <Grid item xs={12} sm={10}>
                            Charge for traverse lines for azimuth, connections, controls and sections of boundary lines (including road reserves, riparian reserves, drain reserves, etc., which fall within or form the survey lot):
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Section3PopoverComponent />
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    {arrayUI}
                </AccordionDetails>

                <AccordionDetails>

                </AccordionDetails>
                <Grid container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.padding}
                >
                    <Grid item xs={12} sm={3}>

                        <GenericIdleTextField
                            label="Amount"
                            name="section3_amount"
                            value={this.props.valueTotal}
                        />
                    </Grid>
                </Grid>
                <AccordionDetails>

                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section3Component);