import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

class YesIdleButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            onClick={this.props.onClick}
            >
                Yes
            </Button>
        )
    }
}

class NoIdleButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="secondary"
            onClick={this.props.onClick}
            >
                No
            </Button>
        )
    }
}

class YesLoadButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="primary"
            disabled={this.props.disabled}
            >
                Deleting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class NoLoadButton extends React.Component {
    render() {
        return (
            <Button
            variant="contained"
            color="secondary"
            disabled={this.props.disabled}
            >
                ...
            </Button>
        )
    }
}

class DialogComponent extends React.Component {
    render() {
        return (
            <Dialog
                onClose={this.props.action2} 
                open={this.props.open}>
                <DialogTitle>
                    Remove this table?
            </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove the selected table?
                </DialogContentText>
                </DialogContent>

                {this.props.loading ?

                <DialogActions>
                    <YesLoadButton disabled={this.props.loading} />
                    <NoLoadButton disabled={this.props.loading} />
                </DialogActions>
                
                :

                <DialogActions>
                    <YesIdleButton onClick={this.props.action} />
                    <NoIdleButton onClick={this.props.action2} />
                </DialogActions>
            }
            </Dialog>
        )
    }
}

export default DialogComponent;