import React from 'react'

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2),
        maxWidth: 700
    },
    table: {
        borderBottom: "none"
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
})

class Section3PopoverComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            anchor: null
        }
    }

    toggleHandler = e => {
        this.setState({
            open: !this.state.open,
            anchor: e.currentTarget
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.toggleHandler}
                >
                    Reference
                </Button>

                <Popover
                    open={this.state.open}
                    onClose={this.toggleHandler}
                    anchorEl={this.state.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.padding}>
                    <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.table}>Charge for traverse lines for azimuth, connections, controls and sections of boundary lines (including road reserves, riparian reserves, drain reserves, etc., which fall within or form the survey lot):</TableCell>
                                        <TableCell className={classes.table}></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right" style={{ width: 200 }}>RM</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.table}>a) Flat to undulating below 15 degree of slope</TableCell>
                                        <TableCell className={classes.table} align="right">3.00 per metre</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.table}>b) Hilly above 15 degree of slope or for developed land where buildings, walls, fences, hedges, etc have been put along any of the boundaries</TableCell>
                                        <TableCell className={classes.table} align="right">3.75 per metre</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.table}>c) Fresh water swamp</TableCell>
                                        <TableCell className={classes.table} align="right">6.00 per metre</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className={classes.table}>d) Tidal swamp</TableCell>
                                        <TableCell className={classes.table} align="right">7.50 per metre</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section3PopoverComponent);