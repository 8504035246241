import React from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../../Util/RequiredTextInput';
import DateInputComponent from '../../Util/DateInputComponent';
import RequiredTooltipTextInput from '../../Util/RequiredTooltipTextInput';
import GenericTextInput from '../../Util/GenericTextInput';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    },
    input: {
        display: 'none',
    },
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_birth_date: new Date(),
            input_prac_date: new Date(),

            file_object_1: [],
            // file_object_1: '',
            file_object_2: [],
            file_object_3: [],
            file_name_1: [],
            // file_name_1: '',
            file_name_2: [],
            file_name_3: [],
            file_size_1: [],
            // file_size_1: '',
            file_size_2: [],
            file_size_3: [],

            shrink: true,

            error_msg: ''
        }
    }

    //Added initialization function on 11/10/2021
    initializeHandler = (data) => {
        this.input_technician_name.value = data[0]['name']
        this.input_ic_no.value = data[0]['ic_no']
        this.input_birth_place.value = data[0]['birth_place']
        this.input_nationality.value = data[0]['nationality']
        this.input_prev_regtech.value = data[0]['prev_registration_no']
        this.input_academic_qualification.value = data[0]['academic_qualification']
        this.input_technical_qualification.value = data[0]['technical_qualification']
        this.input_prev_emp.value = data[0]['prev_emp']
        this.input_prac_exp.value = data[0]['prac_exp']
        this.input_remark.value = data[0]['remark']

        this.setState({
            input_birth_date: new Date(data[0]['birth_date']),
            input_prac_date: new Date(data[0]['prac_date'])
        })
    }

    onChangeHandler_birth_date = date => {
        this.setState({ input_birth_date: date })
    }

    onChangeHandler_prac_date = date => {
        this.setState({ input_prac_date: date })
    }

    generateDocument = () => {
        if (!this.state.file_name_1.length) {
            this.errorHandler("Please upload a passport size photo for the application.")
        }
        else {
            var formatted_birth_date = ""
            var formatted_prac_date = ""

            if (!this.state.input_birth_date) {
                formatted_birth_date = ""
            }
            else if (!this.state.input_prac_date) {
                formatted_prac_date = ""
            }
            else {
                formatted_birth_date = (this.state.input_birth_date).getDate() + '/' + ((this.state.input_birth_date).getMonth() + 1) + '/' + (this.state.input_birth_date).getFullYear()
                formatted_prac_date = (this.state.input_prac_date).getDate() + '/' + ((this.state.input_prac_date).getMonth() + 1) + '/' + (this.state.input_prac_date).getFullYear()
            }

            this.props.action2(
                this.input_technician_name.value,
                this.input_ic_no.value,
                formatted_birth_date,
                this.input_birth_place.value,
                this.input_nationality.value,
                this.input_academic_qualification.value,
                this.input_technical_qualification.value,
                this.input_prev_emp.value,
                formatted_prac_date,
                this.input_prac_exp.value,
                this.input_remark.value,
                this.input_prev_regtech.value,
                this.state.file_object_1,
                this.state.file_name_1
            )
        }
    }

    uploadHandler_1 = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object_1: [...prevState.file_object_1, file],
                file_name_1: [...prevState.file_name_1, file.name],
                file_size_1: [...prevState.file_size_1, file.size]
            }))
        })
    }

    removeHandler_1 = (i) => {
        let file_object_1 = [...this.state.file_object_1]
        let file_name_1 = [...this.state.file_name_1]
        let file_size_1 = [...this.state.file_size_1]
        file_object_1.splice(i, 1)
        file_name_1.splice(i, 1)
        file_size_1.splice(i, 1)
        this.setState({ file_object_1, file_name_1, file_size_1 })
    }

    // uploadHandler_1 = event => {
    //     let file = event.currentTarget.files[0]
    //     this.setState({
    //         file_object_1: file,
    //         file_name_1: file.name,
    //         file_size_1: file.size
    //     })
    // }

    // removeHandler_1 = () => {
    //     this.setState({
    //         file_object_1: '',
    //         file_name_1: '',
    //         file_size_1: ''
    //     })
    // }

    uploadHandler_2 = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object_2: [...prevState.file_object_2, file],
                file_name_2: [...prevState.file_name_2, file.name],
                file_size_2: [...prevState.file_size_2, file.size]
            }))
        })
    }

    removeHandler_2 = (i) => {
        let file_object_2 = [...this.state.file_object_2]
        let file_name_2 = [...this.state.file_name_2]
        let file_size_2 = [...this.state.file_size_2]
        file_object_2.splice(i, 1)
        file_name_2.splice(i, 1)
        file_size_2.splice(i, 1)
        this.setState({ file_object_2, file_name_2, file_size_2 })
    }

    uploadHandler_3 = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object_3: [...prevState.file_object_3, file],
                file_name_3: [...prevState.file_name_3, file.name],
                file_size_3: [...prevState.file_size_3, file.size]
            }))
        })
    }

    removeHandler_3 = (i) => {
        let file_object_3 = [...this.state.file_object_3]
        let file_name_3 = [...this.state.file_name_3]
        let file_size_3 = [...this.state.file_size_3]
        file_object_3.splice(i, 1)
        file_name_3.splice(i, 1)
        file_size_3.splice(i, 1)
        this.setState({ file_object_3, file_name_3, file_size_3 })
    }

    submitHandler = () => {
        this.setState({ loading: true })

        this.setState({
            error_technician_name: this.input_technician_name.value ? false : true,
            error_ic_no: this.input_ic_no.value ? false : true,
            error_birth_place: this.input_birth_place.value ? false : true,
            error_nationality: this.input_nationality.value ? false : true,
            error_academic_qualification: this.input_academic_qualification.value ? false : true,
            error_technical_qualification: this.input_technical_qualification.value ? false : true,
            error_prac_exp: this.input_prac_exp.value ? false : true,
            error_birth_date: this.state.input_birth_date && this.state.input_birth_date !== new Date() ? false : true,
            error_prac_date: this.state.input_prac_date && this.state.input_prac_date !== new Date() ? false : true
        })

        if (this.input_technician_name.value === '' ||
            this.input_ic_no.value === '' ||
            this.input_birth_place.value === '' ||
            this.input_nationality.value === '' ||
            this.input_academic_qualification.value === '' ||
            this.input_technical_qualification.value === '' ||
            this.input_prac_exp.value === '' ||

            this.state.input_birth_date === '' ||
            this.state.input_birth_date === new Date() ||
            this.state.input_birth_date === null ||
            this.state.input_prac_date === '' ||
            this.state.input_prac_date === new Date() ||
            this.state.input_prac_date === null) {

            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            if (
                !this.state.file_name_1.length ||
                // !this.state.file_name_1 ||
                !this.state.file_name_2.length ||
                !this.state.file_name_3.length) {

                this.errorHandler("Please upload the relevant documents for the cadastral survey technician.")
            }

            else {
                if (
                    Number(this.state.file_size_1.reduce((prev, next) =>
                    (Number(prev) + Number(next)), 0)) +
                    // Number(this.state.file_object_1) +
                    Number(this.state.file_size_2.reduce((prev, next) =>
                        (Number(prev) + Number(next)), 0)) +
                    Number(this.state.file_size_3.reduce((prev, next) =>
                        (Number(prev) + Number(next)), 0)) >= 50000000) {

                    this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
                }

                else {
                    var formatted_birth_date = (this.state.input_birth_date).getDate() + '/' + ((this.state.input_birth_date).getMonth() + 1) + '/' + (this.state.input_birth_date).getFullYear()
                    var formatted_prac_date = (this.state.input_prac_date).getDate() + '/' + ((this.state.input_prac_date).getMonth() + 1) + '/' + (this.state.input_prac_date).getFullYear()

                    this.props.action(
                        this.input_technician_name.value,
                        this.input_ic_no.value,
                        this.input_birth_place.value,
                        this.input_nationality.value,
                        this.input_academic_qualification.value,
                        this.input_technical_qualification.value,
                        this.input_prev_emp.value,
                        this.input_prac_exp.value,
                        this.input_remark.value,
                        formatted_birth_date,
                        formatted_prac_date,
                        this.input_prev_regtech.value,
                        this.state.file_object_1,
                        this.state.file_name_1,
                        this.state.file_object_2,
                        this.state.file_name_2,
                        this.state.file_object_3,
                        this.state.file_name_3
                    )
                }
            }
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Paper elevation={3} className={classes.paper}>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                <br />

                <Typography variant="h6">
                    Technician Information
                    </Typography>
                <br />
                <form noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_technician_name}
                                label="Name of Survey Technician (in Capital Letter)"
                                name="input_technician_name"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_technician_name = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RequiredTextInput
                                error={this.state.error_ic_no}
                                label="Identity Card Number"
                                name="input_ic_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ic_no = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DateInputComponent
                                label="Date of Birth (dd/mm/yyyy)"
                                name="input_birth_date"
                                value={this.state.input_birth_date}
                                onChange={this.onChangeHandler_birth_date}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RequiredTextInput
                                error={this.state.error_birth_place}
                                label="Place of Birth"
                                name="input_birth_place"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_birth_place = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RequiredTextInput
                                error={this.state.error_nationality}
                                label="Nationality"
                                name="input_nationality"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_nationality = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Registered Technician No. (If previously registered)"
                                name="input_prev_regtech"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_prev_regtech = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTooltipTextInput
                                error={this.state.error_academic_qualification}
                                label="Academic Qualification"
                                name="input_academic_qualification"
                                inputProps={{ maxLength: 200 }}
                                tooltip={classes.tooltip}
                                title="Please type in the academic qualification with years. (e.g. Diploma in Land Surveying, 2018)"
                                inputRef={node => (this.input_academic_qualification = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTooltipTextInput
                                error={this.state.error_technical_qualification}
                                label="Technical Qualification"
                                name="input_technical_qualification"
                                inputProps={{ maxLength: 200 }}
                                tooltip={classes.tooltip}
                                title="Please type in the technical qualification with dates (Relevant to Survey Technician). (e.g. Survey Course, 2018)"
                                inputRef={node => (this.input_technical_qualification = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Particular of Previous Employment (if any)"
                                name="input_prev_emp"
                                inputProps={{ maxLength: 200 }}
                                inputRef={node => (this.input_prev_emp = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateInputComponent
                                label="Date of Commencement of Service with present surveyor"
                                name="input_prac_date"
                                value={this.state.input_prac_date}
                                onChange={this.onChangeHandler_prac_date}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredTextInput
                                error={this.state.error_prac_exp}
                                label="Practical Experience"
                                name="input_prac_exp"
                                inputProps={{ maxLength: 200 }}
                                inputRef={node => (this.input_prac_exp = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericTextInput
                                label="Remarks"
                                name="input_remark"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_remark = node)}
                                InputLabelProps={{ shrink: this.state.shrink }}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h6">Upload Documents</Typography>
                    <Typography variant="caption">**Please re-upload the files when editing the application.</Typography>
                    <br />
                    <p>1. Passport Size Photo</p>
                    <input
                        accept="image/*,application/pdf"
                        className={classes.input}
                        id="contained-button-file_1"
                        multiple
                        type="file"
                        onChange={this.uploadHandler_1}
                    />
                    <label htmlFor="contained-button-file_1">
                        <Button variant="contained" color="primary" component="span"
                            className={classes.button}>
                            Upload
        </Button>
                    </label>
                    <Table aria-label="simple table">
                        <TableBody>
                            {/* {!this.state.file_name_1 ?
                                <TableRow>
                                    <TableCell>No files uploaded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                        {this.state.file_name_1}
                                    </TableCell>
                                    <TableCell style={{ width: '50%' }}>
                                        <IconButton color="secondary"
                                            onClick={() => this.removeHandler_1}>
                                            <CloseIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            } */}

                            {this.state.file_name_1.length === 0 ?
                                <TableRow>
                                    <TableCell>No files uploaded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                this.state.file_name_1.map((x, i) => (
                                    <TableRow key={x}>
                                        <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                        <TableCell style={{ width: '50%' }}>
                                            <IconButton color="secondary"
                                                onClick={() => this.removeHandler_1(i)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    <Divider />
                    <br />
                    <p>2. Relevant Certificates</p>
                    <input
                        accept="image/*,application/pdf"
                        className={classes.input}
                        id="contained-button-file_2"
                        multiple
                        type="file"
                        onChange={this.uploadHandler_2}
                    />
                    <label htmlFor="contained-button-file_2">
                        <Button variant="contained" color="primary" component="span"
                            className={classes.button}>
                            Upload
        </Button>
                    </label>
                    <Table aria-label="simple table">
                        <TableBody>
                            {this.state.file_name_2.length === 0 ?
                                <TableRow>
                                    <TableCell>No files uploaded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                this.state.file_name_2.map((x, i) => (
                                    <TableRow key={x}>
                                        <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                        <TableCell style={{ width: '50%' }}>
                                            <IconButton color="secondary"
                                                onClick={() => this.removeHandler_2(i)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    <br />
                    <Grid container
                        direction="row"
                        spacing={2}>
                        <Grid item xs={8}>
                            <p>3. Specimen Signature of Survey Technician (LJS 9B)</p>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained"
                                color="primary"
                                onClick={this.generateDocument}>
                                Generate
                    </Button>
                        </Grid>
                    </Grid>

                    <p>Please generate the file, download, sign the document and upload the document here.</p>
                    <input
                        accept="image/*,application/pdf"
                        className={classes.input}
                        id="contained-button-file_3"
                        multiple
                        type="file"
                        onChange={this.uploadHandler_3}
                    />
                    <label htmlFor="contained-button-file_3">
                        <Button variant="contained" color="primary" component="span"
                            className={classes.button}>
                            Upload
        </Button>
                    </label>
                    <Table aria-label="simple table">
                        <TableBody>
                            {this.state.file_name_3.length === 0 ?
                                <TableRow>
                                    <TableCell>No files uploaded</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                this.state.file_name_3.map((x, i) => (
                                    <TableRow key={x}>
                                        <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                        <TableCell style={{ width: '50%' }}>
                                            <IconButton color="secondary"
                                                onClick={() => this.removeHandler_3(i)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </form>

                {this.state.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextLoadButton className={classes.button} />
                    </Box>

                    :

                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextIdleButton className={classes.button}
                            onClick={this.submitHandler} />
                    </Box>
                }
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);