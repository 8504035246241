import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import Routes from './Router/Routes';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: 'light'
    }
    this.toggleTheme = this.toggleTheme.bind(this)
  }


  toggleTheme = () => {
    if (localStorage.getItem('theme') === 'light') {
      localStorage.setItem('theme', 'dark')
      this.setState({ theme: 'dark' })
    } else if (localStorage.getItem('theme') === 'dark') {
      localStorage.setItem('theme', 'light')
      this.setState({ theme: 'light' })
    } else {
      localStorage.setItem('theme', 'dark')
      this.setState({ theme: 'dark' })
    }
  }
  render() {
    const theme =
      createMuiTheme({
        palette: {
          type: !localStorage.getItem('theme') ? 'light' : localStorage.getItem('theme'),
          primary: {
            main: localStorage.getItem('theme') === 'light' ? '#3f51b5' :
              localStorage.getItem('theme') === 'dark' ? '#64b5f6' :
                '#3f51b5'
          },
          secondary: {
            main: localStorage.getItem('theme') === 'light' ? '#f50057' :
              localStorage.getItem('theme') === 'dark' ? '#f48fb1' :
                '#f50057'
          }
        },

        overrides: {
          MuiListItem: {
            root: {
              "&$selected": {
                color: localStorage.getItem('theme') === 'light' ? colors.blue[500] :
                  localStorage.getItem('theme') === 'dark' ? '#64b5f6' :
                  colors.blue[500]
              }
            }
          }
        }
      })

    return (
      <ThemeProvider theme={theme}>
        {/* ToggleTheme function will be passed all the way down to 
        the AppBarComponent */}
        <Routes action={this.toggleTheme} />
      </ThemeProvider>
    )
  }
}

export default App;