import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class Grid1Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.amount.map(x => (
                    <Grid container
                    direction="row"
                    spacing={3}
                    className={this.props.className}
                    key={x.total_amount}>

                        <Grid item xs={12} sm={4}>
                            Current Transaction Amount:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.total_amount.toFixed(2)}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.id}>

                        <Grid item xs={12} sm={4}>
                            Payment Mode:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.payment_mode}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Bank:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.bank}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Cheque / Transaction No.:
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.cheque_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Transaction Reference No.:
                        </Grid>

                        {!this.props.pt_data.length ?
                            <Grid item xs={12} sm={8}>
                                <b>No Information</b>
                            </Grid>
                            :
                            <Grid item xs={12} sm={8}>
                                <b>{this.props.pt_data[0]['id']}</b>
                            </Grid>

                        }

                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
}

class Section4Component extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 4 - Payment Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Payment Details
                    </Typography>
                </AccordionDetails>

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <React.Fragment>
                        <AccordionDetails>
                            <Grid1Component
                                className={classes.padding}
                                amount={this.props.amount}
                            />
                        </AccordionDetails>

                        <AccordionDetails>
                            <Grid2Component
                                className={classes.padding}
                                data={this.props.data}
                                pt_data={this.props.pt_data}
                            />
                        </AccordionDetails>
                    </React.Fragment>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section4Component);