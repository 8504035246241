import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    box: {
        textAlign: 'center'
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    }
})

class ButtonComponent extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Box pt={4} className={classes.box}>
                <Button 
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.props.action}
                >
                    Go to Table Summary
                </Button>

                <Button 
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={this.props.action2}
                >
                    Back to Application Form
                </Button>
            </Box>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ButtonComponent);