import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    }
})

class LicensedSurveyorView extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            swift_code_data: [],
            lsUid: 0,
            backdrop: false,
            viewState: true,
            viewState_2: true
        }
    }

    componentDidMount() {
        this.setState({ backdrop: true })
        this.fetchData()
    }

    init = () => {
        window.location.reload()
    }

    fetchData = () => {
        axios.put(Config() + '/licensedsurveyor/view/get',
        {
            'ls_no': sessionStorage.getItem('ls_no_view')
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data[0]['result'] === "Success") {
                this.setState({
                    data: response.data[1],
                    swift_code_data: response.data[2],
                    backdrop: false
                })
                this.setState({
                    lsUid: this.state.data[0].ls_user_id
                })
            }
            console.log(this.state.lsUid)
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
            this.setState({
                backdrop: false
            })
        })
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    viewState_2Handler = () => {
        this.setState({
            viewState_2: !this.state.viewState_2,
            alert: false,
            error_msg: ''
        })
    }

    goBack = () => {
        sessionStorage.clear()
        this.props.history.push({
            pathname: '/ui/LicensedSurveyorList'
        })
    }

    editHandler = (
        ls_title, ls_name, ic_no, co_name, ls_status,
        email, phone, mobile, fax, addr1, addr2, addr3, addr4,
        paddr1, paddr2, paddr3, paddr4, lic_no, svc_tax,
        gl_acctno, ls_acct_no, ls_bank, ls_bank_branch, ls_payee_ind
    ) => {
        axios.put(Config() + '/licensedsurveyor/update/edit',
        {
            'ls_no': sessionStorage.getItem('ls_no_view'),
            'ls_title': ls_title,
            'ls_name': ls_name,
            'ic_no': ic_no,
            'co_name': co_name,
            'ls_status': ls_status,
            'email': email,
            'phone': phone,
            'mobile': mobile,
            'fax': fax,
            'addr1': addr1,
            'addr2': addr2,
            'addr3': addr3,
            'addr4': addr4,
            'paddr1': paddr1,
            'paddr2': paddr2,
            'paddr3': paddr3,
            'paddr4': paddr4,
            'lic_no': lic_no,
            'svc_tax': svc_tax,
            'gl_acctno': gl_acctno,
            'ls_acct_no': ls_acct_no,
            'ls_bank': ls_bank,
            'ls_bank_branch': ls_bank_branch,
            'payee_ind': ls_payee_ind
        },
        {
            withCredentials: true
        })
        .then(response => {
            if (response.data['result'] === "Success") {
                this.init()
            }
        })
        .catch(error => {
            if (!error.response) {
                this.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    //If Session Expired
                    if (ResponseStatusCheck(error) === 401) {
                        console.log("Display some window here.")
                    }
                    else {
                        this.errorHandler(ResponseStatusCheck(error))
                    }
                }
                else {
                    this.errorHandler("Something went very wrong!")
                }
            }
        })
    }

    editHandler_2 = (
        user_name, password, ls_user_id
    ) => {
        if (user_name === '' || password === '') {
            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            axios.put(Config() + '/licensedsurveyor/update/edit/account',
                {
                    'user_id': ls_user_id,
                    'user_name': user_name,
                    'password': password,
                },
                {
                    withCredentials: true
                })
                .then(response => {
                    if (response.data['result'] === "Success") {
                        this.init()
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        this.errorHandler("Code issue probably.")
                    }
                    else {
                        if (ResponseStatusCheck(error)) {
                            //If Session Expired
                            if (ResponseStatusCheck(error) === 401) {
                                console.log("Display some window here.")
                            }
                            else {
                                this.errorHandler(ResponseStatusCheck(error))
                            }
                        }
                        else {
                            this.errorHandler("Something went very wrong!")
                        }
                    }
                })
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />

                <FormComponent
                alert={this.state.alert}
                error_msg={this.state.error_msg}
                viewState={this.state.viewState}
                viewState_2={this.state.viewState_2}
                data={this.state.data}
                lsUid={this.state.lsUid}
                swift_code_data={this.state.swift_code_data}
                action={this.viewStateHandler}
                action2={this.goBack}
                action3={this.editHandler}
                action4={this.viewStateHandler}
                action5={this.viewState_2Handler}
                action6={this.editHandler_2}
                action7={this.viewState_2Handler} />

                {/* <ButtonComponent
                viewState={this.state.viewState}
                action={this.viewStateHandler}
                action2={this.goBack}
                action3={this.editHandler}
                action4={this.viewStateHandler}
                /> */}
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(LicensedSurveyorView));