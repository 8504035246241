import React from 'react';

import { NumericFormat } from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
    input: {
        display: 'none',
    },
    box: {
        textAlign: 'center'
    },
})

class GenericRequiredTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                label={this.props.label}
                name={this.props.name}
                inputProps={this.props.inputProps}
                value={this.props.value}
                onChange={this.props.onChange}
            />
        )
    }
}

class SelectTextInput extends React.Component {
    render() {
        return (
            <TextField
                required
                error={this.props.error}
                helperText={this.props.helperText}
                variant="outlined"
                margin="normal"
                fullWidth
                select
                label={this.props.label}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
                SelectProps={this.props.SelectProps}
            >
                {this.props.data.map(x => (
                    <MenuItem key={x.key}
                        value={x.value}
                        style={{ whiteSpace: 'normal' }}>
                        {x.text}
                    </MenuItem>
                ))}
            </TextField>
        )
    }
}

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button 
            variant="contained"
            color="primary"
            className={this.props.className}
            onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_payment_mode: '',
            input_bank: [],
            input_cheque: '',
            // input_ref: '',

            file_object: [],
            file_name: [],
            file_size: [],
        }
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object: [...prevState.file_object, file],
                file_name: [...prevState.file_name, file.name],
                file_size: [...prevState.file_size, file.size]
            }))
        })
    }

    removeHandler = (i) => {
        let file_object = [...this.state.file_object]
        let file_name = [...this.state.file_name]
        let file_size = [...this.state.file_size]
        file_object.splice(i, 1)
        file_name.splice(i, 1)
        file_size.splice(i, 1)
        this.setState({ file_object, file_name, file_size })
    }

    submitHandler = () => {
        this.setState({ loading: true })

        if (this.state.input_payment_mode === '' ||
        this.state.input_cheque === '' ||
        // this.state.input_ref === '' ||
        !this.state.file_name.length) {

            this.setState({ 
                error_payment_mode: this.state.input_payment_mode ? false : true,
                error_bank: (this.state.input_payment_mode === 'Cash')? false : true,
                error_cheque: this.state.input_cheque ? false : true,
                // error_ref: this.state.input_ref ? false : true,
                error_file: this.state.file_name.length ? false : true
            })
            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            this.props.action(
                this.state.input_payment_mode,
                this.state.input_bank,
                this.state.input_cheque,
                // this.state.input_ref,
                this.state.file_object,
                this.state.file_name
            )
        }
    }

    errorHandler = (value) => {
        this.setState({ 
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props

        const payment_mode = [
            {
                'key': '1',
                'value': 'Online Banking',
                'text': 'Online Banking'
            },
            {
                'key': '2',
                'value': 'Cheque',
                'text': 'Cheque'
            },
            {
                'key': '3',
                'value': 'Cash',
                'text': 'Cash'
            }
        ]

        return (
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h3">
                    Amount to be paid: RM&nbsp;
                    {this.props.amount === '' ?
                        <b>
                            <NumericFormat 
                                displayType="text" 
                                value= '0.00'
                                thousandSeparator=',' 
                                decimalSeparator='.' 
                            />
                        </b>
                        :
                        <b style={{ color: 'red' }}>
                            <NumericFormat 
                                displayType="text" 
                                value={this.props.amount.toFixed(2)}
                                thousandSeparator=',' 
                                decimalSeparator='.' 
                            />
                        </b>
                    }
                </Typography>

                <br />

                <form noValidate>

                {this.state.alert ?
                        <Alert severity="error">
                            {this.state.error_msg}
                        </Alert>
                        :
                        null}

                    <br />

                    <Grid container
                        spacing={2}>
                        <Grid item xs={12}>
                            <SelectTextInput
                                error={this.state.error_payment_mode}
                                label="Payment Mode"
                                name="input_payment_mode"
                                value={this.state.input_payment_mode}
                                onChange={this.onChangeHandler}
                                data={payment_mode}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SelectTextInput
                                error={this.state.error_bank}
                                label="Bank"
                                name="input_bank"
                                value={this.state.input_bank}
                                onChange={this.onChangeHandler}
                                SelectProps={{ multiple: true }}
                                data={this.props.bank}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <GenericRequiredTextInput
                                error={this.state.error_cheque}
                                label="Cheque / Transaction No."
                                name="input_cheque"
                                value={this.state.input_cheque}
                                onChange={this.onChangeHandler}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <GenericRequiredTextInput
                                error={this.state.error_ref}
                                label="Reference No."
                                name="input_ref"
                                value={this.state.input_ref}
                                onChange={this.onChangeHandler}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid> */}
                    </Grid>

                    <br />
                    <Divider />
                    <br />

                    <Typography variant="h6">
                        Upload Documents
                    </Typography>
                    <br />
                    <p>Please upload the proof of payments.</p>

                    <input
                        accept="image/*,application/pdf"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={this.uploadHandler}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span"
                            className={classes.button}>
                            Upload
        </Button>
                    </label>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '50%' }}>File Name</TableCell>
                                <TableCell style={{ width: '50%' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.file_name.length === 0 ?
                                <TableRow>
                                    <TableCell>No files uploaded.</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                :
                                this.state.file_name.map((x, i) => (
                                    <TableRow key={x}>
                                        <TableCell>{x}</TableCell>
                                        <TableCell>
                                            <IconButton color="secondary"
                                                onClick={() => this.removeHandler(i)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <Box pt={4}
                        className={classes.box}>

                        <Typography variant="subtitle1">
                            If everything is entered correctly, click "Submit" to submit the Payment application.
                    </Typography>
                        <br />

                        {this.state.loading ?
                            <div>
                                <NextLoadButton className={classes.button} />
                                <BackLoadButton className={classes.button} />
                            </div>
                            :
                            <div>
                                <NextIdleButton className={classes.button}
                                    onClick={this.submitHandler} />
                                <BackIdleButton className={classes.button}
                                    onClick={this.props.action2} 
                                    />
                            </div>
                        }
                    </Box>
                </form>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);