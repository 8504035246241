import React from 'react';

import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    }
})

class Review1Component extends React.Component {
    render() {
        const { classes } = this.props
        
        return (
            <div>
                <br />
                <Divider />

                {this.props.data.map(x => (
                    <div className={classes.padding}
                    key={x.status}>
                    <p>Status: {x.status}</p>
                    <p>Reviewed by: {x.reviewed_by}</p>
                    <p>Reviewed at: {x.reviewed_at}</p>
                    <p>Comment: {x.comment}</p>
                    </div>
                ))}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Review1Component);