import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import { colors } from '@material-ui/core'

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold'
    },
    buttonView: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        color: colors.green[500]
    },
    input: {
        display: 'none',
    }
})

class Table1Component extends React.Component {
    render() {
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        {this.props.data.map(x => (
                            <TableRow key={x.id}>
                                <TableCell>
                                    <b>{x.filename}</b>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained"
                                        className={this.props.classNameView}
                                        onClick={() => this.props.onClick(x.url)}>
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <input
                                    accept="image/*"
                                    className={this.props.classNameInput}
                                    id="contained-button-file-1"
                                    type="file"
                                    onChange={this.props.onChange}
                                />
                                <label htmlFor="contained-button-file-1">
                                    <Button variant="contained"
                                        color="primary"
                                        component="span"
                                        className={this.props.className}>
                                        Re-upload
                                    </Button>
                                </label>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

class TableNoDataComponent extends React.Component {
    render() {
        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Typography
                                    variant="subtitle1">
                                    No image here...
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">
                                <input
                                    accept="image/*"
                                    className={this.props.classNameInput}
                                    id="contained-button-file-1"
                                    type="file"
                                    onChange={this.props.onChange}
                                />
                                <label htmlFor="contained-button-file-1">
                                    <Button variant="contained"
                                        color="primary"
                                        component="span"
                                        className={this.props.className}>
                                        Re-upload
                                    </Button>
                                </label>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

class Section2Component extends React.Component {

    openFile = (value) => {
        window.open(value, "_blank")
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        var file_object_array = []
        var file_name_array = []
        var file_size_array = []

        Array.from(file).forEach(file => {
            file_object_array.push(file)
            file_name_array.push(file.name)
            file_size_array.push(file.size)
        })

        this.props.action(
            file_object_array,
            file_name_array,
            file_size_array
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 2 - Passport Size Photo
                    </Typography>
                </AccordionSummary>
                <Divider />

                {!this.props.data.length ?
                    <AccordionDetails>
                        <TableNoDataComponent
                            className={classes.button}
                            classNameInput={classes.input}
                            onChange={this.uploadHandler} />
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Table1Component
                            className={classes.button}
                            classNameView={classes.buttonView}
                            classNameInput={classes.input}
                            data={this.props.data}
                            onClick={this.openFile}
                            onChange={this.uploadHandler} />
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section2Component);