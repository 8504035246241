import React from 'react'

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';

import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000
    },
    tablecell: {
        fontWeight: 'bold',
        width: '10%'
    }
})

class TableComponent extends React.Component {
    render() {
        const { classes } = this.props

        const cellHeader = [
            {
                id: "year",
                label: "Year"
            },
            {
                id: "bf_point",
                label: "Points b/f"
            },
            {
                id: "approved_point",
                label: "Points Approved"
            },
            {
                id: "total_point",
                label: "Total Points"
            },
            {
                id: "exemption_point",
                label: "Exemption"
            },
            {
                id: "mandatory_point",
                label: "Mandatory Points"
            },
            {
                id: "excess_point",
                label: "Excess Point"
            },
            {
                id: "cf_point",
                label: "Points c/f (max 5 points)"
            }
        ]

        return (
            <Paper className={classes.paper}>
                {this.props.alert ?
                    <Alert severity="error">
                        {this.props.error_msg}
                    </Alert>
                    :
                    null}

                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {cellHeader.map(cellHeader => (
                                    <TableCell className={classes.tablecell}
                                        key={cellHeader.id}
                                        padding="default"
                                        align="center"
                                    >
                                        {cellHeader.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {!this.props.data.length ?
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center"
                                        colSpan={8}>
                                        No data to display.
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {this.props.data.map(x => (
                                    <TableRow key={x.cpd_id}>
                                        <TableCell align="center">
                                            <Link href="#" 
                                            onClick={() => this.props.action(x.log_year, x.ls_no)}>
                                                <b>{x.log_year}</b>
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">{x.bf_point}</TableCell>
                                        <TableCell align="center">{x.approved_point}</TableCell>
                                        <TableCell align="center">{x.total_point}</TableCell>
                                        <TableCell align="center">{x.exemption_point}</TableCell>
                                        <TableCell align="center">{x.mandatory_point}</TableCell>
                                        <TableCell align="center">{x.excess_point}</TableCell>
                                        <TableCell align="center">{x.cf_point}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TableComponent);