import React from 'react';

import axios from 'axios';
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import GridComponent from './Component/GridComponent';

class StaffLogin extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
    }
    
    loginHandler = (username, password) => {
        
        axios.post(Config() + '/authentication/staff/login',
        {
            username: username,
            password: password,
        },
        {
            withCredentials: true
        })
        .then(response => {
            console.log(response)
            localStorage.clear()
            localStorage.setItem('user_name', response.data['username'])
            localStorage.setItem('token', 'some key')

            this.props.history.push({
                pathname: '/ui/StaffHome'
            })
        })
        .catch(error => {
            if (!error.response) {
                this.ComponentUI.current.errorHandler("Code issue probably.")
            }
            else {
                if (ResponseStatusCheck(error)) {
                    this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                }

                else {
                    this.ComponentUI.current.errorHandler("Something went very wrong!")
                }
            }
        })
    }
    
    render() {
        return (
            <div>
                <GridComponent action={this.loginHandler} ref={this.ComponentUI} />
            </div>
        )
    }
}

export default StaffLogin;