import React from 'react';
import { withRouter } from 'react-router-dom'

// import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Config } from '../../../../Config';
import { ResponseStatusCheck } from '../../../../Response/ResponseStatus';

import StepperComponent from './Component/StepperComponent';
import HeaderComponent from './Component/HeaderComponent';
import FormComponent from './Component/FormComponent';

import BackdropComponent from '../../../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240 //pad content downwards from toolbar
        }
    },
})

class EngineeringForm extends React.Component {
    constructor() {
        super()
        this.ComponentUI = React.createRef()
        this.state = {
            job_type_array: [],
            district_array: [],

            backdrop: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        axios.get(Config() + '/ls_surveyjob/engineering/application/initialize',
            {
                withCredentials: true
            })
            .then(response => {
                this.setState({
                    district_array: response.data[0],
                    job_type_array: response.data[1]
                })

                //check if this is a new form or existing form
                if (sessionStorage.getItem('form_id') !== 'new') {
                    this.getFormData()
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    getFormData = () => {
        this.setState({ backdrop: true })
        axios.put(Config() + '/ls_surveyjob/engineering/application/get',
            {
                'form_id': sessionStorage.getItem('form_id')
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data.length === 0) {
                    this.ComponentUI.current.errorHandler("Cannot find the form data.")
                }
                else {
                    console.log(response.data)
                    var reference = response.data[0]['reference']
                    var job_type = response.data[0]['job_code']
                    var job_desc = response.data[0]['job_desc_form']
                    var jtu_file = response.data[0]['jtu_file']
                    var district = response.data[0]['dist_code']
                    var location = response.data[0]['location']
                    var name = response.data[0]['name']
                    var address = response.data[0]['address']
                    var country = response.data[0]['country']
                    var city = response.data[0]['city']
                    var state = response.data[0]['state']
                    var postcode = response.data[0]['postcode']
                    var tel_no = response.data[0]['tel_no']
                    var email = response.data[0]['email']

                    this.setState({ backdrop: false })

                    this.ComponentUI.current.getDataHandler(
                        reference, job_type, job_desc, jtu_file,
                        district, location, name, address, country,
                        city, state, postcode, tel_no, email
                    )
                }
            })
    }

    addData = (
        reference, job_type, job_desc, jtu_file,
        district, location, name, address, country,
        city, state, postcode, tel_no, email
    ) => {
        //LS Staff route added on 14/10/2021
        if (localStorage.getItem('perm') === "2") {
            var url = Config() + '/ls_surveyjob/engineering/application/ls_staff/add'
        }
        else {
            url = Config() + '/ls_surveyjob/engineering/application/add'
        }

        //10k Skip indicator added on 22/12/2021
        if (sessionStorage.getItem('10K') === ""|| sessionStorage.getItem('10K') === undefined ||
        sessionStorage.getItem('10K') === null) {
            var skip_ind = ""
        }
        else {
            skip_ind = sessionStorage.getItem('10K')
            console.log(skip_ind)
        }
        axios.post(url,
            {
                'form_id': sessionStorage.getItem('form_id'),
                'reference': reference,
                'job_type': job_type,
                'job_desc': job_desc,
                'jtu_file': jtu_file,
                'district': district,
                'location': location,
                'name': name,
                'address': address,
                'country': country,
                'city': city,
                'state': state,
                'postcode': postcode,
                'tel_no': tel_no,
                'email': email,
                'skip_ind': skip_ind
            },
            {
                withCredentials: true
            })
            .then(response => {
                //Set new form_id to session storage
                sessionStorage.setItem('form_id', response.data)

                //Redirect to next page (Engineering Table List)
                this.props.history.push({
                    pathname: '/ui/EngineeringTableList'
                })
            })
            .catch(error => {
                if (!error.response) {
                    this.ComponentUI.current.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.ComponentUI.current.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.ComponentUI.current.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/ui/LSEngineeringSurveyJobPendingDashboardList'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <StepperComponent />
                <br />
                <HeaderComponent />
                <FormComponent
                    data={this.state.district_array}
                    data2={this.state.job_type_array}
                    action={this.addData}
                    action2={this.goBack}
                    ref={this.ComponentUI} />

                {/* <Hidden smUp implementation="css">
                    <div className={classes.contentMobile}>
                        <StepperComponent />
                        <br />
                        <HeaderComponent />
                        <FormComponent
                            data={this.state.district_array}
                            data2={this.state.job_type_array}
                            action={this.addData}
                            ref={this.ComponentUI} />
                    </div>
                </Hidden>

                <Hidden xsDown implementation="css">
                    <div className={classes.contentDesktop}>
                        <StepperComponent />
                        <br />
                        <HeaderComponent />
                        <FormComponent
                            data={this.state.job_type_array}
                            data2={this.state.district_array}
                            action={this.addData}
                            ref={this.ComponentUI} />
                    </div>
                </Hidden> */}

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(EngineeringForm));