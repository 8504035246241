import React from 'react';

import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import GenericTextInput from '../Util/GenericTextInput';
import SelectTextInput from '../Util/SelectTextInput';
import SearchButton from '../Util/SearchButton';
import ResetButton from '../Util/ResetButton';

const styles = theme => ({
    paper: {
        maxWidth: 500,
        margin: `${theme.spacing(1)}px auto`,
    },
    headerText: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    form: {
        width: '100%', //Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        paddingBottom: 0
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
    },
    margin: {
        marginBottom: 10
    },
    box: {
        textAlign: 'center'
    }
})

class SearchPanelComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            input_ls_name: '',
        }
    }

    onChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = () => {
        let page = 0
        let rowsPerPage = 50

        this.props.action(
            page, rowsPerPage,
            this.input_ls_staff_name.value,
            this.state.input_ls_name
        )
    }

    resetHandler = () => {
        this.input_ls_staff_name.value = ''
        this.setState({
            input_ls_name: ''
        })

        this.props.action2()
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Paper elevation={3} className={classes.paper}>
                    <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.headerText}>Search Panel</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <form className={classes.form}>
                                <GenericTextInput className={classes.margin}
                                    label="LS Staff Name"
                                    inputRef={node => (this.input_ls_staff_name = node)}
                                    inputProps={{ maxLength: 100 }}
                                />

                                <SelectTextInput className={classes.margin}
                                    name="input_ls_name"
                                    label="Filter by LS Name"
                                    value={this.state.input_ls_name}
                                    data={this.props.data}
                                    onChange={this.onChangeHandler}
                                />

                                <Box pt={4} className={classes.box}>
                                    <SearchButton className={classes.button}
                                    onClick={this.submitHandler} />
                                    <ResetButton className={classes.button}
                                    onClick={this.resetHandler} />
                                </Box>
                            </form>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(SearchPanelComponent);