import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import axios from "axios";
import { Config } from '../../Config';
import { ResponseStatusCheck } from '../../Response/ResponseStatus';

import HeaderComponent from './Component/HeaderComponent';
import SearchPanelComponent from './Component/SearchPanelComponent';
import TableComponent from './Component/TableComponent';

import BackdropComponent from '../Util/BackdropComponent';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3), //pad content downwards from toolbar

        [theme.breakpoints.up('lg')]: {
            marginLeft: 240
        }
    },
})

class LicensedSurveyorList extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,

            backdrop: false,
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setState({
            data: [],
            page: 0,
            count: 0,
            rowsPerPage: 50,
            searchIndicator: false,

            alert: false,
            error_msg: '',

            ls_name: '',
            ls_status: '',
        }, () => {
            this.fetchData(this.state.page, this.state.rowsPerPage)
        })
    }

    fetchData = (page, rowsPerPage) => {
        this.setState({ backdrop: true })

        axios.get(Config() + '/licensedsurveyor/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],

                        backdrop: false
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
                this.setState({ backdrop: false })
            })
    }

    //sorting function for table
    sortHandler = (clickedColumn) => {
        const { column, data, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'asc',
            })
            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'asc' ? 'desc' : 'asc',
        })
    }

    //handle page changes and set user defined page number and rows per page
    pageHandler = (newPage) => {
        this.setState({
            page: newPage,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(newPage, this.state.rowsPerPage)
        }
        else {
            this.searchHandler(newPage, this.state.rowsPerPage)
        }
    }

    //handle changes in rows display per page
    rowHandler = (value) => {
        this.setState({
            rowsPerPage: value,
            page: 0,
            backdrop: true
        })
        if (this.state.searchIndicator === false) {
            this.fetchData(0, value)
        }
        else {
            this.searchHandler(0, value)
        }
    }

    //Search Function starts here
    submitHandler = (
        page, rowsPerPage, ls_name, ls_status
    ) => {
        if (ls_name === '' && ls_status === '') {
            this.errorHandler("Please provide some value to search.")
        }

        else {
            this.setState({
                ls_name: ls_name,
                ls_status: ls_status
            }, () => {
                this.searchHandler(page, rowsPerPage)
            })
        }
    }

    searchHandler = (page, rowsPerPage) => {
        this.setState({ backdrop: true, alert: false })
        axios.put(Config() + '/licensedsurveyor/search/page=' + parseInt(page + 1) + '/count=' + rowsPerPage,
            {
                'ls_no': '',
                'ls_name': this.state.ls_name,
                'ls_status': this.state.ls_status
            },
            {
                withCredentials: true
            })
            .then(response => {
                if (response.data[0]['result'] === "Success") {
                    this.setState({
                        data: response.data[1],
                        page: page,
                        count: response.data[3]['totalcount'],

                        backdrop: false,
                        searchIndicator: true
                    })
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.errorHandler("Code issue probably.")
                }
                else {
                    if (ResponseStatusCheck(error)) {
                        //If Session Expired
                        if (ResponseStatusCheck(error) === 401) {
                            console.log("Display some window here.")
                        }
                        else {
                            this.errorHandler(ResponseStatusCheck(error))
                        }
                    }
                    else {
                        this.errorHandler("Something went very wrong!")
                    }
                }
            })
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    goView = (ls_no) => {
        sessionStorage.setItem('ls_no_view', ls_no)
        this.props.history.push({
            pathname: '/ui/LicensedSurveyorView'
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <BackdropComponent open={this.state.backdrop} />

                <HeaderComponent />
                <SearchPanelComponent
                    action={this.submitHandler}
                    action2={this.init} />
                <br />
                <TableComponent
                    alert={this.state.alert}
                    error_msg={this.state.error_msg}
                    data={this.state.data}
                    action={this.goView}
                    actionSort={this.sortHandler}
                    column={this.state.column}
                    direction={this.state.direction}
                    count={this.state.count}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.pageHandler}
                    onChangeRowsPerPage={this.rowHandler} />
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(LicensedSurveyorList));