import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

import BreakdownComponent1 from './BreakdownComponent1';
import BreakdownComponent2 from './BreakdownComponent2';

const styles = theme => ({
    paper: {
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3)
    },
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 300
    },
    input: {
        display: 'none'
    }
})

class NextIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

class BackIdleButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Back
            </Button>
        )
    }
}

class NextLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                Submitting...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class BackLoadButton extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                className={this.props.className}
            >
                ...
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class FormComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            file_object: [],
            file_name: [],
            file_size: []
        }
    }

    uploadHandler = event => {
        let file = event.currentTarget.files

        Array.from(file).forEach(file => {
            this.setState((prevState) => ({
                file_object: [...prevState.file_object, file],
                file_name: [...prevState.file_name, file.name],
                file_size: [...prevState.file_size, file.size]
            }))
        })
    }

    removeHandler = (i) => {
        let file_object = [...this.state.file_object]
        let file_name = [...this.state.file_name]
        let file_size = [...this.state.file_size]
        file_object.splice(i, 1)
        file_name.splice(i, 1)
        file_size.splice(i, 1)
        this.setState({ file_object, file_name, file_size })
    }

    submitHandler = () => {
        this.setState({ loading: true })

        if (!this.state.file_name.length) {
            this.errorHandler("Please upload the relevant documents for the cadastral claim application.")
        }

        else {
            if (
                Number(this.state.file_size.reduce((prev, next) =>
                    (Number(prev) + Number(next)), 0)) >= 50000000) {
                this.errorHandler("The uploaded files exceeded the maximum size of 50MB.")
            }

            else {
                this.props.action(
                    this.state.file_object,
                    this.state.file_name
                )
            }
        }
    }

    errorHandler = (value) => {
        this.setState({
            loading: false,
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Paper elevation={3} className={classes.paper}>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                {/* <br /> */}

                <Typography variant="h6">
                    Claims Breakdown
                </Typography>

                <BreakdownComponent1 data={this.props.data} />
                <BreakdownComponent2 data={this.props.data} />

                <br />
                <Divider />
                <br />

                <Typography variant="h6">
                    Upload Documents
                </Typography>
                <br />
                <input
                    accept="image/*,application/pdf"
                    className={classes.input}
                    id="contained-button-file_1"
                    multiple
                    type="file"
                    onChange={this.uploadHandler}
                />
                <label htmlFor="contained-button-file_1">
                    <Button variant="contained" color="primary" component="span"
                        className={classes.button}>
                        Upload
                    </Button>
                </label>
                <Table>
                    <TableBody>
                        {this.state.file_name.length === 0 ?
                            <TableRow>
                                <TableCell>No files uploaded</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            :
                            this.state.file_name.map((x, i) => (
                                <TableRow key={x}>
                                    <TableCell style={{ width: '50%', whiteSpace: 'normal', wordBreak: 'break-word' }}>{x}</TableCell>
                                    <TableCell style={{ width: '50%' }}>
                                        <IconButton color="secondary"
                                            onClick={() => this.removeHandler(i)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

                {this.state.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <NextLoadButton className={classes.button} />
                        <BackLoadButton className={classes.button} />
                    </Box>

                    :

                    <Box pt={4} style={{ textAlign: 'center' }}>
                        {localStorage.getItem('perm') === "2" ?
                            <div>
                                <NextIdleButton className={classes.button}
                                    onClick={this.submitHandler} />
                                <BackIdleButton className={classes.button}
                                    onClick={this.props.action2} />
                            </div>
                            :
                            <div>
                                <NextIdleButton className={classes.button}
                                    onClick={this.submitHandler} />
                                <BackIdleButton className={classes.button}
                                    onClick={this.props.action2} />
                            </div>
                        }

                            {/* <NextIdleButton className={classes.button}
                                onClick={this.submitHandler} />
                            <BackIdleButton className={classes.button}
                                onClick={this.props.action2} /> */}
                    </Box>
                }
            </Paper>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FormComponent);