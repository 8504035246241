import React from 'react';

import Button from '@material-ui/core/Button';

class ButtonSubmitIdle extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                className={this.props.className}
                onClick={this.props.onClick}
            >
                Submit
            </Button>
        )
    }
}

export default ButtonSubmitIdle;