import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import RequiredTextInput from '../../Util/RequiredTextInput';
import DateInputComponent from '../../Util/DateInputComponent';
import RequiredTooltipTextInput from '../../Util/RequiredTooltipTextInput';
import GenericTextInput from '../../Util/GenericTextInput';

const styles = theme => ({
    padding: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
        fontWeight: 'bold',
        width: 200
    },
})

class Grid1Component extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    {this.props.image.map(x => (
                        <img src={x.url} key={x.id} alt=""
                            style={{ width: '250px', height: '250px' }} />
                    ))}
                </Grid>
            </React.Fragment>
        )
    }
}

class Grid2Component extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.name}>

                        <Grid item xs={12} sm={4}>
                            Name of Survey Technician:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.name}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Identity Card Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.ic_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Date of Birth:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.birth_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Place of Birth:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.birth_place}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Nationality:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.nationality}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Previous Registration No:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.prev_registration_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Academic Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.academic_qualification}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Technical Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.technical_qualification}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Particular of Previous Employment:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.prev_emp}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Date of Commencement of Service with Present Licensed Surveyor:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.prac_date}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Practical Experience:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.prac_exp}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Remarks:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.remark}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Registration No:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.registration_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                <Box pt={4} style={{ textAlign: 'center' }}>
                    <ButtonIdleComponent
                        color="primary"
                        className={this.props.classNameButton}
                        onClick={this.props.onClick}
                        name="Edit"
                    />
                </Box>
            </div>
        )
    }
}

class Grid2ComponentEdit extends React.Component {
    constructor() {
        super()
        this.state = {
            input_birth_date: new Date(),
            input_prac_date: new Date(),
        }
    }

    componentDidMount() {
        this.setState({
            input_birth_date: new Date(this.props.data[0]['birth_date_formatted']),
            input_prac_date: new Date(this.props.data[0]['prac_date'])
        })
    }

    onChangeHandler_birth_date = date => {
        this.setState({ input_birth_date: date })
    }

    onChangeHandler_prac_date = date => {
        this.setState({ input_prac_date: date })
    }

    submitHandler = () => {
        this.setState({
            error_technician_name: this.input_technician_name.value ? false : true,
            error_ic_no: this.input_ic_no.value ? false : true,
            error_birth_place: this.input_birth_place.value ? false : true,
            error_nationality: this.input_nationality.value ? false : true,
            error_academic_qualification: this.input_academic_qualification.value ? false : true,
            error_technical_qualification: this.input_technical_qualification.value ? false : true,
            error_prac_exp: this.input_prac_exp.value ? false : true,
            error_birth_date: this.state.input_birth_date && this.state.input_birth_date !== new Date() ? false : true,
            error_prac_date: this.state.input_prac_date && this.state.input_prac_date !== new Date() ? false : true
        })

        if (this.input_technician_name.value === '' ||
            this.input_ic_no.value === '' ||
            this.input_birth_place.value === '' ||
            this.input_nationality.value === '' ||
            this.input_academic_qualification.value === '' ||
            this.input_technical_qualification.value === '' ||
            this.input_prac_exp.value === '' ||

            this.state.input_birth_date === '' ||
            this.state.input_birth_date === new Date() ||
            this.state.input_prac_date === '' ||
            this.state.input_prac_date === new Date()) {

            this.errorHandler("Please fill in the required input/s.")
        }

        else {
            if (!this.state.input_birth_date) {
                var formatted_birth_date = ""
            }
            else if (!this.state.input_prac_date) {
                var formatted_prac_date = ""
            }
            else {
                formatted_birth_date = (this.state.input_birth_date).getDate() + '/' + ((this.state.input_birth_date).getMonth() + 1) + '/' + (this.state.input_birth_date).getFullYear()
                formatted_prac_date = (this.state.input_prac_date).getDate() + '/' + ((this.state.input_prac_date).getMonth() + 1) + '/' + (this.state.input_prac_date).getFullYear()
            }

            this.props.action(
                this.input_technician_name.value,
                this.input_ic_no.value,
                formatted_birth_date,
                this.input_birth_place.value,
                this.input_nationality.value,
                this.input_prev_regtech.value,
                this.input_academic_qualification.value,
                this.input_technical_qualification.value,
                this.input_prev_emp.value,
                formatted_prac_date,
                this.input_prac_exp.value,
                this.input_remark.value
            )
        }
    }

    errorHandler = (value) => {
        this.setState({
            alert: true,
            error_msg: value
        })

        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div>
                {this.state.alert ?
                    <Alert severity="error">
                        {this.state.error_msg}
                    </Alert>
                    :
                    null}

                {this.props.data.map(x => (
                    <Grid container
                        direction="row"
                        spacing={3}
                        className={this.props.className}
                        key={x.name}>

                        <Grid item xs={12} sm={4}>
                            Name of Survey Technician:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTextInput
                                error={this.state.error_technician_name}
                                name="input_technician_name"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_technician_name = node)}
                                defaultValue={x.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Identity Card Number:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTextInput
                                error={this.state.error_ic_no}
                                name="input_ic_no"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_ic_no = node)}
                                defaultValue={x.ic_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Date of Birth:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <DateInputComponent
                                name="input_birth_date"
                                value={this.state.input_birth_date}
                                onChange={this.onChangeHandler_birth_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Place of Birth:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTextInput
                                error={this.state.error_birth_place}
                                name="input_birth_place"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_birth_place = node)}
                                defaultValue={x.birth_place}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Nationality:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTextInput
                                error={this.state.error_nationality}
                                name="input_nationality"
                                inputProps={{ maxLength: 50 }}
                                inputRef={node => (this.input_nationality = node)}
                                defaultValue={x.nationality}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Previous Registration No:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_prev_regtech"
                                inputProps={{ maxLength: 20 }}
                                inputRef={node => (this.input_prev_regtech = node)}
                                defaultValue={x.prev_registration_no}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Academic Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTooltipTextInput
                                error={this.state.error_academic_qualification}
                                name="input_academic_qualification"
                                inputProps={{ maxLength: 100 }}
                                // title="Please type in the academic qualification with years. (e.g. Diploma in Land Surveying, 2018)"
                                title="This includes eg. SPM, Diploma, Degree"
                                inputRef={node => (this.input_academic_qualification = node)}
                                defaultValue={x.academic_qualification}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Technical Qualification:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTooltipTextInput
                                error={this.state.error_technical_qualification}
                                name="input_technical_qualification"
                                inputProps={{ maxLength: 100 }}
                                // title="Please type in the technical qualification with dates (Relevant to Survey Technician). (e.g. Survey Course, 2018)"
                                title="Means any other post secondary qualification other than academic e.g. Sijil Kemahiran Malaysia, Skills, Vocational & technical certificates"
                                inputRef={node => (this.input_technical_qualification = node)}
                                defaultValue={x.technical_qualification}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Particular of Previous Employment:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_prev_emp"
                                inputProps={{ maxLength: 200 }}
                                inputRef={node => (this.input_prev_emp = node)}
                                defaultValue={x.prev_emp}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Date of Commencement of Service with Present Licensed Surveyor:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <DateInputComponent
                                name="input_prac_date"
                                value={this.state.input_prac_date}
                                onChange={this.onChangeHandler_prac_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Practical Experience:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <RequiredTooltipTextInput
                                error={this.state.error_prac_exp}
                                name="input_prac_exp"
                                inputProps={{ maxLength: 200 }}
                                title="Please summarise specific land surveying related experience eg. Field data capture with a total station, GNSS, data processing, CAD skills, underground utility, hydrography, engineering surveys, drone or aerial surveys, etc."
                                inputRef={node => (this.input_prac_exp = node)}
                                defaultValue={x.prac_exp}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Remarks:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <GenericTextInput
                                name="input_remark"
                                inputProps={{ maxLength: 100 }}
                                inputRef={node => (this.input_remark = node)}
                                defaultValue={x.remark}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            Registration No:
                                    </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{x.registration_no}</b>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}

                {this.props.loading ?
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonLoadComponent
                            color="primary"
                            className={this.props.classNameButton}
                            name="Updating..."
                        />

                        <ButtonLoadComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            name="..."
                        />
                    </Box>
                    :
                    <Box pt={4} style={{ textAlign: 'center' }}>
                        <ButtonIdleComponent
                            color="primary"
                            className={this.props.classNameButton}
                            onClick={this.submitHandler}
                            name="Update"
                        />
                        <ButtonIdleComponent
                            color="secondary"
                            className={this.props.classNameButton}
                            onClick={this.props.onClick}
                            name="Cancel"
                        />
                    </Box>
                }
            </div>
        )
    }
}

class ButtonIdleComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color={this.props.color}
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {this.props.name}
            </Button>
        )
    }
}

class ButtonLoadComponent extends React.Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                disabled
                className={this.props.className}
            >
                {this.props.name}
                <CircularProgress size={24} />
            </Button>
        )
    }
}

class Section1Component extends React.Component {
    constructor() {
        super()
        this.state = {
            viewState: true
        }
    }

    viewStateHandler = () => {
        this.setState({
            viewState: !this.state.viewState
        })
    }

    submitHandler = (
        technician_name, ic_no, birth_date, birth_place,
        nationality, prev_regno, academic_qualification,
        technical_qualification, prev_emp, prac_date,
        prac_exp, remark) => {

        this.props.action(
            technician_name, ic_no, birth_date, birth_place,
            nationality, prev_regno, academic_qualification,
            technical_qualification, prev_emp, prac_date,
            prac_exp, remark
        )

    }

    render() {
        const { classes } = this.props

        return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>
                        Section 1 - Technician Details
                    </Typography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails>
                    <Typography variant="h6"
                        gutterBottom
                        className={classes.padding}>
                        Technician Details
                    </Typography>
                </AccordionDetails>

                {!this.props.image.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        <Grid1Component
                            className={classes.padding}
                            image={this.props.image}
                        />
                    </AccordionDetails>
                }

                {!this.props.data.length ?
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography
                                            variant="subtitle1">
                                            No data here...
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                    :
                    <AccordionDetails>
                        {this.state.viewState === true ?
                            <React.Fragment>
                                <Grid2Component
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    onClick={this.viewStateHandler}
                                />

                            </React.Fragment>

                            :
                            <React.Fragment>
                                <Grid2ComponentEdit
                                    className={classes.padding}
                                    data={this.props.data}
                                    classNameButton={classes.button}
                                    loading={this.props.loading}
                                    action={this.submitHandler}
                                    onClick={this.viewStateHandler}
                                />
                            </React.Fragment>
                        }
                    </AccordionDetails>
                }
            </Accordion>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Section1Component);